import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { RrDataService, Widget } from "../../shared/rr-data.service";
import { TerritoryPickerComponent } from "../../territory-picker/territory-picker.component";
import { ReportFieldPipe } from "../../reports/report/report-field.pipe";
import { Observable } from "rxjs";
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';
// import '@types/web-animations-js/web-animations.min';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { RrLibService } from 'app/rr-lib.service';


@Component({
    selector: 'rr-widget',
    templateUrl: './widget.component.html',
    styleUrls: ['./widget.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('animateValue', [
            state('counted', style({
                transform: 'scale(1) rotate(0deg)'
            })),
            state('counting', style({
                transform: 'scale(1.1) rotate(2deg)'
            })),
            transition('counting => counted', animate('300ms')),
            transition('counted => counting', animate('100ms'))
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush

})
export class WidgetComponent implements OnInit {

    @Input() name: string;

    widget: Widget;

    actual: number = 0;
    target: number = 0;
    rate: number = 0;

    state = 'zero';

    private cycle: string;
    private selectedTerritories: string[];

    private timer: NodeJS.Timer;

    private listeners = {};

    constructor(private rrd: RrDataService, private cdr: ChangeDetectorRef) {

    }

    processWidget(widget: Widget) {
        var vars = {};
        var varArrays = {};
        const cycle = this.cycle;
        const territories = this.selectedTerritories;

        var oldVal = this.actual + this.target;

        const compute = function (formula: string) {

            const varNames = formula.match(/%[a-z,_,A-Z]*%/g);

            if (varNames != null) {
                varNames.forEach(function (v: string) {
                    const val = vars[v.replace(/%/g, '')];
                    if (val) {

                        formula = formula.replace(v, val);
                    }
                });
            }

            if (formula.indexOf('%') == -1) {
                return eval(formula) || 0;
            } else {
                return 0;
            }


        }

        if (cycle && territories && widget) {
            vars = {};
            const terrVars = {}
            oldVal = this.actual + this.target;
            this.actual = 0;
            this.target = 0;
            this.rate = 0;
            // debugger
            if (territories.length == 0) {
                if (this.actual + this.target != oldVal) {
                    this.state = 'counting';
                    setTimeout(x => {
                        this.state = 'counted'
                    }, 300);
                }
            }

            var promises: Promise<any>[] = []

            const syncVariables = (promise : Promise<any>) => {

            }

            territories.forEach(terrId => {

                const rfp = new ReportFieldPipe();

                var terrListener: Subscription[] = this.listeners[terrId] || [];

                terrListener.forEach(listener => {
                    listener.unsubscribe();
                })

                terrListener = [];


                /* collect variables */
                for (let v in widget.variables) {

                    var variable = widget.variables[v];
                    variable = variable.replace('%terrId%', terrId).replace('%cycle%', cycle).replace('%today%', RrLibService.dateToday());

                    if (typeof variable == 'string' && (variable.indexOf('getData:') != -1)) {
                        if (variable.indexOf('getData:') != -1) {
                            var dPath = variable.replace('getData:', '');
                            var timer;
                            terrListener.push(this.rrd.clientGetObject(dPath).snapshotChanges().subscribe(snap => {

                                // vars[v] = (vars[v] || 0) + snap.payload.val();
                                this.state = 'counting';
                                this.cdr.markForCheck()

                                new Promise((resolve, reject) => {

                                    var tvars = terrVars[v] || {}

                                    tvars[terrId] = snap.payload.val();

                                    vars[v] = 0;
                                    for (let tid in tvars) {
                                        vars[v] += tvars[tid]
                                    }

                                    terrVars[v] = tvars;

                                    this.actual = compute(widget.actual.replace('%territory_count%', `${territories.length}`));
                                    this.target = compute(widget.target.replace('%territory_count%', `${territories.length}`));

                                    if (this.target * this.actual > 0) {
                                        this.rate = Math.round((this.actual / this.target) * 100);
                                    } else {
                                        this.rate = 0;
                                    }

                                    resolve(null)

                                }).then(() => {

                                    if (this.actual + this.target != oldVal) {

                                        // if (timer != null) {
                                        //     clearTimeout(timer);
                                        // }

                                        // timer = setTimeout(x => {
                                        //     this.state = 'counted'
                                        //     this.cdr.markForCheck()
                                        // }, 300);
                                        this.state = 'counted'
                                        this.cdr.markForCheck()
                                    }
                                })
                            })
                            )
                        }
                    }

                    if (typeof variable == 'string' && (variable.indexOf('sum:') != -1)) {
                        if (variable.indexOf('sum:') != -1) {
                            var dPath = variable.replace('sum:', '');
                            var timer;
                            terrListener.push(this.rrd.clientGetObject(dPath).snapshotChanges().subscribe(snap => {


                                this.state = 'counting';
                                this.cdr.markForCheck()

                                new Promise((resolve, reject) => {

                                    var tvars = terrVars[v] || {}

                                    tvars[terrId] = snap.payload.val();

                                    vars[v] = 0;
                                    for (let tid in tvars) {
                                        vars[v] += tvars[tid]
                                    }

                                    terrVars[v] = tvars;


                                    this.actual = compute(widget.actual.replace('%territory_count%', `${territories.length}`));
                                    this.target = compute(widget.target.replace('%territory_count%', `${territories.length}`));

                                    if (this.target * this.actual > 0) {
                                        this.rate = Math.round((this.actual / this.target) * 100);
                                    } else {
                                        this.rate = 0;
                                    }

                                    resolve(null)

                                }).then(() => {

                                    if (this.actual + this.target != oldVal) {

                                        // if (timer != null) {
                                        //     clearTimeout(timer);
                                        // }

                                        // timer = setTimeout(x => {
                                        //     this.state = 'counted'
                                        //     this.cdr.markForCheck()
                                        // }, 300);
                                        this.state = 'counted'
                                        this.cdr.markForCheck()
                                    }
                                })

                                // vars[v] = (vars[v] || 0) + snap.payload.val();
                                // var tvars = terrVars[v] || {}

                                // tvars[terrId] = snap.payload.val();

                                // vars[v] = 0;
                                // for (let tid in tvars) {
                                //     vars[v] += tvars[tid]
                                // }

                                // terrVars[v] = tvars;


                                // this.actual = compute(widget.actual.replace('%territory_count%', `${territories.length}`));
                                // this.target = compute(widget.target.replace('%territory_count%', `${territories.length}`));

                                // if (this.target * this.actual > 0) {
                                //     this.rate = Math.round((this.actual / this.target) * 100);
                                // } else {
                                //     this.rate = 0;
                                // }

                                // if (this.actual + this.target != oldVal) {
                                //     this.state = 'counting';
                                //     this.cdr.markForCheck()

                                //     if (timer != null) {
                                //         clearTimeout(timer);
                                //     }

                                //     timer = setTimeout(x => {
                                //         this.state = 'counted'
                                //         this.cdr.markForCheck()
                                //     }, 300);
                                // }

                            }))
                        }
                    }

                    if (typeof variable == 'string' && (variable.indexOf('average:') != -1)) {
                        if (variable.indexOf('average:') != -1) {
                            var dPath = variable.replace('average:', '');


                            var timer;

                            terrListener.push(this.rrd.clientGetObject(dPath).snapshotChanges().subscribe(snap => {

                                this.state = 'counting';
                                this.cdr.markForCheck()

                                new Promise((resolve, reject) => {

                                    var tvars = terrVars[v] || {}

                                    tvars[terrId] = snap.payload.val();

                                    vars[v] = 0;
                                    var ctr = 0;
                                    for (let tid in tvars) {
                                        if (tvars[tid] !== null) {
                                            vars[v] += tvars[tid]
                                            ctr += 1
                                        }

                                    }
                                    vars[v] = vars[v] / ctr
                                    terrVars[v] = tvars;


                                    this.actual = compute(widget.actual.replace('%territory_count%', `${territories.length}`));
                                    this.target = compute(widget.target.replace('%territory_count%', `${territories.length}`));

                                    if (this.target * this.actual > 0) {
                                        this.rate = Math.round((this.actual / this.target) * 100);
                                    } else {
                                        this.rate = 0;
                                    }

                                    resolve(null)

                                }).then(() => {

                                    if (this.actual + this.target != oldVal) {

                                        // if (timer != null) {
                                        //     clearTimeout(timer);
                                        // }

                                        // timer = setTimeout(x => {
                                        //     this.state = 'counted'
                                        //     this.cdr.markForCheck()
                                        // }, 300);
                                        this.state = 'counted'
                                        this.cdr.markForCheck()
                                    }
                                })

                                // var tvars = terrVars[v] || {}

                                // tvars[terrId] = snap.payload.val();

                                // vars[v] = 0;
                                // var ctr = 0;
                                // for (let tid in tvars) {
                                //     if (tvars[tid] !== null) {
                                //         vars[v] += tvars[tid]
                                //         ctr += 1
                                //     }

                                // }
                                // vars[v] = vars[v] / ctr
                                // terrVars[v] = tvars;


                                // this.actual = compute(widget.actual.replace('%territory_count%', `${territories.length}`));
                                // this.target = compute(widget.target.replace('%territory_count%', `${territories.length}`));

                                // if (this.target * this.actual > 0) {
                                //     this.rate = Math.round((this.actual / this.target) * 100);
                                // } else {
                                //     this.rate = 0;
                                // }
                                // if (this.actual + this.target != oldVal) {
                                //     this.state = 'counting';
                                //     this.cdr.markForCheck()
                                //     if (timer != null) {
                                //         clearTimeout(timer);
                                //     }

                                //     timer = setTimeout(x => {
                                //         this.state = 'counted'
                                //         this.cdr.markForCheck()
                                //     }, 300);
                                // }

                            }))
                        }
                    }

                    if (typeof variable == 'string' && (variable.indexOf('count:') != -1)) {

                        if (variable.indexOf('count:') != -1) {

                            var dPath = variable.replace('count:', '');

                            var timer;

                            terrListener.push(this.rrd.clientDataRead(dPath).snapshotChanges().subscribe(snap => {

                                this.state = 'counting';
                                this.cdr.markForCheck()

                                new Promise((resolve, reject) => {

                                    var tvars = terrVars[v] || {}

                                    tvars[terrId] = snap.length

                                    vars[v] = 0;
                                    for (let tid in tvars) {
                                        vars[v] += tvars[tid]
                                    }

                                    terrVars[v] = tvars;

                                    this.actual = compute(widget.actual.replace('%territory_count%', `${territories.length}`));
                                    this.target = compute(widget.target.replace('%territory_count%', `${territories.length}`));

                                    if (this.target * this.actual > 0) {
                                        this.rate = Math.round((this.actual / this.target) * 100);
                                    } else {
                                        this.rate = 0;
                                    }

                                    resolve(null)

                                }).then(() => {

                                    if (this.actual + this.target != oldVal) {

                                        // if (timer != null) {
                                        //     clearTimeout(timer);
                                        // }

                                        // timer = setTimeout(x => {
                                        //     this.state = 'counted'
                                        //     this.cdr.markForCheck()
                                        // }, 300);
                                        this.state = 'counted'
                                        this.cdr.markForCheck()
                                    }
                                })


                                // // vars[v] = (vars[v] || 0) + snap.length;
                                // var tvars = terrVars[v] || {}

                                // tvars[terrId] = snap.length

                                // vars[v] = 0;
                                // for (let tid in tvars) {
                                //     vars[v] += tvars[tid]
                                // }

                                // terrVars[v] = tvars;

                                // this.actual = compute(widget.actual.replace('%territory_count%', `${territories.length}`));
                                // this.target = compute(widget.target.replace('%territory_count%', `${territories.length}`));

                                // if (this.target * this.actual > 0) {
                                //     this.rate = Math.round((this.actual / this.target) * 100);
                                // } else {
                                //     this.rate = 0;
                                // }

                                // if (this.actual + this.target != oldVal) {

                                //     this.state = 'counting';
                                //     this.cdr.markForCheck()

                                //     if (timer != null) {
                                //         clearTimeout(timer);
                                //     }

                                //     timer = setTimeout(x => {

                                //         this.state = 'counted'
                                //         this.cdr.markForCheck()
                                //     }, 300);
                                // }

                            }))
                        }
                    }
                }
            }
            )





        }

    }


    ngOnInit() {

        const rrd = this.rrd;

        var cSub: Subscription
        var tSub: Subscription

        rrd.widgetList.subscribe(wl => {
            if (wl) {

                this.widget = wl.find((w: Widget) => w.title == this.name);

                if (this.widget) {

                    if (cSub) {
                        cSub.unsubscribe()
                    }

                    cSub = rrd.currentCycle.subscribe(c => {

                        this.cycle = c;
                        if (tSub) {
                            tSub.unsubscribe()
                        }

                        tSub = rrd.selectedTerritories.subscribe(t => {
                            this.selectedTerritories = t;
                            this.processWidget(this.widget);
                        })

                    })
                }

            }

        })

    }

}
