import { Component, Input } from '@angular/core';
import { WegotComponent } from "./wegot.component";
import {ImFireService} from "../../im-fire.service";
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';
// import '@types/web-animations-js/web-animations.min';
import {RrDataService} from "../../shared/rr-data.service";


@Component({
    selector: 'wegot-customer-reach',
    templateUrl: './wegot.component.html',
    styleUrls: ['./wegot.component.css'],
    animations: [
        trigger('animateValue', [
            state('counted', style({
                transform: 'scale(1) rotate(0deg)'
            })),
            state('counting', style({
                transform: 'scale(1.1) rotate(2deg)'
            })),
            transition('counting => counted', animate('300ms')),
            transition('counted => counting', animate('100ms'))
        ])
    ]
})

export class CustomerReachComponent implements WegotComponent {
    @Input() title:string = "Customer Reach";
    @Input() actual:number = 0;
    @Input() target:number = 0;
    @Input() rate:number = 0.0;
    @Input() extraClass:string = "warning";

    state = 'zero';
    territoryCustomerCount = {};
    territoryCustomersReached = {};
    selectedTerritories = [];
    listener = {};

    constructor(private fb:ImFireService, private rrd : RrDataService) {
    }

    ngOnInit() {

        this.rrd.currentCycle.subscribe(cycle => {

            this.fb.userTerritories.subscribe(x => x.forEach(terr => {

                if (this.listener[terr + 'A']) {
                    this.listener[terr + 'A'].unsubscribe()
                }

                this.listener[terr + 'A'] = this.fb.clientGetObject('_call_counters/' + cycle + '/customer_count/' + terr).subscribe(val => {
                    //console.log('retrieving ' + terr + ' VALUE:' + val);
                    this.territoryCustomerCount[terr] = (val || 0);
                    this.recount();
                })

                if (this.listener[terr + 'B']) {
                    this.listener[terr + 'B'].unsubscribe()
                }

                this.listener[terr + 'B'] = this.fb.clientGetObject('_call_counters/' + cycle + '/customers_reached/' + terr).subscribe(val => {
                    //console.log('retrieving ' + terr + ' VALUE:' + val);
                    this.territoryCustomersReached[terr] = (val || 0);
                    this.recount();
                })


            }))
        });

        this.fb.selectedTerritories.subscribe(x => {
            this.selectedTerritories = x;
            this.recount()
        });

    }

    private countFlag = false;
    recount() {
        if (this.countFlag == false) {
            this.countFlag = true;
            const oldVal = this.actual;

            this.actual = 0;
            this.target = 0;

            this.selectedTerritories.forEach(terr => {
                this.actual = this.actual + (this.territoryCustomersReached[terr] || 0);
                this.target = this.target + (this.territoryCustomerCount[terr] || 0 );
            });


            this.rate = Math.round((this.actual / this.target)*100) || 0;

            this.countFlag = false;
            if (this.actual != oldVal) {
                this.state = 'counting';
                setTimeout(x => {
                    this.state = 'counted'
                }, 300);
            }

        }
    }

}
