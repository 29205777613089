import { Injectable } from '@angular/core';
import {ToasterModule, ToasterService, ToasterConfig} from 'angular2-toaster';
import {Toast} from "angular2-toaster/angular2-toaster";
import {BehaviorSubject} from "rxjs/BehaviorSubject";

@Injectable()
export class EventsService {

    static _toast = new BehaviorSubject<Object>({});
    static toasts = EventsService._toast.asObservable();

    constructor() { }

    static createToast(type: string, title: string, body: string) {
        const t = {
            type: type,
            title: title,
            body: body
        }
        EventsService._toast.next(t);
    }

}
