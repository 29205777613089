// TODO: fix the hovering position of the dot.  right now it doesn't appear on the right position
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ImFireService } from "../../im-fire.service";
import { DatabaseSnapshot } from "@angular/fire/database/interfaces";
import { DatePipe } from '@angular/common';
import { ChartComponent } from './chart.component';
import {RrDataService} from "../../shared/rr-data.service";

declare let d3: any;

@Component({
    selector: 'chart-daily-calls',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.css',
        '../../../../node_modules/nvd3/build/nv.d3.css'],
    encapsulation: ViewEncapsulation.None
})
export class ChartDailyCalls implements  OnInit {

    options;
    data;
    title = "Daily Call Monitor";
    listener = {};

    constructor(protected fb:ImFireService, private rrd : RrDataService) {
    }

    private rawData = {};
    private selectedTerritories = [];

    ngOnInit() {

        this.options = {
            chart: {
                type: 'lineChart',
                height: 250,
                interpolate: "cardinal" ,
                tension: 0,
                margin : {
                    top: 20,
                    right: 20,
                    bottom: 50,
                    left: 55
                },
                x: function(d) { return new Date(d.label); },
                y: function(d) { return d.value; },
                showControl: false,
                showValues:  false,
                showLegend:  this.selectedTerritories.length < 11,
                valueFormat: function(d){
                    //return d3.format(',.4f')(d);
                    return d3.format(',.f')(d);
                },
                duration: 500,
                xAxis: {
                    axisLabel: 'Date',
                    tickFormat: function(d) {
                        const dPipe = new DatePipe('en-US');
                        return dPipe.transform(d,'MMM-dd');
                    }
                },
                yAxis: {
                    axisLabel: 'Call Count',
                    axisLabelDistance: -10
                }
            }
        }



        this.rrd.currentCycle.subscribe(cycle => {

            this.rawData = {};

            this.fb.userTerritories.subscribe(x => x.forEach(terr => {
                    //console.log(terr);

                    if (terr != 'none') {

                        if (this.listener[terr]) {
                            this.listener[terr].unsubscribe();
                        }

                        this.listener[terr] = this.fb.clientGetListRef('_call_counters/' + cycle + '/daily_calls/' + terr).snapshotChanges().subscribe(list => {

                            list.forEach(daySnap => {

                                const date:string = daySnap.key;
                                const count:number = daySnap.payload.val();

                                var dayData = this.rawData[date] || new Object;
                                dayData[terr] = count;

                                this.rawData[date] = dayData;

                            });

                            //console.log(this.rawData);

                            this.recount()

                        });


                    }
                }
            ));
        });

        this.fb.selectedTerritories.subscribe(x => {
            this.selectedTerritories = x;
            this.recount()
        });


    }

    recount() {

        this.data = [];

        //console.log(this.rawData);

        //console.log(this.selectedTerritories);

        this.options.chart.showLegend = this.selectedTerritories.length < 11;

        this.selectedTerritories.forEach( terr => {

            var callsPerDay = [];
            //console.log("territory = " + terr);
            const dates : string[] = Object.keys(this.rawData).sort(function(a,b){return a < b ? -1 : a > b ? 1 : 0}) ;

            dates.forEach(dateLabel => {

                callsPerDay.push({ label: dateLabel, value: this.rawData[dateLabel][terr] || 0});

            });

            this.data.push({ values : callsPerDay, key : terr});

        });

        //console.log(this.data);


    }

}
