import { Component, OnInit } from '@angular/core';
import { AuthService } from "../shared/auth.service";
import { ImFireService } from "../im-fire.service";
import { RrDataService, IGNotif, UserData } from "../shared/rr-data.service";
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { RrLibService } from "../rr-lib.service";
import { dsv } from 'd3';
import { DatePipe } from '@angular/common';
import { NotificationData, NotificationBody } from './notification';
import { EventsService } from "../shared/events.service";

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
    notificationInclusion = [];
    notificationDeletion = [];
    notificationNFD = [];
    initialNotif = [];
    deletionProfilePic = [];
    inclusionProfilePic = [];
    CTDProfilePic = [];
    notifProfilePic = [];
    notifCountInclusion: number = 0;
    notifCountDeletion: number = 0;
    notifCountNFD: number = 0;
    notifAll: string;
    notification = [];
    profilePic;
    notifLoading: boolean = true;
    viewNotifApprove: string;
    viewNotifDeny: string;
    selectedTab: number = 0;
    private pp;
    currentUser: UserData;


    constructor(private authService: AuthService,
        private rrd: RrDataService,
        private imFire: ImFireService) {

        this.rrd.userData.subscribe(currUser => {
            this.currentUser = currUser
        })

    }


    ngOnInit() {
        // this.sendNotification();

        this.imFire.selectedNotification.subscribe(n => {


            this.notifAll = n

            this.authService.userInfo.subscribe(udata => {

                this.rrd.allUsers.subscribe(uList => {

                    if (uList != null) {
                        if (n === 'all') {

                            this.imFire.readFirList(udata.client + '/notification/' + udata.userName).snapshotChanges().subscribe(n => {
                                this.notifCountDeletion = 0;
                                this.notifCountInclusion = 0;
                                this.notifCountNFD = 0;
                                this.notificationInclusion = [];
                                this.notificationDeletion = [];
                                this.notificationNFD = [];
                                this.notifLoading = true;

                                n.forEach(notif => {

                                    let data = notif.payload.val();

                                    if (!notif.payload.hasChild('seen')) {
                                        let type = data['type'] // notif.payload.child('type').val();
                                        let notifDateTime = data['datetime'] //notif.payload.child('datetime').val();

                                        let ndt = this.imFire.getNotificationTime(notifDateTime)

                                        let from = uList.byUser[data['from']]
                                        // console.dir(from)

                                        let ppic = from ? from['profileURL'] : Promise.resolve('images/profile.jpg')

                                        let notifVal = { $key: notif.key, ...notif.payload.val(), profile_pic: ppic, time_span: ndt };
                                        switch (type) {
                                            case 'Inclusion':
                                                this.notificationInclusion.push(notifVal)
                                                this.notifCountInclusion++;
                                                break;
                                            case 'Deletion':
                                                this.notificationDeletion.push(notifVal)
                                                this.notifCountDeletion++;
                                                break;
                                            case 'Non-Field day':
                                                this.notificationNFD.push(notifVal)
                                                this.notifCountNFD++
                                                break;
                                            default:
                                                break;
                                        }

                                    }
                                    this.notifLoading = false;

                                    // if(this.notifCountInclusion === 0){
                                    //     this.selectedTab = 1
                                    // }
                                    // if(this.notifCountDeletion === 0){
                                    //     this.selectedTab = 2
                                    // }
                                    // if (this.notifCountNFD === 0){
                                    //     this.selectedTab = 0
                                    // }
                                })
                                this.notificationInclusion = this.notificationInclusion.reverse()
                                this.notificationDeletion = this.notificationDeletion.reverse()
                                this.notificationNFD = this.notificationNFD.reverse()
                                this.deletionProfilePic = this.deletionProfilePic.reverse()
                                this.inclusionProfilePic = this.inclusionProfilePic.reverse()
                                this.CTDProfilePic = this.CTDProfilePic.reverse()
                            })
                        } else {
                            this.imFire.readFirObject(udata.client + '/notification/' + udata.userName + '/' + this.notifAll).snapshotChanges()
                                .subscribe(notif => {
                                    this.notification = [];
                                    let data = notif.payload.val() || {};
                                    if (!data['seen']) {

                                        let type = data['type'] // notif.payload.child('type').val();
                                        let notifDateTime = data['datetime'] //notif.payload.child('datetime').val();

                                        let ndt = this.imFire.getNotificationTime(notifDateTime)


                                        let from = uList.byUser[data['from']]
                                        // console.dir(from)

                                        let ppic = from ? from['profileURL'] : Promise.resolve('images/profile.jpg')

                                        let notifVal = { $key: notif.key, ...notif.payload.val(), profile_pic: ppic, time_span: ndt };
                                        this.notification.push(notifVal)

                                        switch (type) {
                                            case 'Inclusion':
                                                this.viewNotifApprove = 'approveInclusion'
                                                this.viewNotifDeny = 'denyInclusion'
                                                this.selectedTab = 0
                                                break;
                                            case 'Deletion':
                                                this.viewNotifApprove = 'approveDeletion'
                                                this.viewNotifDeny = 'denyDeletion'
                                                this.selectedTab = 1
                                                break;
                                            case 'Non-Field day':
                                                this.viewNotifApprove = 'approveCTD'
                                                this.viewNotifDeny = 'denyCTD'
                                                this.selectedTab = 2
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                })
                        }
                    }
                })
            })
        })
    }



    markAsSeen(index: string) {
        let notificationPath = AuthService.client + '/notification/' + AuthService.userName + '/' + index + '/seen';
        const dateseen = (new DatePipe('EN-US')).transform(new Date(), 'yyyy-MM-dd HH:mm:ss ZZZ');
        let notifSeen = this.imFire.readFirObject(notificationPath);
        if (notifSeen) {
            notifSeen.set(dateseen);
            console.log('Mark as Seen!')
        }
    }

    sendNotification(to: string, body: any, index: string, nToast: any) {
        const ds = (new DatePipe('EN-US')).transform(new Date(), 'yyyy-MM-dd HH:mm:ss ZZZ');
        let userName = this.authService.userInfo.getValue().userName
        let key = to + "/" + userName + "-" + ds;

        let nbody = body;
        nbody["from"] = userName;
        nbody["datetime"] = ds;

        this.imFire.readFirObject(AuthService.client + '/notification/' + key).set(nbody);
        console.log('Notification Sent! ' + nbody['subject'])
        this.notifToast(nToast);
        this.markAsSeen(index)
    }

    moveToDeleted(obj, index) {
        const ds = (new DatePipe('EN-US')).transform(new Date(), 'yyyy-MM-dd HH:mm:ss ZZZ');
        let key = (obj.md_id ? obj.md_id : "-(nomdid)" + ds);
        console.log(key)
        if (key === "(draft)") {
            key = "(draft)" + ds;
        }
        let territory_id = 'TD'
        let deletedDoctorsPath = AuthService.client + "/deleted_doctor/" + territory_id
        this.imFire.readFirObject(deletedDoctorsPath + '/' + key).set(obj);
        console.log('Deleted Doctors Path Set! = ' + deletedDoctorsPath + '/' + key)
        let notificationPath = AuthService.client + "/notification/" + AuthService.userName;
        this.imFire.readFirObject(notificationPath + '/' + index + "/fir_ref").set("https://doxs-42fe8.firebaseio.com/" + deletedDoctorsPath + "/" + key);
        console.log('New Notification fir_ref Set! = ' + index)
    }


    checkVal(value: string): string {
        if (value) {
            return value;
        } else {
            return '--';
        }
    }


    approveDeny(i: string, type: string) {


        this.imFire.readFirObject(AuthService.client + '/' + 'notification/' + AuthService.userName + '/' + i)
            .snapshotChanges().subscribe(notif => {
                let n = ({ $key: notif.payload.key, ...notif.payload.val() });

                if (i) {
                    let index = i
                    i = null;
                    let fr = n.fir_ref.replace("https://doxs-42fe8.firebaseio.com/", "");
                    let to = n.from;
                    let fullname = this.currentUser.name;

                    this.imFire.readFirObject(fr).snapshotChanges().subscribe(f => {
                        let d = { ...f.payload.val() }
                        let last_name = f.payload.child('last_name').val();
                        let first_name = f.payload.child('first_name').val();
                        let dateseen = (new DatePipe('EN-US')).transform(new Date(), 'yyyy-MM-dd HH:mm:ss ZZZ');

                        if (fr) {
                            let fir_ref = fr;
                            fr = null
                            let notifBody;
                            let nToast;
                            switch (type) {
                                case 'approveInclusion':
                                    if (this.isValidCustomer(d)) {
                                        let p1 = this.checkVal(d.last_name).substring(0, 1);
                                        let p2 = this.checkVal(d.first_name).substring(0, 1);
                                        let p3 = this.checkVal(d.middle_name).substring(0, 1);

                                        let start = p1 + p2 + p3;
                                        let end = p1 + p2 + p3 + "9";

                                        let doctorPath = AuthService.client + "/doctor/";
                                        let doctorClinicPath = AuthService.client + "/doctor_clinic/";
                                        this.imFire.readFirList(doctorPath, ref => ref.orderByKey().startAt(start).endAt(end)).snapshotChanges().subscribe((snap) => {
                                            if (doctorPath) {
                                                let dp = doctorPath;
                                                doctorPath = null;
                                                let count = 1;
                                                snap.forEach(s => {
                                                    count++;
                                                })
                                                let newMDID: string = (start + this.pad(count, 5)).toUpperCase();
                                                console.log([p1, p2, p3, start, end, count, newMDID.toUpperCase()])
                                                d.md_id = newMDID;
                                                d.inclusion_notification = null;
                                                console.log(d)

                                                let territory_id = fir_ref.split("/")[2];
                                                notifBody = {
                                                    message: "Your request to include " + this.checkVal(d.last_name) + ", " + this.checkVal(d.first_name) + " has been approved by " + fullname,
                                                    actions: "OK",
                                                    type: this.notifType.receipt,
                                                    icon: this.notifIcons.alert,
                                                    subject: "INCLUSION APPROVAL"
                                                }
                                                nToast = {
                                                    message: "Request to include " + this.checkVal(d.last_name) + ", " + this.checkVal(d.first_name) + " has been approved.",
                                                    type: 'success',
                                                    subject: 'INCLUSION APPROVAL'
                                                }

                                                this.imFire.readFirObject(dp + '/' + newMDID).set(d);
                                                this.imFire.readFirObject(doctorClinicPath + "/" + territory_id + '/' + newMDID).set(d);
                                                this.imFire.readFirObject(fir_ref).set(null)
                                                this.sendNotification(to, notifBody, index, nToast);
                                                this.moveToDeleted(d, index);
                                            }

                                        })
                                    } else {
                                        nToast = {
                                            message: "Invalid Inclusion Request.",
                                            type: 'error',
                                            subject: 'INCLUSION APPROVAL'
                                        }
                                        notifBody = {
                                            message: "Your request to INCLUDE " + this.checkVal(d.last_name) + ", " + this.checkVal(d.first_name) + " was DECLINED by " + fullname,
                                            actions: "OK",
                                            type: this.notifType.receipt,
                                            icon: this.notifIcons.alert,
                                            subject: "INCLUSION DECLINED (" + this.checkVal(d.last_name) + ", " + this.checkVal(d.first_name) + ")"
                                        }

                                        let notificationPath = AuthService.client + "/notification/" + AuthService.userName + '/' + index;
                                        this.imFire.readFirObject(notificationPath + "/inclusion_deny_date").set(dateseen);
                                        this.sendNotification(to, notifBody, index, nToast);
                                        this.moveToDeleted(d, index);
                                    }
                                    break;

                                case 'denyInclusion':
                                    notifBody = {
                                        message: "Your request to INCLUDE " + this.checkVal(d.last_name) + ", " + this.checkVal(d.first_name) + " was DECLINED by " + fullname,
                                        actions: "OK",
                                        type: this.notifType.receipt,
                                        icon: this.notifIcons.alert,
                                        subject: "INCLUSION DECLINED (" + this.checkVal(d.last_name) + ", " + this.checkVal(d.first_name) + ")"
                                    }
                                    nToast = {
                                        message: "Request to INCLUDE " + this.checkVal(d.last_name) + ", " + this.checkVal(d.first_name) + " was DECLINED.",
                                        type: 'warning',
                                        subject: 'INCLUSION APPROVAL'
                                    }
                                    this.sendNotification(to, notifBody, index, nToast);
                                    this.moveToDeleted(d, index);
                                    break;

                                case 'approveDeletion':
                                    notifBody = {
                                        message: "Your request to delete " + this.checkVal(d.last_name) + ", " + this.checkVal(d.first_name) + " has been approved by " + fullname,
                                        actions: "OK",
                                        type: this.notifType.receipt,
                                        icon: this.notifIcons.alert,
                                        subject: "DELETION APPROVAL"
                                    }
                                    nToast = {
                                        message: "Request to DELETE " + this.checkVal(d.last_name) + ", " + this.checkVal(d.first_name) + " has been approved.",
                                        type: 'success',
                                        subject: 'DELETION APPROVAL'
                                    }

                                    this.sendNotification(to, notifBody, index, nToast);
                                    this.moveToDeleted(d, index);
                                    this.imFire.readFirObject(fir_ref).set(null)
                                    break;

                                case 'denyDeletion':
                                    notifBody = {
                                        message: "Your request to delete " + last_name + ", " + first_name + " was DECLINED by " + fullname,
                                        actions: "OK",
                                        type: this.notifType.receipt,
                                        icon: this.notifIcons.alert,
                                        subject: "DELETION DECLINED (" + last_name + ", " + d.first_name + ")"
                                    }
                                    nToast = {
                                        message: "Request to DELETE " + this.checkVal(d.last_name) + ", " + this.checkVal(d.first_name) + " was DECLINED.",
                                        type: 'warning',
                                        subject: 'DELETION APPROVAL'
                                    }

                                    this.sendNotification(to, notifBody, index, nToast);
                                    break;

                                case 'approveCTD':
                                    let reason = this.checkVal(d["ctd_reason"]);
                                    let ctd_date = this.checkVal(d.$key);

                                    notifBody = {
                                        message: "Your " + reason + " - CTD request for " + ctd_date + " was APPROVED by " + fullname,
                                        actions: "OK",
                                        type: this.notifType.receipt,
                                        icon: this.notifIcons.alert,
                                        subject: "CTD APPROVED (" + reason + " - " + ctd_date + ")"
                                    }
                                    nToast = {
                                        message: reason + " - CTD request for " + ctd_date + " was APPROVED.",
                                        type: 'success',
                                        subject: "CTD APPROVED (" + reason + " - " + ctd_date + ")"
                                    }

                                    this.imFire.readFirObject(fir_ref + '/ctd_deny_date').set(null);
                                    this.imFire.readFirObject(fir_ref + '/ctd_approve_date').set(dateseen);
                                    this.sendNotification(to, notifBody, index, nToast);
                                    break;

                                case 'denyCTD':
                                    let deny_reason = this.checkVal(d["ctd_reason"]);
                                    let deny_ctd_date = this.checkVal(d.$key);

                                    notifBody = {
                                        message: "Your " + deny_reason + " - CTD request for " + deny_ctd_date + " was DECLINED by " + fullname,
                                        actions: "OK",
                                        type: this.notifType.receipt,
                                        icon: this.notifIcons.alert,
                                        subject: "CTD DECLINED (" + deny_reason + " - " + deny_ctd_date + ")"
                                    }
                                    nToast = {
                                        message: deny_reason + " - CTD request for " + deny_ctd_date + " was DECLINED.",
                                        type: 'warning',
                                        subject: "CTD DECLINED (" + deny_reason + " - " + deny_ctd_date + ")"
                                    }

                                    this.imFire.readFirObject(fir_ref + '/ctd_deny_date').set(dateseen);
                                    this.imFire.readFirObject(fir_ref + '/ctd_approve_date').set(null);
                                    this.sendNotification(to, notifBody, index, nToast);
                                    break;
                            }
                            console.log(type + ' Success!')
                            this.imFire.setSelectedNotification('all')
                        }
                    })

                }


            })

    }




    notifToast(nbody: any) {
        EventsService.createToast(nbody.type, nbody.subject, nbody.message);
    }




    isValidCustomer(obj) {
        if (obj.last_name || obj.first_name) {
            return true;
        }
        return false;
    }

    pad(n, width, z?) {
        z = z || '0';
        width = width || 2;
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    }



    notifType = {
        note: "Note",
        custDeletionRequest: "Deletion",
        custUndeleteNotice: "Undelete",
        custInclusionRequest: "Inclusion",
        custInclusionCancelNotice: "Cancel Inclusion",
        nonFielDayRequest: "Non-Field day",
        receipt: "Receipt",
        like: "Like"
    };

    notifIcons = {
        info: "info",
        question: "question",
        alert: "alert",
        like: "like",
        check: "check"
    };
}



