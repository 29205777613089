import { Pipe, PipeTransform } from '@angular/core';
import { ReportFieldPipe } from "./report/report-field.pipe";
import { BehaviorSubject } from "rxjs/BehaviorSubject";

// Todo: allow array operations for more complex aggregations

@Pipe({
    name: 'agrg8'
})
export class Agrg8Pipe implements PipeTransform {
    static totals = new BehaviorSubject<Object>({});;
    static totalsSubscriptions = Agrg8Pipe.totals.asObservable()

    static reset() {

        Agrg8Pipe.totals = new BehaviorSubject<Object>({});;
        Agrg8Pipe.totalsSubscriptions = Agrg8Pipe.totals.asObservable()

    }
    //static getDataMaps = {};



    transform(value: any, row: Object, col: string, colIndex: number, grouping: number[], colDefinition: any[]): any {

        //if (row == 'show') {
        //    const colName = col.replace(/[ .]/g, '_');
        //    const groupId = (colDefinition[colIndex]['title']).replace(/[ .]/g, '_') + value;
        //
        //    //console.log('showing agrg8 [' + colName + '][' + groupId + ']');
        //
        //    //console.log(Agrg8Pipe.totals);
        //
        //    return Agrg8Pipe.totals[colName][groupId];
        //}

        if (value == null) {
            return value;
        }
        if (colDefinition[colIndex]['aggregation'] || colDefinition[colIndex]['aggregation_formula']) {

            var aggrString = colDefinition[colIndex]['aggregation'];

            var agrTotals = Agrg8Pipe.totals.getValue();

            var colTitle = colDefinition[colIndex]['title'];

            var totals = agrTotals[colTitle] || {};

            if (typeof value == 'string') {
                value = parseFloat(value);
            }

            if (isNaN(value)) {
                value = 0;
            }

            var formula;

            if (colDefinition[colIndex]['aggregation_formula'] != null) {
                
                aggrString = colDefinition[colIndex]['aggregation_formula'];


            } else {

                formula = eval(colDefinition[colIndex]['aggregation']);
            }

            grouping.forEach(i => {

                const colName = (colDefinition[i]['title']).replace(/[ .]/g, '_');

                var groupId = i + row[colName];

                if (typeof row[colName].indexOf == 'function') {

                    if (row[colName].indexOf('getData') != -1) {
                        //console.log('withGetData ' + row[colName]);

                        groupId = i + ReportFieldPipe.getDataMaps[row[colName]];
                    }
                }

                if (formula != null) {
                    totals[groupId] = formula(totals[groupId] || 0, value);
                } else {
                    
                    totals[groupId] = this.aggregate_formula(aggrString, agrTotals, groupId);
                }

            })

            if (formula != null) {

                totals['grand'] = formula(totals['grand'] || 0, value);

            } else {
                
                totals['grand'] = this.aggregate_formula(aggrString, agrTotals, 'grand');
            }

            agrTotals[colTitle] = totals;

        }

        return value;
    }

    aggregate_formula(aggrString : string, agrTotals : any, groupId : string) {
        const vars = aggrString.match(/%[a-z,_,A-Z]*%/g);
                var agFormula = aggrString;
                for (var colN in agrTotals) {
                    const val = agrTotals[colN][groupId];
                    const colNV = colN.replace(/[ .]/g, '_');
                    agFormula = agFormula.replace(`%${colNV}%`,val);
                }
                const fnFormula = eval(agFormula.replace('compute:', '() => {return ') + '}');
                return  fnFormula();
    }

}
