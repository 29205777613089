
import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, BehaviorSubject } from "rxjs";
import { RrDataService, AccessRights } from "app/shared/rr-data.service";

@Injectable()
export class AdminGuard implements CanActivate {


    constructor(private rrd : RrDataService) {
        
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.rrd.userAccess(AccessRights.IgniteAdmin);
    }

    
}
