

// export const firebaseConfig = {
//     // Paste all this from the Firebase console...
//     apiKey: "AIzaSyCLM2hBxxDeFkJHEKu7qh5_m8RDhGz0JUk",
//     authDomain: "doxs-42fe8.firebaseapp.com",
//     databaseURL: "https://doxs-42fe8.firebaseio.com",
//     storageBucket: "doxs-42fe8.appspot.com",
//     messagingSenderId: "240522491766"
// };

export const firebaseConfig = {
    // apiKey: "AIzaSyCJTtJcmYvv3Vr0OC1I73rlDsT0053nKjg",
    // authDomain: "ignitemanager-3e153.firebaseapp.com",
    // databaseURL: "https://ignitemanager-3e153.firebaseio.com",
    // projectId: "ignitemanager-3e153",
    // storageBucket: "ignitemanager-3e153.appspot.com",
    // messagingSenderId: "741335284412"

    apiKey: "AIzaSyCLM2hBxxDeFkJHEKu7qh5_m8RDhGz0JUk",
    authDomain: "doxs-42fe8.firebaseapp.com",
    databaseURL: "https://doxs-42fe8.firebaseio.com",
    storageBucket: "doxs-42fe8.appspot.com",
    messagingSenderId: "240522491766"
};

