import { Injectable, Inject, EventEmitter, Output } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { FirebaseApp } from '@angular/fire';
import { AuthService } from 'app/shared/auth.service';
import * as firebase from 'firebase';
import { TreeviewItem } from "ngx-treeview/src/index";
import { BehaviorSubject, Subject, Observable } from "rxjs";
import { AngularFireAuthModule } from "@angular/fire/auth/auth.module";
import { AngularFireAction } from "@angular/fire/database/interfaces";
import { RrLibService } from "./rr-lib.service";
import { DatabaseReference } from "@angular/fire/database-deprecated/interfaces";
import { AngularFireObject } from "@angular/fire/database/interfaces";
import 'rxjs/add/operator/filter';
import { CustomerData, LookupsData } from 'app/customers/customer';
import { EventsService } from "./shared/events.service";
import { DatePipe } from '@angular/common';
import { AngularFireStorage } from '@angular/fire/storage';
import { switchMap, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';


@Injectable()
export class ImFireService {

    static territories: string[];
    static loadingInfo = new BehaviorSubject<Object>({ target: 0, done: 0, progress: 0 });

    private _all_territories = new BehaviorSubject<string[]>(['none']);
    public userTerritories = this._all_territories.asObservable();


    private sourceTerritories = new BehaviorSubject<string[]>(["none"]);
    selectedTerritories = this.sourceTerritories.asObservable();

    private rawCallsPerDayPerRep = {};
    private callsPerDayPerRep = new BehaviorSubject<any[]>([{}]);
    selectedCallsPerDayTotals = this.callsPerDayPerRep.asObservable();

    static _currentCycle = new BehaviorSubject<string>(RrLibService.dateToLongString(new Date).substr(0, 7));
    static currentCycle = ImFireService._currentCycle.asObservable();

    static _period = new BehaviorSubject<Object>({});
    static period = ImFireService._period.asObservable();

    public profileImgUpload = new BehaviorSubject<boolean>(false);
    public passwordUpdate = new BehaviorSubject<boolean>(false);

    private _userData = new BehaviorSubject<any>(null)
    public userData = this._userData.asObservable();

    private notifications = new BehaviorSubject<any>('all');
    selectedNotification = this.notifications.asObservable();


    constructor(private afb: AngularFireDatabase, private auth: AuthService, private setDate: DatePipe, private afs: AngularFireStorage) {
        auth.currUser.subscribe(user => {
            if (user.isAnonymous != true) {
                this.clientGetObject(`users/${user.userName}`).subscribe(userInfo => {
                    this._userData.next(userInfo);
                    const terrId = userInfo['territory_id'] || "";
                    this._all_territories.next(terrId.split('||'));
                })
            }
        })
    }

    readFirList(path: string, query?): AngularFireList<any> {

        return this.afb.list(path, query);
    }

    readFirObject(path: string, query?): AngularFireObject<any> {
        return this.afb.object(path);
    }

    clientGetObject(path: string): Observable<any> {

        return this.afb.object(AuthService.client + '/' + path).valueChanges();
    }

    clientGetList(path: string): Observable<any[]> {
        return this.afb.list(AuthService.client + '/' + path).valueChanges();
    }

    clientGetListRef(path: string) {
        return this.afb.list<any>(AuthService.client + '/' + path);
    }

    getProfileImageURL(fileName: string): Promise<any> {

        // return new Promise((res, reg) => { res("images/profile.jpg")});
        if (fileName) {
            return this.afs.storage.ref(AuthService.client + '/' + fileName).getDownloadURL().catch(err => {
                return Promise.resolve('images/profile.jpg')    
            })
        } else {
            return Promise.resolve('images/profile.jpg')
        }
        // return firebase.storage().ref().child(AuthService.client + '/' + fileName).getDownloadURL();

    }


    updateProfileImage(file: File, profileImg: string, territory: string) {
        let timestamp: number = Date.now()
        const fileName = territory + '_' + AuthService.userName + '_profile_' + timestamp;
        const metaData = { 'contentType': file.type };
        const path = 'users/' + AuthService.userName;
        const updateProfileImgName = this.afb.object(AuthService.client + '/' + path);
        let user = firebase.auth().currentUser;

        let uploadTask = firebase.storage().ref(AuthService.client + '/' + fileName).put(file, metaData);
        console.log("Uploading:", fileName);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
            (snapshot) => {
                // upload in progress
                //   file.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                this.profileImgUpload.next(true)
            },
            (error) => {
                // upload failed
                // console.log(error)
                EventsService.createToast('error', 'User Profile', error.message);
            },
            () => {

                updateProfileImgName.snapshotChanges().subscribe(udata => {
                    if (udata.payload.hasChild('profile_picture')) {
                        updateProfileImgName.update({ profile_picture: fileName });
                        console.log('FileName Updated');
                    } else {
                        this.afb.object(AuthService.client + '/' + path + '/profile_picture').set(fileName);
                        console.log('New Profile Picture Set');
                    }
                })

                if (profileImg) {
                    firebase.storage().ref(AuthService.client + '/' + profileImg).delete();
                    console.log("Old File Deleted:", profileImg);
                    profileImg = null
                }
                EventsService.createToast('success', 'User Profile', 'Profile Picture Updated');
                this.profileImgUpload.next(false);
            }
        );
    }

    updateUserDetails(userDetails: any) {
        let path = AuthService.client + '/' + 'users/' + AuthService.userName;
        const updateUserDetails = this.afb.object(path);
        let user = firebase.auth().currentUser;

        if (userDetails) {
            for (let i in userDetails) {
                if (i === 'last_location') {
                    this.afb.object(path + '/' + i + '/address').set(userDetails[i])
                    console.log(userDetails[i] + " Set/Updated")
                } else {
                    this.afb.object(path + '/' + i).set(userDetails[i] || '')
                    console.log(userDetails[i] + " Set/Updated")
                }
            }
            EventsService.createToast('success', 'User Profile', 'Details Updated');
            userDetails = null
        }
    }

    updateUserPassword(newPassword: string, oldPassword: string) {
        var user = firebase.auth().currentUser;

        var path = AuthService.client + '/users/' + AuthService.userName + '/password';
        var updatePass = this.readFirObject(path)
        var credentials = firebase.auth.EmailAuthProvider.credential(user.email, oldPassword);
        this.passwordUpdate.next(true);
        user.reauthenticateWithCredential(credentials)
            .then(() => {
                console.log('reauth ok')
                user.updatePassword(newPassword).then((success) => {
                    // Update Password successful.
                    updatePass.set(newPassword);
                    EventsService.createToast('success', 'User Profile', 'Password Updated');
                    this.passwordUpdate.next(false);
                }).catch((error) => {
                    // Error on Updating Password.
                    EventsService.createToast('error', 'User Profile', error);
                    this.passwordUpdate.next(false);
                });
            }).catch((error) => {
                // Error on reauth
                EventsService.createToast('error', 'User Profile', error);
                this.passwordUpdate.next(false);
            });

    }

    getNotificationTime(notifDateTime: any): string {
        let ds: any = RrLibService.dateToLongString(new Date());
        let n = Date.parse(notifDateTime)
        let nd = Date.parse(ds)
        let datDiff = nd - n
        let interval = Math.floor(datDiff / ((1000 * 60 * 60 * 24) * 365));
        if (interval > 0) {
            return interval + " year" + (interval > 1 ? "s" : "") + " ago";
        }
        interval = Math.floor(datDiff / (1000 * 60 * 60 * 24 * 30));
        if (interval > 0) {
            return interval + " month" + (interval > 1 ? "s" : "") + " ago";
        }
        interval = Math.floor(datDiff / (1000 * 60 * 60 * 24));
        if (interval > 0) {
            return interval + " day" + (interval > 1 ? "s" : "") + " ago";
        }

        interval = Math.floor((datDiff / (1000 * 60 * 60)) % 24);
        if (interval > 0) {
            return interval + " hr" + (interval > 1 ? "s" : "") + " ago";
        }
        interval = Math.floor((datDiff / (1000 * 60)) % 60);
        if (interval > 0) {
            return interval + " min" + (interval > 1 ? "s" : "") + " ago";
        }
        return "A moment ago";
    }

    setSelectedNotification(index: string) {
        this.notifications.next(index)
    }



    setSelectedTerritories(selection: string[]) {
        this.sourceTerritories.next(selection);
    }


    getWorkingDays(): number {
        return 14;
    }

    getTargetDailyAverageCall(): number {
        return 21;
    }

    getTargetCallsToDate(): number {
        return this.getWorkingDays() * this.getTargetDailyAverageCall();
    }

    getCustomers(path: string): AngularFireList<any> {

        return this.afb.list<any>(path
            // , ref => ref.limitToFirst(50)
        );

        // return this.afb.list<any>(path,  ref => ref.orderByChild('size'));

    }

    getCardCustomers(path: string) {
        return this.afb.list<any>(path);
    }

    SearchCardCustomers(path: string, start: BehaviorSubject<string>, currentFilter: string): Observable<any[]> {

        return start.pipe(switchMap(startText => {
            const endText = startText + '\uf8ff';
            return this.afb
                .list(path, ref =>
                    ref
                        .orderByChild(currentFilter)
                        .startAt(startText)
                        .endAt(endText)
                )
                .snapshotChanges().pipe(
                    debounceTime(200),
                    distinctUntilChanged(),
                    map(changes => {
                        // console.log(changes);
                        return changes.map(c => {
                            // console.log(c);
                            return { key: c.payload.key, ...c.payload.val() };
                        });
                    })
                )
                
        }))

        // return start.switchMap(startText => {
        //     const endText = startText + '\uf8ff';
        //     return this.afb
        //         .list(path, ref =>
        //             ref
        //                 .orderByChild(currentFilter)
        //                 .startAt(startText)
        //                 .endAt(endText)
        //         )
        //         .snapshotChanges()
        //         .debounceTime(200)
        //         .distinctUntilChanged()
        //         .map(changes => {
        //             // console.log(changes);
        //             return changes.map(c => {
        //                 // console.log(c);
        //                 return { key: c.payload.key, ...c.payload.val() };
        //             });
        //         });
        // });
    }

    getUserCalls(path: string, key: string): AngularFireList<any> {
        var territory = "TD"
        var date = new Date();
        var startDate = new Date(date.getFullYear(), date.getMonth());
        var endDate = new Date();
        this.setDate.transform(startDate, 'yyyy-MM-dd')
        this.setDate.transform(endDate, 'yyyy-MM-dd')



        return this.afb.list(path + '/' + territory);
    }

    getUserNotes(path: string, key: string): AngularFireList<any> {
        var territory = "TD"
        var date = new Date();
        var startDate = new Date(date.getFullYear(), date.getMonth());
        var endDate = new Date();
        this.setDate.transform(startDate, 'yyyy-MM')
        this.setDate.transform(endDate, 'yyyy-MM-dd')



        return this.afb.list(path + '/' + territory + '/2018-02');
    }



    getLookups(path: string): AngularFireObject<LookupsData> {
        return this.afb.object<LookupsData>(path);
    }

    updateCustomerData(path: string, userdata: CustomerData, doctorDataChild) {
        var update = this.afb.object(path);
        update.snapshotChanges().subscribe(item => {
            if (userdata) {
                for (var i in doctorDataChild) {
                    if (item.payload.hasChild(doctorDataChild[i])) {
                        update.update({ [doctorDataChild[i]]: userdata[doctorDataChild[i]] });
                        console.log(doctorDataChild[i] + ' Updated');
                    } else if ((!item.payload.hasChild(doctorDataChild[i])) && (userdata[doctorDataChild[i]])) {
                        this.afb.object(path + '/' + doctorDataChild[i]).set(userdata[doctorDataChild[i]]);
                        console.log('New ' + doctorDataChild[i] + ' Set');
                    }
                }
            }
            userdata = null
        })
    }

}
