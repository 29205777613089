import { DatabaseSnapshot } from "@angular/fire/database";
// import { get } from "http";
import { RrLibService } from "../rr-lib.service";
import { ImFireService } from "app/im-fire.service";
import { RrDataService } from "app/shared/rr-data.service";
import { AuthService } from "app/shared/auth.service";
import { DataSnapshot } from '@angular/fire/database/interfaces';


export class CustomerData {
  key: string;
  first_name: string;
  specialty_description: string;
  last_name: string;
  middle_name: string;
  class_code: string;
  frequency: string = '';
  clinic_address: string;
  md_id: string;
  $key: string;
  childx: any;
  lic_no: string;
  address_city: string;
  gender: string;
  birth_date: string;
  profile_pic: string;
  delete_notification: string;
  inclusion_notification: string;
  territory: string;
  username: string;
  // bg_color: string = "white";
  snap: DataSnapshot;
  cycle: string = RrLibService.cycleToday();

  bg_color: string;
  // get bg_color(): string {

  //   return (this.delete_notification != null) ? 'orange' : (this.inclusion_notification != null) ? 'gray'
  //     : this.call_status == 'Called To Target' ? 'rgb(140, 241, 114)' : this.call_status == 'Reached' ? 'rgb(243, 235, 127)' : 'rgb(243, 235, 235)';

  // }

  color: string;
  // get color(): string {
  //   return (this.delete_notification != null) ? 'white' : (this.inclusion_notification != null) ? 'white' : 'black'
  // }

  call_status: string;

  _call_status(): string {

    const frequency = (typeof this.frequency == 'string') ? parseInt(this.frequency.replace(/x/gi, '')) : this.frequency;
    const callCount = this.callCount

    return callCount >= frequency ? 'Called To Target' : callCount > 0 ? 'Reached' : 'Unreached';

  }

  callCount: number;
  _callCount(): number {

    const cycle = RrLibService.cycleToday();
    const callsField = `calls_${cycle}`;

    const callCount = parseInt(this[callsField] || '0');
    
    return callCount

  }


  isFromMasterList: boolean = false;
  // get isFromMasterList(): boolean {
  //   return this.snap.ref.path.toString().indexOf("doctor_clinic_master") != -1;
  // }

  toggleDelete() {

    if (this.delete_notification != null) {
      this.snap.ref.child('delete_notification').remove();
      this.snap.ref.child('delete').remove();
    } else {
      this.snap.ref.child('delete_notification').set(`delete requested via IM2 ${AuthService.userName} on ${Date()}`);
      this.snap.ref.child('delete').set('yes')
    }

  }

  save() {
    var rec = {};

    for (const fname in this) {
      if ('toggleDelete,_callCount,callCount,isFromMasterList,_call_status,call_status,color,bg_color,cycle,snap,key,rrd,$key,territory,username,source,save,getCustomerKey'.indexOf(fname) == -1) {
        let f = fname.toString();
        let v = this[f]
        if (v != null && v != "") {
          if (typeof v != 'object') {
            rec[f] = v;
          }
        }
      }
    }

    // fields.forEach(field => {
    //   const fname = field['field_name'];
    //   if (this[fname]) {
    //     rec[fname] = this[fname];
    //   }
    // })

    rec['territory_id'] = this.territory;
    rec['userid'] = this.username || AuthService.userName;

    if (this.snap == null) {

      rec['inclusion_notification'] = `saved from IM2 ${this['source']}`

      const mdID = CustomerData.getCustomerKey(rec);
      rec['md_id'] = mdID;

      const ref = `${AuthService.client}/${this['source']}/${this.territory}/${mdID}`;


      this.rrd.firbaseRootRef.child(ref).update(rec);

    } else {

      this.snap.ref.set(rec);

    }
  }

  constructor(snap: DataSnapshot, private rrd: RrDataService) {

    // ImFireService.currentCycle.subscribe(cycle => this.cycle = cycle)

    this.cycle = RrLibService.cycleToday();

    if (snap == null) {
      this.key = 'New';
      this.md_id = '(draft)'
    } else {
      
      this.key = snap.key;
      const v = snap.val();
      this.snap = snap;
      Object.keys(v).forEach(k => {
        this[k] = v[k];
      })

      this.isFromMasterList = this.snap.ref.toString().indexOf("doctor_clinic_master") != -1;
      // this.isFromMasterList = this.snap.ref.toString() == "doctor_clinic_master";
      // console.log(this.isFromMasterList)

      
    }


    // debugger
    const u = this.rrd.userData.getValue();
    this.territory = this['territory_id'] || u.territory_id;

    this.color = (this.delete_notification != null) ? 'white' : (this.inclusion_notification != null) ? 'white' : 'black';


    this.callCount = this._callCount();
    this.call_status = this._call_status();
    this.bg_color = (this.delete_notification != null) ? 'orange' : (this.inclusion_notification != null) ? 'gray'
      : this.call_status == 'Called To Target' ? 'rgb(140, 241, 114)' : this.call_status == 'Reached' ? 'rgb(243, 235, 127)' : 'rgb(243, 235, 235)';






    // if (snap.hasChild(`calls_${this.cycle}`) == false) {
    //   this.rrd.clientGetObject(`/calls_per_customer/${this.cycle}/${this.territory}/${this.$key}`)
    //     .snapshotChanges().subscribe(
    //     r => {

    //       this[`calls_${this.cycle}`] = r.payload.numChildren();
    //       //this.snap.child(`calls_${this.cycle}`).ref.set( `${r.payload.numChildren()}`);

    //     });

    // }

  }

  static getCustomerKey(cData: any): string {

    const p1 = (cData['last_name'] || '-').substring(0, 1);
    const p2 = (cData['first_name'] || '-').substring(0, 1) || '-';
    const p3 = (cData['middle_name'] || '-').substring(0, 1) || '-';

    const str = `${(cData['last_name'] || '-')}${(cData['first_name'] || '-')}${(cData['middle_name'] || '-')}${(cData['specialty_description'] || '-')}`
    const p4 = RrLibService.codeFromString(str.toUpperCase());

    return (p1 + p2 + p3 + p4).toUpperCase();
  }

}

export interface GroupbySpec {
  spec_desc: string;
  customer: CustomerData[];
  pageEnd: number;
}

export class LookupsData {
  class_code: string;
  ctd_reasons: string;
  delete_reasons: string;
  frequency: string;
  product_list: string;
  specialty: string;
  time: string;
}