// TODO: fix the hovering position of the dot.  right now it doesn't appear on the right position
// processChart URL: https://us-central1-doxs-42fe8.cloudfunctions.net/im2DashComponentData?client=britton&cycle=2019-01&selectedTerritories=TM1-1|TM1-2|TM1-3|TM1-4&chartRef=config/dash_charts/0
import { Component, OnInit, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { ImFireService } from "../../im-fire.service";
import { DatabaseSnapshot } from "@angular/fire/database/interfaces";
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { RrDataService } from "../../shared/rr-data.service";
import { DashChart } from "../../shared/rr-data.service";
import { RrLibService } from "../../rr-lib.service";
import { NvD3Component } from "ng2-nvd3/build/index";
import { AfterViewInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { Subscription } from 'rxjs/Subscription';
import { ChangeDetectionStrategy } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { testDCM } from './testdata';
import { BehaviorSubject } from 'rxjs';

declare let d3: any;

@Component({
    selector: 'rr-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.css',
        '../../../../node_modules/nvd3/build/nv.d3.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent implements OnInit, AfterViewInit {

    @ViewChild('d3Chart') d3Chart: NvD3Component;
    @Input() title: string;

    public options;
    public data = [];
    private testdata = testDCM;
    listener = {};

    constructor(private rrd: RrDataService, private rrl: RrLibService, private cdr: ChangeDetectorRef, private http: HttpClient) {

    }



    private selectedTerritories = [];
    private dashChart: DashChart;
    private cycle: string;
    private uListSubs: Subscription;
    private JSONData = new BehaviorSubject<any>(null)

    public xlabels = {};
    terrData = {}


    processChartHTTP() {

        const cycle = this.cycle;
        const territories = this.selectedTerritories.join("|") || "none";
        const dashChart = this.dashChart;
        const path = dashChart.path.replace("https://doxs-42fe8.firebaseio.com/", "")
        var dashboardno = path[path.length - 1];
        this.data = []

        // this.http.request(`http://us-central1-doxs-42fe8.cloudfunctions.net/im2DashComponentData?client=britton&cycle=${cycle}&selectedTerritories=${territories}&chartRef=${path}`).subscribe(res => {

        //     // console.log(`http://us-central1-doxs-42fe8.cloudfunctions.net/im2DashComponentData?client=britton&cycle=${cycle}&selectedTerritories=${territories}&chartRef=${path}`)

        //     const obj = res.json()
        //     console.log(JSON.stringify(obj))
        //     this.data = obj
        //     this.cdr.markForCheck()
        // })

        // const obj = this.http.get(`http://us-central1-doxs-42fe8.cloudfunctions.net/im2DashComponentData?client=britton&cycle=${cycle}&selectedTerritories=${territories}&chartRef=${path}`)

        // console.log(JSON.stringify(obj))
        // this.data = obj
        // this.cdr.markForCheck()


        // obj.subscribe(snap => {
        //     console.log(snap)
        // })
        this.selectedTerritories.forEach(terrID => {
            var timer
            this.terrData = {}
            this.rrd.clientGetObject(`dashboard_data/${cycle}/${dashboardno}/${terrID}`).snapshotChanges().subscribe(snap => {
                // console.log(this.terrData)

                if (snap.payload.val()) {
                    this.terrData[terrID] = snap.payload.val()
                    console.log(snap.payload.val())
                    if (timer) {
                        clearTimeout(timer)
                        timer = null
                    }

                    setTimeout(() => {
                        console.log(this.terrData)
                        timer = this.summarizeData()
                    }, 100)

                }
            })
        })

    }

    summarizeData() {
        this.data = []

        if (this.dashChart.target) {
            var actualslabels = []
            var targetslabels = []
            var avalues = []
            var tvalues = []
            for (const i in this.terrData) {
                // console.log(`data has target`)

                const alabels = this.terrData[i]["Actual"]["values"]
                const tlabels = this.terrData[i]["Target"]["values"]
                for (const x in alabels) {
                    // console.log(`Actual : ${i} : ${alabels[x]['label']} : ${alabels[x]['value']}`)
                    // console.log(`Target : ${i} : ${tlabels[x]['label']} : ${tlabels[x]['value']}`)
                    actualslabels[alabels[x]['label']] = (actualslabels[alabels[x]['label']] || 0) + alabels[x]['value']
                    targetslabels[tlabels[x]['label']] = (targetslabels[tlabels[x]['label']] || 0) + tlabels[x]['value']
                }

            }
            // console.log(actualslabels)
            for (const x in actualslabels) {
                const rate = Math.round((actualslabels[x] / targetslabels[x]) * 100)
                const tooltip = (actualslabels[x] * targetslabels[x]) > 0 ? `${rate}% = ${actualslabels[x]}/${targetslabels[x]}` : `${actualslabels[x]}/${targetslabels[x]}`

                avalues.push({label : x, value : actualslabels[x], color : rate < 25 ? 'red' : rate < 50 ? 'orange' : rate < 75 ? 'cyan' : 'green', tooltip : tooltip})
                tvalues.push({label : x, value : targetslabels[x], color : '#bbaaaa', tooltip : tooltip})
            }
            // console.log(avalues)
            // console.log(tvalues)
            this.data.push({ values: avalues, key: `Actual` });
            this.data.push({ values: tvalues, key: `Target` });
        } else {
            for (const i in this.terrData) {
                // console.log(this.terrData[i])
                const labels = this.terrData[i]['values'] || []
                const values = []
                for (const x in labels){
                    values.push({label : labels[x]['label'], value : labels[x]['value'] || 0})
                }
                this.data.push({values : values, color : this.terrData[i]['color'], key : this.terrData[i]['key']})
            }
            // for (const i in this.terrData) {
            //     this.data.push(this.terrData[i])
            // }
        }
        console.log(this.data)
        this.cdr.markForCheck()
    }

    processChart() {

        const cycle = this.cycle;
        const territories = this.selectedTerritories;
        const dashChart = this.dashChart;
        var xlabels = this.xlabels;
        var terrData = this.terrData;
        var terrTarget = {}

        var d3Chart = this.d3Chart;

        this.data = [];

        if (this.uListSubs) {
            this.uListSubs.unsubscribe()
        }

        if (cycle && territories && dashChart) {

            territories.forEach((terrId, series) => {

                this.uListSubs = this.rrd.allUsers.subscribe(userList => {

                    if (terrId != 'none') {

                        if (this.listener[terrId]) {
                            this.listener[terrId].unsubscribe();
                        }

                        if (this.listener[`target-${terrId}`]) {
                            this.listener[`target-${terrId}`].unsubscribe()
                        }

                        /*
                         * prepare the path where the dashboard data will be coming from
                         * everything is always grouped by territory and by cycle so we can interact with the
                         * Territory Explorer
                        * */
                        var path = dashChart.source.replace('%terrId%', terrId).replace('%cycle%', cycle);
                        var tPath: string
                        const keys = path.match(/{[a-z,A-Z]*}/g);

                        var promArr = []

                        if (dashChart.target) {

                            tPath = dashChart.target.replace('%terrId%', terrId).replace('%cycle%', cycle);

                            const tkeys = path.match(/{[a-z,A-Z]*}/g);
                            tkeys.forEach(k => tPath = tPath.replace(k, ''));
                            const p1 = new Promise<any>((resolve, reject) => {
                                this.listener[`target-${terrId}`] = this.rrd.clientDataRead(tPath).snapshotChanges().subscribe(list => {

                                    if (list.length > 0) {

                                        // these are the field names to be used for both axes
                                        const xValueField = dashChart.x_axis['value'].replace(/%/g, '');
                                        const yValueField = dashChart.y_axis['value'].replace(/%/g, '');

                                        /*
                                         go through each row and convert the snapshot into an object while assigning
                                         the {<keys>} as additional fields to the object
                                        */
                                        list.forEach(snap => {

                                            var flatData = this.rrl.flatten(snap.payload, keys);
                                            flatData['cycle'] = cycle;
                                            flatData['territory_id'] = terrId;
                                            // flatData['name'] = userList.byTerr[terrId]['name'];

                                            const xValue = flatData[xValueField];
                                            const yValue = flatData[yValueField] || 0;

                                            /*
                                            *
                                            * arrange the data in an object for easier manipulation
                                            *
                                            * */

                                            if (terrTarget[xValue] == null) {
                                                terrTarget[xValue] = {}
                                            }

                                            if (xlabels[xValue] == null) {

                                                xlabels[xValue] = {};

                                            } else {

                                                terrTarget[xValue][terrId] = yValue;

                                                var sum = 0
                                                for (let terr in terrTarget[xValue]) {
                                                    sum += terrTarget[xValue][terr]
                                                }

                                                xlabels[xValue]['Target'] = sum;

                                            }
                                        })

                                        resolve('ok')

                                    }
                                });
                            })

                            promArr.push(p1)

                        }

                        keys.forEach(k => path = path.replace(k, ''));

                        const p2 = new Promise<any>((resolve, reject) => {
                            this.listener[terrId] = this.rrd.clientDataRead(path).snapshotChanges().subscribe(list => {

                                if (list.length > 0) {


                                    // these are the field names to be used for both axes
                                    const xValueField = dashChart.x_axis['value'].replace(/%/g, '');
                                    const yValueField = dashChart.y_axis['value'].replace(/%/g, '');

                                    /*
                                     go through each row and convert the snapshot into an object while assigning
                                     the {<keys>} as additional fields to the object
                                    */
                                    list.forEach(snap => {

                                        var flatData = this.rrl.flatten(snap.payload, keys);
                                        flatData['cycle'] = cycle;
                                        flatData['territory_id'] = terrId;
                                        // flatData['name'] = userList.byTerr[terrId]['name'];

                                        const xValue = flatData[xValueField];
                                        const yValue = flatData[yValueField] || 0;

                                        /*
                                        *
                                        * arrange the data in an object for easier manipulation
                                        *
                                        * */
                                        if (dashChart.target) {
                                            if (terrData[xValue] == null) {
                                                terrData[xValue] = {}
                                            }

                                            if (xlabels[xValue] == null) {

                                                xlabels[xValue] = {};

                                            } else {

                                                terrData[xValue][terrId] = yValue;

                                                var sum = 0
                                                for (let terr in terrData[xValue]) {
                                                    sum += terrData[xValue][terr]
                                                }

                                                xlabels[xValue]['Actual'] = sum;

                                            }

                                        } else {
                                            if (xlabels[xValue] == null) {
                                                xlabels[xValue] = {};
                                            } else {
                                                xlabels[xValue][terrId] = yValue;

                                            }
                                        }

                                    })

                                    resolve('ok');

                                }

                            });
                        })

                        promArr.push(p2)

                        Promise.all(promArr).then((r) => {
                            this.data = [];

                            // sort the x-axis data
                            const xkeys = Object.keys(xlabels).sort((a, b) => {
                                return a < b ? -1 : a > b ? 1 : 0
                            });

                            xkeys.forEach(xlabel => {
                                if (dashChart.target) {
                                    var x = xlabels[xlabel];
                                    if (x['Actual'] == null) {
                                        x['Actual'] = 0;
                                    }
                                    if (x['Target'] == null) {
                                        x['Target'] = 0;
                                    }
                                } else {
                                    territories.forEach(t => {
                                        var x = xlabels[xlabel];
                                        if (x[t] == null) {
                                            x[t] = 0;
                                        }
                                    });
                                }

                            })

                            if (this.dashChart.target) {

                                var act = 0;
                                var tar = 0;

                                const actuals = [];
                                const targets = []

                                xkeys.forEach(x => {

                                    act = xlabels[x]['Actual'] || 0;
                                    tar = xlabels[x]['Target'] || 0;

                                    const rate = Math.round((act / tar) * 100)

                                    const tooltip = (act * tar) > 0 ? `${rate}% = ${act}/${tar}` : `${act}/${tar}`

                                    actuals.push({ label: x, value: xlabels[x]['Actual'], color: rate < 25 ? 'red' : rate < 50 ? 'orange' : rate < 75 ? 'cyan' : 'green', tooltip: tooltip });
                                    targets.push({ label: x, value: xlabels[x]['Target'], color: '#bbaaaa', tooltip: tooltip });

                                })

                                this.data.push({ values: actuals, key: `Actual` });
                                this.data.push({ values: targets, key: `Target` });

                                console.log('this.xlabels', this.xlabels)

                            } else {
                                territories.forEach(t => {
                                    const trows = [];
                                    xkeys.forEach(x => {
                                        trows.push({ label: x, value: xlabels[x][t] });
                                    })

                                    if (userList.byTerr[t] != null) {
                                        this.data.push({ values: trows, color: userList.byTerr[t]['color'], key: `${t} - ${userList.byTerr[t]['name']}` });
                                    } else {
                                        this.data.push({ values: trows, key: `${t} - Unknown` });
                                    }

                                })

                                if (d3Chart != null) {
                                    // d3Chart.options.chart.rotateLabels = 45
                                    d3Chart.options.showLegend = this.selectedTerritories.length < 11;
                                }

                            }
                            console.log(this.data)
                            this.cdr.markForCheck()
                        })
                    }
                })
            })
        }


    }


    ngOnInit() {

        /*this.rrd.clientDataRead("config/dash_charts",
            { query : {
                orderByChild:'title',
                limitToFirst:1,
                startAt: this.title
            }}).snapshotChanges().subscribe(dashChart => {
            debugger*/

        this.rrd.chartList.subscribe(clist => {
            if (clist) {
                this.dashChart = clist.find(x => x.title == this.title)

                if (this.dashChart) {

                    this.options = {
                        chart: {
                            type: this.dashChart.type,
                            height: this.dashChart.height || 250,
                            //interpolate: "cardinal",
                            tension: 0,
                            margin: {
                                top: this.dashChart.margin['top'] || 20,
                                right: this.dashChart.margin['right'] || 20,
                                bottom: this.dashChart.margin['bottom'] || 50,
                                left: this.dashChart.margin['left'] || 20
                            },

                            x: this.dashChart.x_axis['type'] == 'date' ? function (d) {
                                return new Date(d.label);
                            } : function (d) {
                                return d.label;
                            },

                            y: this.dashChart.y_axis['type'] == 'date' ? function (d) {
                                return new Date(d.label);
                            } : function (d) {
                                return d.value;
                            },
                            showControl: false,
                            showValues: false,
                            showLegend: false,
                            stacked: this.dashChart.stacked,
                            rotateLabels: 0,
                            barColor: this.dashChart.target != null ? function (d) {

                                return d.color
                            } : null,
                            tooltip: this.dashChart.target != null ? {
                                headerFormatter: this.getXLabels
                            } : null,
                            duration: 500,
                            xAxis: {
                                axisLabel: this.dashChart.x_axis['label'],
                                ticks: {
                                    fontSize: 6
                                }
                            },
                            yAxis: {
                                axisLabel: this.dashChart.y_axis['label']


                            }
                        }
                    }


                    if (this.dashChart.x_axis['axisLabelDistance']) {
                        this.options.chart.xAxis.axisLabelDistance = this.dashChart.x_axis['axisLabelDistance'];
                    }
                    if (this.dashChart.y_axis['axisLabelDistance']) {
                        this.options.chart.yAxis.axisLabelDistance = this.dashChart.y_axis['axisLabelDistance'];
                    }

                    if (this.dashChart.x_axis['tickFormat']) {
                        this.options.chart.xAxis.tickFormat = eval(this.dashChart.x_axis['tickFormat']);
                    }
                    if (this.dashChart.y_axis['tickFormat']) {
                        this.options.chart.yAxis.tickFormat = eval(this.dashChart.y_axis['tickFormat']);
                    }
                    if (this.dashChart.valueFormat) {
                        this.options.chart.valueFormat = eval(this.dashChart.valueFormat);
                    }

                    if (this.dashChart.interpolate) {
                        this.options.chart.interpolate = this.dashChart.interpolate;
                    }

                    this.processChartHTTP();
                    // this.processChart();
                    if (this.d3Chart != null) {
                        this.d3Chart.updateWithOptions(this.options);
                    }
                }

            }
        });

        this.rrd.currentCycle.subscribe(cycle => {
            if (cycle) {
                this.xlabels = {};
                this.cycle = cycle;
                this.processChartHTTP();
                // this.processChart();
            }
        });

        this.rrd.selectedTerritories.subscribe(tList => {
            if (tList) {
                this.selectedTerritories = tList;
                // this.data = this.testdata;
                this.processChartHTTP()
                // this.processChart();
            }
        });
    }

    ngAfterViewInit() {

    }

    getXLabels(d) {
        const data = d3.event.toElement.__data__

        return data ? `${d} : ${data['tooltip']}` : d
    }
}

function dateFormat(d: Date, format: string): string {

    const dp = new DatePipe('en');
    return dp.transform(d, format);
}
