import { Component, OnInit } from '@angular/core';
import { RrDataService, Widget, DashChart } from 'app/shared/rr-data.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.css']
})
export class DashboardPageComponent implements OnInit {

  private widgets : Widget[]
  private chartList : DashChart[]

  constructor(private rrd : RrDataService) { 
    rrd.widgetList.subscribe(widgetList => {
      this.widgets = widgetList
    })
    
    rrd.chartList.subscribe(chartList => {
      this.chartList = chartList
    })
  }

  ngOnInit() {
  }

}
