import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable } from "rxjs";
import { DatabaseSnapshot } from "@angular/fire/database-deprecated/interfaces";

@Injectable()
export class RrLibService {

    static pad(num: number, width_of_pad: number, what_to_pad: string) {

        what_to_pad = what_to_pad || '0';
        width_of_pad = width_of_pad || 2;
        const number = num + '';

        return number.length >= width_of_pad ? number : new Array(width_of_pad - number.length + 1).join(what_to_pad) + number;
    }

    static dateToLongString(date: Date): string {
        const ds = (new DatePipe('EN-US')).transform(date, 'yyyy-MM-dd HH:mm:ss');
        return ds;
    }

    static dateToday(): string {
        return (new DatePipe('EN-US')).transform(new Date(), 'yyyy-MM-dd');
        // return '2018-04-27'
    }

    static cycleToday(): string {
        return (new DatePipe('EN-US')).transform(new Date(), 'yyyy-MM');
    }

    constructor() {
    }


    flatten(data: DatabaseSnapshot, keys: string[]) {

        var result = {};

        function recurse(cur: DatabaseSnapshot, level) {

            if (level < keys.length) {

                const k = keys[level].replace('{', '').replace('}', '');

                result[k] = cur.key;

                cur.forEach(function (snap): boolean {
                    //console.log(snap.key);
                    result[snap.key] = snap.val();
                    if (snap.child(snap.key).hasChildren()) {
                        recurse(snap, level + 1);
                    }
                    return false;
                })

            }
        }

        if (typeof data.val() == 'object') {

            recurse(data, 0);
        } else {
            const k = keys[0].replace('{', '').replace('}', '');

            if (keys.length > 1) {

                result[k] = data.key;
                const k2 = keys[1].replace('{', '').replace('}', '');
                result[k2] = data.val();

            } else {
                result[k] = data.val();
            }
        }

        return result;
    }

    static hashCode(str) { // java String#hashCode
        if (str) {
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 5) - hash);
            }
            return hash;
        } else {
            return 0;
        }
    }

    static hashCode8(str) { // java String#hashCode
        if (str) {
            var hash = 0;
            for (var i = 0; i < str.length; i++) {
                hash = str.charCodeAt(i) + ((hash << 7) - hash);
            }
            return hash;
        } else {
            return 0;
        }
    }

    static intToRGB(i) {
        var c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();

        return "00000".substring(0, 6 - c.length) + c;
    }

    static colorFromString(str: string): string {
        switch (str) {
            case "Reached": return 'gold'
            case "Unreached": return 'darkred'
            case "Called To Target": return 'green'

            default:
                return '#' + RrLibService.intToRGB(RrLibService.hashCode(str));
        }
    }

    static intToCode(i) {
        var c = (i & 0xFFFFFFFF)
            .toString(16)
            .toUpperCase();

        return "0000000".substring(0, 8 - c.length) + c;
    }

    static codeFromString(str : string) : string {
        return RrLibService.intToCode(RrLibService.hashCode8(str));
    }
}
