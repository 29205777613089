import {DatabaseSnapshot} from "@angular/fire/database-deprecated/interfaces";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {AuthService} from "./auth.service";

/**
 * Created by rio.bautista on 10/12/2017.
 */
export class Period {
    periodCode : string;
    description : string;
    start : Date;
    end : Date;

    constructor(snap : DatabaseSnapshot) {
        this.periodCode = snap.key;
        const v = snap.val();
        Object.keys(v).forEach(k => {
            this[k] = v[k];
        })
    }
}
