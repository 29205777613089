import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ChatComponent implements OnInit {

    currentChat : any[];
    chatData$ : AngularFireList<any[]>;

  constructor() { }

  ngOnInit() {
      this.currentChat = [
          {user: 'test', message: 'hello..'},
          { user: 'jeff', message: 'learning angular 4' },
          { user: 'bob', message: 'cool, angular is great' },
          { user: 'jeff', message: 'i know that\'s right' },
      ]
  }

    chat(formValue) {
        formValue.user = 'anonymous';

        this.currentChat.push(formValue);
    }
}
