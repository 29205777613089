import {Component, OnInit} from "@angular/core";
import {AuthService} from "app/shared/auth.service";
import { Observable, BehaviorSubject } from "rxjs";
import {Router} from "@angular/router";
import { UserInfo } from "app/shared/user-info";
import { ImFireService } from 'app/im-fire.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit{
    userInfo: Observable<UserInfo>;
    selectedTerritories : string[];
    isLoggedIn = new BehaviorSubject(false);

    constructor(private authService: AuthService, private router: Router, private fb : ImFireService ) {
        this.userInfo = authService.userInfo;
        this.userInfo.pipe(
            map(userInfo => !userInfo.isAnonymous)
        ).subscribe(this.isLoggedIn)
            

    }

    ngOnInit() {
        this.fb.selectedTerritories.subscribe(x => {
            this.selectedTerritories = x;
        });

        this.router.navigate(['./']);
    }


    navigateToLogin(e) {
        this.router.navigate(['/login']);
        e.preventDefault();
    }

    navigateToRegister(e) {
        this.router.navigate(['/register']);
        e.preventDefault();
    }
}
