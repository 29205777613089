import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { HttpModule } from "@angular/http";
import { AppComponent } from "./app.component";
import { firebaseConfig } from "environments/firebaseConfig";
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AuthService } from "./shared/auth.service";
import { LoginUserComponent } from "./login-user/login-user.component";
import { DisplayUserComponent } from "./display-user/display-user.component";
import { RegisterUserComponent } from "./register-user/register-user.component";
import { AlertModule } from "ngx-bootstrap";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { Routes, RouterModule } from "@angular/router";
import { HomePageComponent } from "./pages/home-page.component";
import { RegisterPageComponent } from "./pages/register-page.component";
import { AllInOnePageComponent } from "./pages/all-in-one-page.component";
import { LoginPageComponent } from "./pages/login-page.component";
import { LoggedInGuard } from "./shared/logged-in-guard";
import { DashboardPageComponent } from './pages/dashboard-page.component';
import { EformsComponent } from './eforms/eforms.component';
import { ChatComponent } from './chat/chat.component';
import { AppNavbarComponent } from './app-navbar/app-navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ImFireService } from './im-fire.service';
import * as firebase from 'firebase';
import {
    MatBadgeModule,
    MatCardModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatMenuModule,
    MatTabsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatSortModule,
    MatGridListModule,
    MatDialogModule,
    MatListModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatSidenavModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatSlideToggleModule
} from '@angular/material';
import { TreeviewModule } from 'ngx-treeview';
import { TerritoryPickerComponent } from './territory-picker/territory-picker.component';
import { WegotComponent } from './dash/wegot/wegot.component';
import { CallsToDateComponent } from './dash/wegot/calls-to-date.component';
import { CustomerReachComponent } from './dash/wegot/customer-reach.component';
import { CustomerFrequencyComponent } from './dash/wegot/customer-frequency.component';
import { NvD3Module } from 'ng2-nvd3';
import { ChartComponent } from './dash/chart/chart.component';
import { ChartDailyCalls } from './dash/chart/chart-daily-calls.component';
import { EventsService } from "./shared/events.service";
import 'd3';
import 'nvd3';
import { ChartCallRatePerFrequency } from "./dash/chart/chart-call-rate-per-frequency";
import { ToasterModule, ToasterService } from "angular2-toaster";
import { ReportComponent } from './reports/report/report.component';
import { ReportFieldPipe } from './reports/report/report-field.pipe';
import { Agrg8Pipe } from './reports/agrg8.pipe';
import { RrDataService } from "./shared/rr-data.service";
import { WidgetComponent } from './dash/widget/widget.component';
import { RrLibService } from "./rr-lib.service";
import { CustomersListComponent } from './customers/customers-list/customers-list.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EllipsisModule } from 'ngx-ellipsis';
import { ScrollEventModule } from 'ngx-scroll-event';
import { CardsComponent } from "./customers/cards/cards.component";
import { FileOverlayComponent } from "./customers/cards/file-overlay/file-overlay.component"
import { OverlayModule } from '@angular/cdk/overlay';
import { FullCalendarModule } from 'ng-fullcalendar';
import { RRCalendarComponent } from "./calendar/calendar.component";
import { CustomerKanbanBoardComponent } from './customers/customer-kanban-board/customer-kanban-board.component';
import { DatePipe } from '@angular/common';
import { UserProfileComponent } from './user-profile/user-profile.component'
import { TaggingVerificationComponent } from './reports/default/tagging-verification/tagging-verification.component';
import { TVDetailsComponent } from './reports/default/tagging-verification/t-v-details/t-v-details.component';
import { FilesComponent } from './files/files.component';
import { AngularFireStorage } from "@angular/fire/storage";
import { TagInputModule } from 'ngx-chips';
import { CustomerCardComponent } from './customers/customer-card/customer-card.component';
import { RrmapComponent } from './dash/rrmap/rrmap.component';
import { AgmCoreModule } from '@agm/core';
import { CustomerTmlComponent } from './customers/customer-tml/customer-tml.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SignatureComponent } from './reports/signature/signature.component';
import { CustomerMainComponent } from './customers/customer-main/customer-main.component';
import { PublicFeedComponent } from './public-feed/public-feed.component';
import { AdminComponent, AdminUsersComponent, EditUserComponent } from './admin/admin.component';
import { AdminGuard } from "./shared/admin-guard";
import { CallCardComponent } from './customers/cards/file-overlay/call-card/call-card.component';
import { BlankComponent } from './blank/blank.component';



const routes: Routes = [
    { path: 'register', component: RegisterPageComponent },
    { path: 'report/:render', component: ReportComponent },
    { path: 'report', component: ReportComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'login', component: LoginPageComponent },
    { path: 'dashboard', component: DashboardPageComponent, canActivate: [LoggedInGuard] },
    { path: 'eform/:render', component: EformsComponent},
    { path: 'admin', component: AdminComponent, canActivate: [AdminGuard] },
    { path: 'calendar', component: RRCalendarComponent},
    { path: 'customers', component: CustomerMainComponent},
    { path: 'public-feed', component: PublicFeedComponent},
    { path: 'user-profile', component: UserProfileComponent},
    { path: 'files', component: FilesComponent, canActivate: [LoggedInGuard] },
    { path: 'notifications', component: NotificationsComponent},
    { path: '', component: BlankComponent },
];

firebase.initializeApp(firebaseConfig);

@NgModule({
    declarations: [
        AppComponent,
        DisplayUserComponent,
        LoginUserComponent,
        RegisterUserComponent,
        ResetPasswordComponent,
        HomePageComponent,
        RegisterPageComponent,
        AllInOnePageComponent,
        LoginPageComponent,
        DashboardPageComponent,
        EformsComponent,
        ChatComponent,
        AppNavbarComponent,
        SidebarComponent,
        TerritoryPickerComponent,
        WegotComponent,
        CallsToDateComponent,
        CustomerReachComponent,
        CustomerFrequencyComponent,
        ChartDailyCalls,
        ChartComponent,
        ChartCallRatePerFrequency,
        ReportComponent,
        ReportFieldPipe,
        Agrg8Pipe,
        WidgetComponent,
        CustomersListComponent,
        CardsComponent,
        FileOverlayComponent,
        RRCalendarComponent,
        CustomerTmlComponent,
        CustomerKanbanBoardComponent,
        UserProfileComponent,
        TaggingVerificationComponent,
        TVDetailsComponent,
        FilesComponent,
        CustomerCardComponent,
        RrmapComponent,
        NotificationsComponent,
        SignatureComponent,
        CustomerMainComponent,
        PublicFeedComponent,
        AdminComponent,
        AdminUsersComponent,
        EditUserComponent,
        CallCardComponent,
        BlankComponent
        
    ],
    imports: [
        BrowserModule,
        FullCalendarModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatCardModule,
        MatIconModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatTabsModule,
        MatStepperModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSelectModule,
        MatTooltipModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatSortModule,
        MatTableModule,
        MatBadgeModule,
        HttpModule,
        MatButtonToggleModule,
        AlertModule.forRoot(),
        AngularFireModule.initializeApp(firebaseConfig, "IgniteManager"),
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        RouterModule.forRoot(routes),
        TreeviewModule.forRoot(),
        NvD3Module,
        ToasterModule,
        InfiniteScrollModule,
        MatDialogModule,
        MatGridListModule,
        MatExpansionModule,
        MatListModule,
        EllipsisModule,
        ScrollEventModule,
        TagInputModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDN4ftM4zZg2GjTOKXLO3nyoOnpjRtcNLk'
        }),
        HttpClientModule
    ],
    providers: [AngularFireStorage, AuthService, AdminGuard, LoggedInGuard, ImFireService, EventsService, ToasterService, RrDataService, RrLibService, DatePipe],
    bootstrap: [AppComponent],
    entryComponents: [
        FileOverlayComponent,
        EditUserComponent,
        TVDetailsComponent
    ],
})

export class AppModule {
}
