// TODO: fix the hovering position of the dot.  right now it doesn't appear on the right position
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {ImFireService} from "../../im-fire.service";
import {DatabaseSnapshot} from "@angular/fire/database/interfaces";
import { DatePipe } from '@angular/common';
import {ChartComponent} from './chart.component';
import {RrDataService} from "../../shared/rr-data.service";

declare let d3: any;


@Component({
    selector: 'chart-call-rate-per-frequency',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.css',
        '../../../../node_modules/nvd3/build/nv.d3.css'],
    encapsulation: ViewEncapsulation.None
})
export class ChartCallRatePerFrequency implements  OnInit {

    options;
    data;
    title = "Calls Per Frequency";

    constructor(protected fb:ImFireService, private rrd : RrDataService) {

    }

    private rawData = {};
    private selectedTerritories = [];

    private listener = {}

    ngOnInit() {

        this.options = {
            chart: {
                type: 'multiBarChart',
                height: 250,
                //interpolate: "basis" ,
                margin : {
                    top: 20,
                    right: 20,
                    bottom: 50,
                    left: 55
                },
                x: function(d){return d.label},
                y: function(d){return d.value;},
                showControl:false,
                showValues: false,
                showLegend: this.selectedTerritories.length < 11,
                valueFormat: function(d){
                    //return d3.format(',.4f')(d);
                    return d3.format(',.f')(d);
                },
                duration: 500,
                xAxis: {
                    axisLabel: 'Frequency',
                    tickFormat: function(d) {

                        return d + 'X';
                    }
                },
                yAxis: {
                    axisLabel: 'Call Count',
                    axisLabelDistance: -10
                }
            }
        }




        this.rrd.currentCycle.subscribe(cycle => {
            this.rawData = {};

            this.fb.userTerritories.subscribe(x => x.forEach(terr => {

                    //console.log(terr);

                    if (terr != 'none') {

                        if (this.listener[terr]) {
                            this.listener[terr].unsubscribe();
                        }

                        this.listener[terr] = this.fb.clientGetListRef('_call_counters/' + cycle + '/monthly_calls_per_freq/' + terr).snapshotChanges().subscribe(list => {

                            list.forEach(freqSnap => {

                                const freq:string = freqSnap.key;
                                const count:number = freqSnap.payload.val();

                                var freqData = this.rawData[freq] || new Object;
                                freqData[terr] = count;

                                this.rawData[freq] = freqData;

                            });

                            //console.log(this.rawData);

                            this.recount()

                        });


                    }
                }
            ));
        });

        this.fb.selectedTerritories.subscribe(x => {
            this.selectedTerritories = x;
            this.recount()
        });


    }

    recount() {

        this.data = [];

        //console.log(this.rawData);

        //console.log(this.selectedTerritories);

        this.options.chart.showLegend = this.selectedTerritories.length < 11;

        this.selectedTerritories.forEach( terr => {

            var callsPerFreq = [];
            //console.log("territory = " + terr);
            const freqs : string[] = Object.keys(this.rawData).sort(function(a,b){return a < b ? -1 : a > b ? 1 : 0}) ;

            freqs.forEach(freq => {

                callsPerFreq.push({ label: freq, value: this.rawData[freq][terr] || 0});

            });

            this.data.push({ values : callsPerFreq, key : terr});

        });

        //console.log(this.data);


    }

}
