import { Component, OnInit, ViewChild, ViewEncapsulation, Inject } from '@angular/core';
import { ReportComponent } from '../../../report/report.component';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ImFireService } from "../../../../im-fire.service";
import * as firDB from "firebase";
import { AngularFireList, DatabaseSnapshot, } from "@angular/fire/database/interfaces";
import { AngularFireObject } from "@angular/fire/database/interfaces";

import { Subject, Observable } from "rxjs";
// import { ReportFieldPipe } from "./report-field.pipe";
// import { Agrg8Pipe } from "../agrg8.pipe";
import { ChildEvent } from "@angular/fire/database/interfaces";
import { AuthService } from "../../../../shared/auth.service";
import { RrDataService } from "../../../../shared/rr-data.service";
import { Period } from "../../../../shared/period-definition";

import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserInfo } from 'app/shared/user-info';

import { DataSource } from '@angular/cdk/table';

import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-t-v-details',
  templateUrl: './t-v-details.component.html',
  styleUrls: ['./t-v-details.component.css']
})
export class TVDetailsComponent implements OnInit {

  constructor(private _formBuilder: FormBuilder,
    private fb: ImFireService,
    private rrd: RrDataService) { }

  rdk = [];
  rd = {};

  ngOnInit() {

    this.rrd.currentPeriod.subscribe(p => this.reportingCycle = p);

    this.fb.selectedTerritories.subscribe(territories => {
      this.rdk = [];
      this.rd = {};
      for (var i in territories) {
        var tdx = territories[i];
        this.getTerritoryDetail(tdx);
      }
    });

  }

  reportingCycle: Period;

  getTerritoryDetail(tdx) {
    if (this.reportingCycle) {
      this.fb.clientGetObject(`territories/${tdx}/${this.reportingCycle.periodCode}`)
        .subscribe(tdata => {
          if (tdata) {
            if (!this.rd[tdata.manager.name]) {
              this.rd[tdata.manager.name] = {};
            }
            this.rd[tdata.manager.name][tdata.name] = [];

            this.fb.clientGetObject(`_call_counters/${this.reportingCycle.periodCode}/daily_time_record/${tdx}`)
              .subscribe(dtrdata => {
                if (dtrdata) {
                  for (var callDate in dtrdata) {
                    this.fb.clientGetObject(`_call_counters/${this.reportingCycle.periodCode}/daily_calls/${tdx}/${callDate}`)
                      .subscribe(cdata => {
                        if (!cdata) {
                          cdata = 0;
                        }
                        this.rd[tdata.manager.name][tdata.name].push({
                          name: tdata.name,
                          manager: tdata.manager.name,
                          call_date: callDate,
                          timein: dtrdata[callDate].time_in,
                          timeout: dtrdata[callDate].time_out,
                          hours_on_field: dtrdata[callDate].hours_on_field,
                          call_count: cdata

                        });
                      }
                      );
                  }
                }
                console.log('Call Date: ' + callDate);
              });
            this.rdk = Object.keys(this.rd);
          }
        }
        );
    }
  }

  getKeys(json) {
    return Object.keys(json);
  }

  getKeys2(json2) {
    return Object.keys(json2);
  }
}

export interface Element {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}