import { Component, OnInit, ViewEncapsulation, EventEmitter } from '@angular/core';
import {AuthService} from "app/shared/auth.service";
import {ImFireService} from "app/im-fire.service";
import {Observable} from "rxjs";
import {RrDataService} from "../shared/rr-data.service";
import {ToasterModule, ToasterService, ToasterConfig} from 'angular2-toaster';
import {EventsService} from "../shared/events.service";
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserProfileComponent implements OnInit {
  
  territories : string[];
  userDetails : Observable<any>;
  profileImg : string;
  name:string;
  role:string;
  lastCheckin:string;
  territory:string;
  lat: number;
  profileImgFileName:string;
  lng: number;
  map:boolean=false;
  upload:boolean;
  panelOpenState:boolean=false;
  passwordUpdate:boolean;
  opFormControl = new FormControl('', [
    Validators.required,
  ]);
  npFormControl = new FormControl('', [
    Validators.required
  ]);
  cnpFormControl =  new FormControl('', [
    Validators.required
  ]);

  birthday;
  
  confirmPassError:boolean=false;
  currentPassError:boolean=false;
  
  
  
  constructor(
    private authService: AuthService, 
    private imFire : ImFireService,  
    toast : ToasterService, 
    private rrd : RrDataService) {
    
    
  }
  
  ngOnInit() {
     this.userDetails = this.imFire.clientGetObject('users/' + AuthService.userName);
    this.userDetails.subscribe(x => {
      
      if (x.profile_picture) {
        this.imFire.getProfileImageURL(x.profile_picture).then(y => this.profileImg = y);
        
      } else {
        this.profileImg = "images/profile.jpg";
      }
      this.name = x.name;
      this.role = x.role;
      this.birthday = x.birthday;
      if (x.last_location){
        this.lastCheckin = x.last_location.address;
      }
      
      this.territory = x.territory_id;
      this.profileImgFileName = x.profile_picture
      // this.lat = x.last_location.lat
      // this.lng = x.last_location.lon
    })
    
    
    this.imFire.profileImgUpload.subscribe(upload => {
      this.upload=upload
    })
    
    this.imFire.passwordUpdate.subscribe(p => {
      this.passwordUpdate = p
      if(!p){
        this.resetPasswordField()
      }
    })
    
  }
  
  birthDate(event: any){
    const birthdate = (new DatePipe('EN-US')).transform(event.target.value , 'MM/dd/yyyy');
    this.birthday = birthdate;
    console.log(birthdate)
  }
  changeProfileImg(event: any){
    const file: File = event.target.files[0]
    if (file){
      this.imFire.updateProfileImage(file,this.profileImgFileName,this.territory);
    }
  }
  updateUserDetails(){
    const userDetails = {name:this.name,role:this.role,birthday:this.birthday,
      last_location:this.lastCheckin}
      this.imFire.updateUserDetails(userDetails);
    }
    //requires reAuth
    updateUserPassword(){
      let path = AuthService.client + '/users/' + AuthService.userName ;
      let currentPass = this.opFormControl.value;
      let newPass = this.npFormControl.value;
      let confirmNewPass = this.cnpFormControl.value
      
      if (newPass != confirmNewPass){
        this.confirmPassError = true
      }else{
        this.confirmPassError = false
      }
      
      let checkPass=  this.imFire.readFirObject(path +  '/password')
      checkPass.valueChanges().subscribe(pass => {
        if(currentPass){
          if((currentPass === pass)){
            this.currentPassError= false;
          }
          else if((currentPass !== pass) && (this.opFormControl.value)){
            console.log('Invalid Old Password ')
            this.currentPassError = true;
          }
          if ((currentPass === pass) && (newPass === confirmNewPass)){
            this.imFire.updateUserPassword(confirmNewPass,currentPass)
            currentPass = null
          }
        } 
      })
    }
    
    resetPasswordField(){
      this.panelOpenState = false;
      this.opFormControl = new FormControl('', [Validators.required,  ]);
      this.cnpFormControl = new FormControl('', [Validators.required,  ]);
      this.npFormControl = new FormControl('', [Validators.required,  ]);
      this.currentPassError = false;
      this.confirmPassError = false;
    }
  }
  
  
  
  