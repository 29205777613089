import { Component, OnInit, Input } from '@angular/core';
import { CustomerData, LookupsData, GroupbySpec } from '../customer';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { RrDataService } from '../../shared/rr-data.service';
import { HostListener } from '@angular/core';
import { ScrollEvent } from 'ngx-scroll-event';
import { FileOverlayComponent } from '../cards/file-overlay/file-overlay.component';
import { RrLibService } from 'app/rr-lib.service';
import { Observable } from 'rxjs';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-customer-kanban-board',
  templateUrl: './customer-kanban-board.component.html',
  styleUrls: ['./customer-kanban-board.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerKanbanBoardComponent implements OnInit {


  @Input('CustomerList') customerList: Observable<CustomerData[]>;

  pageHeight:number = 600;
  pageBuffer:number = 10;


  selectedCustomer;
  editRow: string = "0";
  dataSource: MatTableDataSource<CustomerData> = new MatTableDataSource();
  customers: CustomerData[] = [];
  lookups: LookupsData[] = [];
  groups: GroupbySpec[] = [];
  groupedCustomers = [];
  loading: boolean = true;

  groupBy = 'specialty_description';
  static terrNames = {};

  constructor(private rrd: RrDataService, private cdr: ChangeDetectorRef) {
    rrd.allUsers.subscribe(uList => {
      
      for (const terr in uList.byTerr) {
        const user = uList.byTerr[terr];
        CustomerKanbanBoardComponent.terrNames[terr] = user['name'];
      }
      if (this.groupBy == 'territory') {
        this.setGroup('territory');
      }
    })
  }

  getColorFromString(str: string = "") {
    return RrLibService.colorFromString(str);
  }

  ngOnInit() {
    this.customerList.subscribe(customers => {
      this.customers = customers

      this.setGroup(this.groupBy)

    })
  }

  setGroup(group) {
    var groups = {};

    // and go over each customer 
    // debugger
    this.groupBy = group;
    const selectedGroup = group;
    this.customers.forEach(cust => {

      // put them in their respective group (this can now become a selectable variable)
      const groupVal = cust[this.groupBy];

      var group = groups[groupVal] || [];

      
      group.push(cust);
      groups[groupVal] = group;

      var aGroups = [];
      this.groupedCustomers = [];
      // convert the object into an array

      this.groups = [];
      // this.cdr.detach();
      for (var groupN in groups) {
        var groupDesc = groupN;
        if (selectedGroup == 'territory') {
          groupDesc = CustomerKanbanBoardComponent.terrNames[groupN] || groupN;
        }
        // collect the specialties or whatever grouping was used
        this.groupedCustomers.push(groupN);
        aGroups = aGroups.concat(groups[groupN]);

        this.groups.push({ spec_desc: groupDesc, customer: groups[groupN], pageEnd: Math.min(this.pageBuffer, groups[groupN].length) })
      
      }

      this.groups = this.groups.sort((a,b) => { return a.spec_desc > b.spec_desc ? 1 : a.spec_desc > b.spec_desc ? -1 : 0})
      
      // this.cdr.reattach();
      this.cdr.markForCheck();
      this.loading = false;
    })
  }

  

  onScroll( event, group : GroupbySpec)
  {
    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;
    const scrollPosition = scrollTop + offsetHeight;
    const scrollTreshold = scrollHeight - this.pageHeight;
    if( scrollPosition > scrollTreshold && group.pageEnd < group.customer.length){
      group.pageEnd = Math.min(group.pageEnd + this.pageBuffer, group.customer.length);
    }
  }

}



