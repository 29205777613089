import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';
import { ImFireService } from "app/im-fire.service";
import { DisplayUserComponent } from "app/display-user/display-user.component";
import { Observable, BehaviorSubject } from "rxjs";
import { RrDataService } from "../shared/rr-data.service";
import { debuglog } from 'util';

@Component({
    selector: 'app-territory-picker',
    templateUrl: './territory-picker.component.html',
    styleUrls: ['./territory-picker.component.css'],
    encapsulation: ViewEncapsulation.None
})

export class TerritoryPickerComponent implements OnInit {

    items: TreeviewItem[];
    values: string[];

    config = TreeviewConfig.create({
        hasAllCheckBox: true,
        hasFilter: false,
        hasCollapseExpand: false,
        decoupleChildFromParent: false,
        maxHeight: 400
    });

    constructor(private fb: ImFireService, private rrd: RrDataService) {

    }

    onSelectedChanged(event) {
        this.values = event;
        // console.log(this.items)
        this.fb.setSelectedTerritories(this.values);
        this.rrd.setSelectedTerritories(this.values);

        var dmIDs = []

        this.items.forEach(tree => {
            if(tree['internalChecked']){
                // console.log(tree.value)
                dmIDs.push(tree.value)
            }
        })

        this.rrd.setSelectedDistrict(dmIDs.join('||'))
    }

    territories = [];

    ngOnInit() {

        this.fb.selectedTerritories.subscribe(tl => {
            this.territories = tl;
            // console.log(tl)
        });

        // this.rrd.userData.subscribe(user => {
        //     if(user){
        //         this.curruser = user
        //         this.territories = this.curruser.territory_id.split("||")
        //     }
        // })

        this.rrd.currentCycle.subscribe(cycle => {
            // debugger
            this.rrd.territoryTree.subscribe(items => {

                while (items.length == 1 && (items[0].children != null)) {
                    items = items[0].children;
                }


                if (items.length > 0) {
                    this.items = items;

                    if (this.items[0].text == 'All') {
                        this.config.hasAllCheckBox = false;
                        this.items[0].collapsed = false;
                    }

                    this.items.forEach(d => {
                        if (d.children != null) {
                            d.children.forEach(t => {
                                const terrId = t.value;

                                this.fb.clientGetObject('territories/' + terrId + '/' + cycle).subscribe(tconf => {

                                    if (tconf == null) {
                                        t.disabled = false;
                                        t.checked = false;
                                        const i = this.territories.indexOf(terrId);
                                        if (i > -1) {
                                            // this.territories.splice(i, 1);

                                            // this.fb.setSelectedTerritories(this.territories);
                                            this.rrd.setSelectedTerritories(this.territories);
                                            // this.fb.setAllTerritories(this.territories);
                                        }

                                        const i2 = d.children.indexOf(t);
                                        if (i2 > -1) {
                                            // d.children.splice(i2, 1);
                                            // this.fb.setSelectedTerritories(this.territories);
                                            this.rrd.setSelectedTerritories(this.territories);
                                            // this.fb.setAllTerritories(this.territories);
                                        }
                                    }
                                })

                            })
                        }
                    })
                }

            })
        })

    }

}
