import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SignatureComponent } from '../../../../reports/signature/signature.component';

@Component({
  selector: 'app-call-card',
  templateUrl: './call-card.component.html',
  styleUrls: ['./call-card.component.css']
})
export class CallCardComponent implements OnInit {
  @Input('Call') call = new Array();

  @ViewChild('signatureCanvas') canvasRef;

  constructor() { }

  ngOnInit() {
  }

}
