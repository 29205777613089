import { Component, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';

@Component({
  selector: 'app-signature',
  // templateUrl: './signature.component.html',
  template: '<canvas #canvasEl width="200" height="100"></canvas>',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements AfterViewInit {
  /** Template reference to the canvas element */
  @ViewChild('canvasEl') canvasEl: ElementRef;    /** Canvas 2d context */
  private context: CanvasRenderingContext2D;
  constructor() { }

  @Input() sign: any;

  ngAfterViewInit() {
    this.context = (this.canvasEl.nativeElement as HTMLCanvasElement)
      .getContext('2d');
    // console.log("sign" + this.sign);

    this.draw();
  }  /**   * Draws something using the context we obtained earlier on   */

  private draw() {
    var sign = this.sign || "";
    if(sign.indexOf(',/') !== -1){
      var cord = sign.split(",");
      var maxx = Math.ceil(cord[0] / 2);
      var maxy = Math.ceil(cord[1] / 2);
      this.context.moveTo(maxx, maxy);
      
      for (var i = 0; i < cord.length; i += 2) {
        var p0 = Math.ceil(cord[i] / 2);
        var p1 = Math.ceil(cord[i + 1] / 2);
        var p2 = Math.ceil(cord[i + 2] / 2);
        var p3 = Math.ceil(cord[i + 3] / 2);
        if (cord[i] == "/") {

          this.context.moveTo(p2, p3);
          this.context.lineTo(p2, p3);
          this.context.stroke();
          maxx = Math.max(p2, maxx)
          maxy = Math.max(p3, maxy)
        } else {
          this.context.moveTo(p0, p1);
          this.context.lineTo(p2, p3);
          this.context.stroke();
          maxx = Math.max(p0, p2, maxx)
          maxy = Math.max(p1, p3, maxy)
        }
      }
      
    } else{ 
      var cord = sign.split("/");
      for (var i = 0; i < cord.length; i++) {
        var points = cord[i].split(",");
        var p0 = Math.ceil(points[0] / 2);
        var p1 = Math.ceil(points[1] / 2);
        var p2 = Math.ceil(points[2] / 2);
        var p3 = Math.ceil(points[3] / 2);
        this.context.moveTo(p0, p1);
        this.context.lineTo(p2, p3);
        this.context.stroke();
      }
    }
    
  }
}

