import { Component, OnInit, ViewEncapsulation, ViewChild, Input, Inject } from '@angular/core';
import { DatePipe } from '@angular/common'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RrDataService, UserData } from '../../../shared/rr-data.service';
import { EventsService } from '../../../shared/events.service';
import { CustomerData } from '../../customer';
import { CustomersListComponent } from '../../customers-list/customers-list.component';
import { FormControl, Validators } from '@angular/forms';
import { ImFireService } from '../../../im-fire.service';
import { AuthService } from '../../../shared/auth.service';
import { SignatureComponent } from '../../../reports/signature/signature.component';
import { FormGroup } from '@angular/forms';
import { userInfo } from 'os';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { RrLibService } from 'app/rr-lib.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-file-overlay',
  templateUrl: './file-overlay.component.html',
  styleUrls: ['./file-overlay.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class FileOverlayComponent implements OnInit {
  doctorDataChild = ['last_name', 'first_name', 'middle_name', 'class_code', 'frequency', 'specialty_description', 'clinic_address', 'lic_no', 'address_city', 'birth_date', 'gender'];
  callsRecord = ['md_id', 'cust_name', 'time', 'visit_date', 'location_name', 'product1', 'product2', 'planned', 'signature'];
  notesRecord = ['md_id', 'author_name', 'cust_name', 'note'];
  private md_id: any;
  private first_name: any;
  private last_name: any;
  private selectClassCode: any;
  private selectFrequency: any;
  private selectSpecialty_description: any;
  private birth_date: any;
  private key: any;
  private value: string;
  private clinic_address: any;
  private prc_number: any;
  private address_city: any;
  private middle_name: any;
  private userdata;
  private gender: any;
  private frequencyData = new Array();
  private class_codeData = new Array();
  private specialty_descriptionData = new Array();
  private profileImage: string;
  notes_all = [];
  lookups_data = new BehaviorSubject<String[]>(null);
  customer_fields = new Array();
  calls_data = [];
  notes_data = [];
  note_addition = "";
  calls_loading = true;
  notes_loading = true;

  @ViewChild('signatureCanvas') canvasRef;

  private userData: UserData;
  private allowedToAdd = false;

  constructor(
    public dialogRef: MatDialogRef<FileOverlayComponent>, private rrd: RrDataService, private fb: ImFireService, @Inject(MAT_DIALOG_DATA) public data: CustomerData, private datepipe: DatePipe) {
    // this.md_id = this.data.md_id;
    // this.last_name = this.data.last_name;
    // this.first_name = this.data.first_name;
    // this.selectSpecialty_description = this.data.specialty_description;
    // this.selectClassCode = this.data.class_code;
    // this.selectFrequency = this.data.frequency;
    // this.key = this.data.$key;
    // this.birth_date = this.data.birth_date;
    // this.middle_name = this.data.middle_name,
    //   this.clinic_address = this.data.clinic_address
    // this.prc_number = this.data.lic_no;
    // this.address_city = this.data.address_city;
    // this.gender = this.data.gender;


    this.rrd.userData.subscribe(u => {
      if (u) {
        this.userData = u;
        this.allowedToAdd = u.isAllowedTo('add_md');
      }
    })

    var terrListener : Subscription;
    this.rrd.currentCycle.subscribe(cycle => {
      
      // console.log(cycle)

      if (terrListener) {
        terrListener.unsubscribe()
      }

      terrListener = this.rrd.territoriesPerCustomer.subscribe(terrsPerCust => {
        // console.log(terrs)
        var ps = []
        var nps = []

        const terrs = terrsPerCust[data.md_id] || []
        terrs.forEach(terr => {
          // console.log(terr)

          const p = this.rrd.firClientRef(`calls/${terr}`).orderByKey().startAt(`${cycle}-01`).once('value', (daySnaps) => {
            daySnaps.forEach(daySnap => {
              if (daySnap.hasChild(data.md_id)) {
                var call = daySnap.child(data.md_id).val()
                call['territory_id'] = terr
                this.calls_data.push(call)
              }
              return false
            })
          })

          ps.push(p)

          const p2 = this.rrd.firClientRef(`notes/${terr}/${cycle}`).orderByChild('md_id').equalTo(data.md_id).once('value', (notesSnap) => {
            notesSnap.forEach(noteSnap => {
              
                var note = noteSnap.val()
                
                if (note[`author`] == this.userData.userid) {
                  note['bgColor'] = 'blue';
                } else {
                  note['bgColor'] = 'orange';
                }

                this.notes_data.push(note)
              
              return false
            })
          })
          nps.push(p2)

        })
        Promise.all(ps).then(() => {
          this.calls_loading = false
        })
        Promise.all(nps).then(() => {
          this.notes_loading = false
        })
      })
    })

  }


  ngOnInit() {
    this.setFields();
    // this.getCalls();
    this.profileImage = "images/profile.jpg";
    // this.loadNotes();
  }

  onCloseConfirm() {
    this.dialogRef.close('Confirm');
  }
  onCloseCancel() {
    this.dialogRef.close('Cancel');
  }


  clickSaveChanges(key: string) {
    // this.userdata = {
    //   last_name: this.last_name.toUpper(),
    //   first_name: this.first_name.toUpper(),
    //   middle_name: this.middle_name.toUpper(),
    //   class_code: this.selectClassCode,
    //   specialty_description: this.selectSpecialty_description,
    //   frequency: this.selectFrequency,
    //   clinic_address: this.clinic_address.toUpper(),
    //   gender: this.gender,
    //   address_city: this.address_city.toUpper(),
    //   birth_date: this.birth_date.toUpper()
    // };

    // console.log(this.data.territory);

    this.data.save();
    this.dialogRef.close('Saved');

    // this.rrd.updateCustomerData('doctor_clinic/TD', key, this.userdata, this.doctorDataChild);
    // this.rrd.updateCustomerData('doctor', key, this.userdata, this.doctorDataChild);
    // this.onCloseCancel();
    // var bodytoast = key + ' Details Updated';
    // EventsService.createToast('success', 'Customer Details', bodytoast);
  }

  requiredCity = new FormControl(this.address_city, [Validators.pattern("[a-zA-Z]*")]);
  doctorName = new FormControl(this.last_name, [Validators.pattern("[a-zA-Z]* ")]);
  addressClinic = new FormControl(this.clinic_address, [Validators.pattern("[#]* + [a-zA-Z.]*")]);
  enableForms = new FormControl(true);

  getErrorMessage() {
    return this.requiredCity.hasError('required') ? 'You must enter a value' :
      this.requiredCity.hasError('pattern') ? 'Invalid City Name' :
        this.doctorName.hasError('pattern') ? 'Invalid Name' :
          this.addressClinic.hasError('pattern') ? 'Invalid City Address' :
            '';
  }
  // public onDate(event): void {
  //   this.getDate.date = event;

  //   this.getData(this.getDate.date);
  // }

  public getData(data: string): void {
    this.birth_date = data;
  }

  getCalls() {
    // var counter = 0;
    // this.rrd.getUserCalls('calls', this.key).snapshotChanges().subscribe(items => {
    //   items.map(item => {
    //     if (item.payload.hasChild(this.key) && counter < 6) {
    //       this.calls_data[counter] = item.payload.child(this.key).val();
    //       counter = counter + 1;
    //     }
    //   })
    // });
    
    // this.rrd.calendarEvents.subscribe(calls => {
    //   if (calls) {
    //     this.calls_data = [];
    //     // console.log(calls);

    //     calls['calls'].forEach(call => {
    //       console.log(call['meta']['md_id']);
    //       if (this.data.md_id == call['meta']['md_id']) {
    //         this.calls_data.push(call);
    //       }
    //     })

    //     console.log(this.calls_data);
    //   }
    // })
  }

  loadNotes() {
    this.rrd.currentCycle.subscribe(cycle => {
      
      
      this.rrd.clientDataRead(`notes`).snapshotChanges().subscribe(terrs => {

        terrs.forEach(terr => {

          terr.payload.child(cycle).forEach(note => {

            //if(note.child(`md_id`).val() == this.data.md_id) {
            //console.log(note.val())

            var v = note.val()

            if (v[`author`] == this.userData.userid) {
              v['bgColor'] = 'white';
            } else {
              v['bgColor'] = 'yellow';
            }

            this.notes_all.push(v);
            //}
            this.getNotes();
            return false
          })
        })
      })
    });
  }

  getNotes() {
    this.notes_data = [];
    this.notes_all.forEach(note => {
      if (note[`md_id`] == this.data.md_id) {
        this.notes_data.push(note);
      }
    })

    // var terr = '';

    // // this.rrd.selectedTerritories.subscribe(u => {
    // //   console.log(u);
    // //   // terr = u;
    // //   // if (u.){

    // //   // }
    // // })

    // this.rrd.allTerritories.subscribe(terrs => {

    // })

    // this.rrd.currentCycle.subscribe(cycle => {
    //   //console.log(`notes/${this.userData.territory_id}/${cycle}`);
    //   //this.currentcycle = cycle;

    //   this.rrd.clientDataRead(`notes`).snapshotChanges().subscribe(terrs => {
    //     terrs.forEach(terr => {
    //       terr.payload.child(cycle).forEach(note => {

    //         if(note.child(`md_id`).val() == this.data.md_id) {
    //           console.log(note.val())

    //           var v = note.val()

    //           if(v[`author`] == this.userData.userid){
    //             v['bgColor'] = 'white';
    //           }else {
    //             v['bgColor'] = 'yellow';
    //           }

    //           this.notes_data.push(v);
    //         }

    //         return false
    //       })
    //     })
    //   })

    // this.rrd.clientDataRead(`notes/${this.data.territory}/${cycle}`).snapshotChanges().subscribe(list => {

    //   this.notes_data = [];

    //   list.forEach(u => {
    //     const v = u.payload.val();
    //     //terr.includes(v[`author`]) && 
    //     if(v[`md_id`] == this.data.md_id) {
    //       console.log(v[`md_id`]);

    //       if(v[`author`] == this.userData.userid){
    //         v['bgColor'] = 'white';
    //       }else {
    //         v['bgColor'] = 'yellow';
    //       }

    //       this.notes_data.push(v);

    //     }

    //   });

    // });

    //});
  }

  setFields() {
    this.rrd.customer_fields.subscribe(fields => {
      this.customer_fields = [];
      // console.log(fields);
      this.rrd.lookups_data.subscribe(lookups => {
        // console.log(lookups);
        var custFields = [];
        for (const fName in fields) {
          var field = fields[fName];

          var type = field['type'];

          if (field['type'] === undefined) {
            type = '';
          }

          // console.log(type);
          // console.log(type.includes('lookup'));
          if (type.includes('lookup')) {
            var str = type.split('||');
            field['lookup_data'] = lookups[str[1]];
            // console.log(lookups[str[1]])
            field['field_type'] = str[0];
          }
          else {
            field['field_type'] = type;
          }

          custFields.push(field);
        }
        // console.log(custFields);
        this.customer_fields = custFields;
      });

    });
  }

  addNote() {
    var date = this.datepipe.transform(Date(), 'yyyy-MM-dd HH:mm:ss Z')

    var first = ""
    var middle = ""
    var last = ""

    if (this.data.first_name) first = this.data.first_name;
    if (this.data.middle_name) middle = this.data.middle_name;
    if (this.data.last_name) last = this.data.last_name;

    var name = `${last} ${first}`

    if (this.note_addition != "") {
      if (confirm('Add note?')) {
        this.rrd.clientSetObject(`/notes/${this.userData.territory_id}/${this.data.cycle}/${date}/author`, this.userData.key)
        this.rrd.clientSetObject(`/notes/${this.userData.territory_id}/${this.data.cycle}/${date}/author_name`, this.userData.name)
        this.rrd.clientSetObject(`/notes/${this.userData.territory_id}/${this.data.cycle}/${date}/cust_name`, name)
        this.rrd.clientSetObject(`/notes/${this.userData.territory_id}/${this.data.cycle}/${date}/datetime`, date)
        this.rrd.clientSetObject(`/notes/${this.userData.territory_id}/${this.data.cycle}/${date}/md_id`, this.data.md_id)
        this.rrd.clientSetObject(`/notes/${this.userData.territory_id}/${this.data.cycle}/${date}/note`, this.note_addition)
        alert('Note Saved')
      }
    }

    // console.log(`notes/${this.userData.territory_id}/${this.data.cycle}/${date} : ${this.userData.key}`)
    // console.log(this.note_addition);
  }

  drawSignature() {
    let ctx: CanvasRenderingContext2D =
      this.canvasRef.nativeElement.getContext('2d');
  }
}