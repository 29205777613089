import { Router, ActivatedRoute, Params } from '@angular/router';
import { Component, OnInit, ViewChild, ViewEncapsulation, ApplicationRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { ImFireService } from "../../im-fire.service";
import * as firDB from "firebase";
import { AngularFireList, DatabaseSnapshot, AngularFireObject, ChildEvent } from "@angular/fire/database/interfaces";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { ReportFieldPipe } from "./report-field.pipe";
import { Agrg8Pipe } from "../agrg8.pipe";
import { AuthService } from "../../shared/auth.service";
import { RrDataService } from "../../shared/rr-data.service";
import { Period } from "../../shared/period-definition";
import { DatePipe } from '@angular/common';

import * as XLSX from 'xlsx';
import { ChangeDetectionStrategy } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

// TODO: work on the progress bar

export class ReportComponent implements OnInit {

    isLinear = true;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;

    reports: any[];
    startDate = new FormControl();
    endDate = new FormControl();
    Class = new FormControl();

    selectedReport = "";
    isReportLoading = false;
    selectedReportIndex: number = 0;

    Object = Object;

    hideParameters = false;

    getDate: any = new Date();
    getDate2: any = new Date();
    startAt: any;
    endAt: any;
    reportParameters: any;
    isFilteredbyDate = false;
    filterOptions = new FormArray([]);
    filterFields : any[];
    isFiltered = false;
    minDate = new Date();
    maxDate = new Date();
    subtotalOnly = false;

    cycles = [
        { value: '2017-11', viewValue: 'November, 2017' } //defaultvalue
    ];

    selectedCycle = '2017-11';

    constructor(private _formBuilder: FormBuilder,
        private fb: ImFireService,
        private activatedRoute: ActivatedRoute,
        private rrd: RrDataService,
        private appref: ApplicationRef,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngOnInit() {



        this.firstFormGroup = this._formBuilder.group({
            firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
            secondCtrl: ['', Validators.required]
        });

        this.activatedRoute.params.subscribe((params: Params) => {

            //console.log(params);

            this.reportParameters = params;

            this.selectedReport = params['render'];
            if (this.selectedReport > '') {
                this.hideParameters = true;


                this.fb.clientGetObject('config/reports/' + this.selectedReport).subscribe(r => {
                    if (r) {
                        r.ref = `${AuthService.client}/config/reports/${this.selectedReport}`;
                        this.reports = [r];
                        this.showReport(params);
                    }
                    else {
                        this.fb.readFirObject(`default/config/reports/${this.selectedReport}`)
                            .valueChanges().subscribe(r => {
                                r.ref = `default/config/reports/${this.selectedReport}`
                                this.reports = [r];
                                this.showReport(params);
                            })
                    }
                });

            } else {
                this.fb.clientGetList('config/reports').subscribe(r => {
                    this.reports = r;
                });
            }

        });

        this.rrd.currentPeriod.subscribe(p => this.reportingCycle = p);

    }

    exportToExcel(event) {
        //console.log('Reports ', this.reports);
        var table = document.getElementById("report");
        var wb = XLSX.utils.table_to_book(table);
        XLSX.writeFile(wb, this.selectedReport + '_' + this.reportingCycle.description + ".xlsx");
    }

    // public onDate(event): void {
    //     this.getDate.date = event;
    //     this.getData(this.getDate.date);
    // }

    // public getData(data: string): void {
    //     this.startDate = (new DatePipe('EN-US')).transform(data, 'yyyy-MM-dd');
    //     console.log('Start date: ' + this.startDate);
    // }


    // public onDate2(event): void {
    //     this.getDate2.date = event;
    //     this.getData2(this.getDate2.date);
    // }

    // public getData2(data: string): void {
    //     this.endDate = (new DatePipe('EN-US')).transform(data, 'yyyy-MM-dd');
    //     console.log('End date: ' + this.endDate);
    // }

    onReportSelected() {
        this.selectedReport = this.reports[this.selectedReportIndex].title;
    }

    columnTitles = [];
    listeners = [];
    reportData = {};
    groupings = [];
    selectedTerritories: string[];
    selectedTerritorieswithUserIDs: string[];
    reportLoadingProgress = 0;
    reportDef: any;

    reportLimit = 2;
    structuredReportData = {}
    reportTotals = {}
    reportingCycle: Period;

    private process_status = [];


    isObject(v: any) {
        return (typeof v == 'object') && (typeof v.forEach == 'undefined');
    }

    isArray(v: any): boolean {
        return (typeof v.forEach == 'function');
    }

    showTotal(col: string, level: string, item: string) {
        if (this.reportTotals[col]) {
            if (this.reportTotals[col][level + item]) {

                return this.reportTotals[col][level + item];
            }
        }
        return 0;
    }


    showHRef(ref: string, r: any) {
        ref = ref.replace('%terrId%', r['terrId']);
        const vars = ref.match(/%[a-z,_,A-Z]*%/g);

        // console.log('path:' + ref + ' vars:' + vars);

        var colVal = ref;
        if (vars != null) {
            vars.forEach(function (v: string) {

                const vk = v.replace(/%/g, '');
                const val = r[vk];
                if (val) {
                    colVal = colVal.replace(v, val);
                }

            });
        }
        return colVal;
    }

    showRouterLink(colVal: string, r: any) {

        if (colVal.indexOf('&') != -1) {
            const href = colVal.split('&')[0];
            const objs = colVal.split('&')[1].split(',');
            var obj = {};

            for (let o of objs) {
                obj[o] = r[o];
            }

            return [href, obj];
        } else {
            return [colVal];
        }

    }

    sortData(data: any[]) {

        const r = this.reportDef;

        if (r['order'] != null) {
            const order = r['order'].split('|')[0];
            const dir = r['order'].split('|')[1] || 'A';
            data = data.sort((a, b) => {
                if (dir == 'A') {
                    return a[order] > b[order] ? 1 : -1;
                }
                return a[order] < b[order] ? 1 : -1;
            })

        }

        return data;

    }

    isExcluded(data: any[], columns: any[]) {
        // console.log(data, columns)
        var excluded = false;
        columns.forEach(col => {
            if (col['exclude']) {
                const title = col['title']
                const keywords = col['exclude']
                const value = data[title.replace(/[ .]/g, '_')]
                //console.log(title.replace(/[ .]/g, '_'),col['exclude'], data[title.replace(/[ .]/g, '_')])
                if (keywords.indexOf(value) > -1 || value.indexOf("getData:") > -1) {
                    excluded = true;
                }

            }
            if (this.isFilteredbyDate) {
                if (col['title'] == "Date") {
                    const sd = new Date(this.startDate.value)
                    const ed = new Date(this.endDate.value)
                    const coldate = new Date(new Date(data['Date']).toDateString())

                    if (sd > coldate && this.startDate.value) {
                        excluded = true;
                    }
                    if (coldate > ed && this.endDate.value) {
                        excluded = true;
                    }
                }
            }
            if (this.isFiltered) {
                // console.log(this.filterOptions.at(0).value,this.filterOptions.at(1).value)
                var i = 0;
                this.filterFields.forEach(f => {
                    if(data[f['field']] != this.filterOptions.at(i).value && this.filterOptions.at(i).value != "ALL"){
                        excluded = true;
                    }
                    i++;
                })
            }
        })
        return excluded
    }

    onFilterChange() {
        console.log("Filter changed to "+this.filterOptions.at(0).value);
        this.showReport(this.reportParameters);
    }

    showReport(params: any) {

        this.isReportLoading = true;

        ImFireService.loadingInfo.subscribe(li => {
            this.reportLoadingProgress = li['progress'];
        })

        const reportFieldPipe = new ReportFieldPipe();

        const report = this.reports[this.selectedReportIndex];
        var g = [];
        var structData = this.structuredReportData;
        var computedColumns = [];
        var cdr = this.cdr
        var appref = this.appref

        if (report['filterbyDate']) {
            this.isFilteredbyDate = true;
        } else {
            this.isFilteredbyDate = false;
        }
        if (report['filters']) {
            this.isFiltered = true;
            this.filterFields = report['filters'];
            this.filterFields.forEach(f => {
                // this.filterOptions.addControl(f['field'], new FormControl(''))
                this.filterOptions.push(new FormControl('ALL'));
            })
        } else {
            this.isFiltered = false;
        }
        if (report['subTotalOnly']) {
            this.subtotalOnly = true;
        } else {
            this.subtotalOnly = false
        }
        // console.log(report)

        function recomputeRow(rowData): Promise<any> {

            return new Promise((resolve, reject) => {

                computedColumns.forEach(x => {
                    const colName = (x['title']).replace(/[ .]/g, '_');
                    const cpath = x.path;
                    const vars = cpath.match(/%[a-z,_,A-Z]*%/g);
                    const format = x.format;
                    // console.log('path:' + cpath + ' vars:' + vars);

                    var colVal = x.path;
                    vars.forEach(function (v: string) {


                        const vk = v.replace(/%/g, '');
                        const val = rowData[vk];
                        if (val || format == "number") {
                            colVal = colVal.replace(v, val);
                        }

                    });

                    const formula = eval(colVal.replace('compute:', '() => {return ') + '}');
                    rowData[colName] = formula();

                });
                cdr.markForCheck()
                resolve(rowData);
            })


        }

        function recurseGroups(target, level, t, rowData) {

            const colName = (t[level]['title']).replace(/[ .]/g, '_');

            const groupName = rowData[colName];

            // check if there's a next level
            if (level < g.length - 1) {
                if (target[groupName] == null) {
                    target[groupName] = {};
                }
                recurseGroups(target[groupName], level + 1, t, rowData);

            } else {

                if (target[groupName] == null) {
                    target[groupName] = [];
                }

                if (typeof target[groupName].push == 'function') {

                    recomputeRow(rowData).then(rdata => {
                        target[groupName].push(rdata)
                        appref.tick()
                    })

                }
            }

        }


        // console.log(report);

        this.rrd.currentCycle
            .subscribe(cycle => {
                const d = new Date(`${cycle}-01`);
                this.minDate = new Date(d.getFullYear(), d.getMonth(), 1)
                this.maxDate = new Date(d.getFullYear(), d.getMonth() + 1, 0)
                this.reportData = [];
                this.process_status = [];

                this.fb.readFirObject(report.ref).valueChanges()
                    .subscribe(r => {

                        //this.listeners.forEach(listener => {
                        //    listener.unsubscribe();
                        //});

                        this.listeners = [];
                        this.reportDef = r;
                        this.columnTitles = r.columns;
                        var numberOfGetDataColumns = 0;  // get the count so we know if the promises are complete already before pushing to the reportData
                        var exclude = [];

                        computedColumns = [];
                        this.columnTitles.forEach(x => {
                            if (x['path'].indexOf('getData') != -1) {
                                numberOfGetDataColumns += 1;
                            }
                            if (x['path'].indexOf('count:') != -1) {
                                numberOfGetDataColumns += 1;
                            }
                            if (x['path'].indexOf('compute:') != -1) {
                                computedColumns.push(x);
                            }
                            if (x['exclude']) {
                                exclude[x.title.replace(/[ .]/g, '_')] = x['exclude'];
                                console.log(x['exclude']);
                            }
                        })

                        this.groupings = r.grouping.split(',');
                        g = this.groupings;
                        const colTitles = this.columnTitles;

                        const showTerritoryReport = (territory): Promise<any> => {
                            return new Promise<any>((resolve, reject) => {

                                if (this.reportData[territory] == null) {

                                    const userid = this.selectedTerritorieswithUserIDs[territory] || "";
                                    //console.log(userid)
                                    var path = r.source.replace('%terrId%', territory).replace('%userId%', userid).replace('%cycle%', cycle);

                                    for (let p in params) {
                                        const val = params[p];
                                        path = path.replace(`%${p}%`, val);
                                    }
                                    // extract the keys used in the source 
                                    // e.g. {md_id}, {visit_date}
                                    const keys = path.match(/{[a-z,_,A-Z]*}/g);
                                    if (keys != null) {
                                        keys.forEach(k => path = path.replace(k, ''));
                                    }


                                    //const l = this.fb.clientGetListRef(path).valueChanges()
                                    //    .subscribe(source => {
                                    const l = firDB.database().ref(AuthService.client + '/' + path)
                                        .once('value', source => {

                                            var terrRows = [];

                                            const flatten = this.flatten;

                                            var rowExclude = false;

                                            const processRow = function (row) {
                                                var flatData = flatten(row, keys);
                                                flatData['terrId'] = territory;
                                                flatData['cycle'] = cycle;
                                                // console.log('ROW:', JSON.stringify(row))
                                                // console.log('OUTPUT', JSON.stringify(flatData));

                                                var rowData = {};

                                                var rowCnter = 0;

                                                colTitles.forEach(function (col) {

                                                    const cpath = col.path;
                                                    const vars = cpath.match(/%[a-z,_,A-Z]*%/g);

                                                    //console.log('path:' + cpath + ' vars:' + vars);

                                                    var colVal = col.path;

                                                    if(vars){
                                                        vars.forEach(function (v: string) {


                                                            const vk = v.replace(/%/g, '');
                                                            const val = flatData[vk];
    
                                                            colVal = colVal.replace(v, val);
    
                                                            //console.log(vk + '-' + val);
    
                                                        });
                                                    } else {
                                                        const vk = cpath.replace(/%/g, '');
                                                        const val = flatData[vk];
    
                                                        colVal = val;
                                                    } 
                                                    

                                                    //console.log(colVal);

                                                    if (colVal.indexOf('getData:') != -1) {
                                                        //console.log('before ' + colVal);

                                                        reportFieldPipe.transform(colVal, col['format'])
                                                            .then(x => {
                                                                //console.log('before: ' + rowData[col.title.replace(/[ .]/g, '_')] + ' - ' + x);
                                                                //colVal = x;

                                                                rowCnter += 1;

                                                                if (x) {

                                                                    rowData[col.title.replace(/[ .]/g, '_')] = x;
                                                                    if (rowCnter >= numberOfGetDataColumns) {
                                                                        recurseGroups(structData, 0, colTitles, rowData);
                                                                    }
                                                                    //console.log(structData);

                                                                }
                                                            });
                                                    }

                                                    if (colVal.indexOf('count:') != -1) {
                                                        //console.log('before ' + colVal);

                                                        reportFieldPipe.transform(colVal, col['format'])
                                                            .then(x => {

                                                                rowCnter += 1;

                                                                if (x) {

                                                                    rowData[col.title.replace(/[ .]/g, '_')] = x;
                                                                    if (rowCnter >= numberOfGetDataColumns) {
                                                                        recurseGroups(structData, 0, colTitles, rowData);
                                                                    }

                                                                }
                                                            });
                                                    }

                                                    rowData[col.title.replace(/[ .]/g, '_')] = colVal;

                                                    if (exclude[col.title.replace(/[ .]/g, '_')] == colVal) {
                                                        rowExclude = true;
                                                    }

                                                })

                                                if (numberOfGetDataColumns == 0) {
                                                    recurseGroups(structData, 0, colTitles, rowData);
                                                }

                                                //console.log(rowData);

                                                //data.push(rowData);
                                                if (!rowExclude) {
                                                    rowData['terrId'] = territory;
                                                    terrRows.push(rowData);
                                                }

                                                //this.reportData = data;
                                                //console.log(this.reportData);


                                                return false;

                                            }

                                            if (source.hasChildren()) {
                                                source.forEach(row => {
                                                    return processRow(row)
                                                });
                                            } else {
                                                processRow(source);
                                            }


                                            this.reportData[territory] = terrRows;
                                            this.cdr.markForCheck()
                                            // console.log(this);
                                            resolve(terrRows)

                                        });

                                    this.listeners.push(l);

                                } else {
                                    resolve(null)
                                }
                            })
                        }

                        const showUserIDReport = (userid): Promise<any> => {
                            return new Promise<any>((resolve, reject) => {

                                if (this.reportData[userid] == null) {
                                    var path = r.source.replace('%userId%', userid).replace('%cycle%', cycle);

                                    for (let p in params) {
                                        const val = params[p];
                                        path = path.replace(`%${p}%`, val);
                                    }
                                    // extract the keys used in the source 
                                    // e.g. {md_id}, {visit_date}
                                    const keys = path.match(/{[a-z,_,A-Z]*}/g);
                                    if (keys != null) {
                                        keys.forEach(k => path = path.replace(k, ''));
                                    }


                                    //const l = this.fb.clientGetListRef(path).valueChanges()
                                    //    .subscribe(source => {
                                    const l = firDB.database().ref(AuthService.client + '/' + path)
                                        .once('value', source => {

                                            var terrRows = [];

                                            const flatten = this.flatten;

                                            var rowExclude = false;

                                            const processRow = function (row) {
                                                var flatData = flatten(row, keys);
                                                flatData['cycle'] = cycle;
                                                flatData['userId'] = userid;
                                                //console.log('ROW:', JSON.stringify(row))
                                                //console.log('OUTPUT', JSON.stringify(flatData));

                                                var rowData = {};

                                                var rowCnter = 0;

                                                colTitles.forEach(function (col) {

                                                    const cpath = col.path;
                                                    const vars = cpath.match(/%[a-z,_,A-Z]*%/g);

                                                    //console.log('path:' + cpath + ' vars:' + vars);

                                                    var colVal = col.path;

                                                    vars.forEach(function (v: string) {


                                                        const vk = v.replace(/%/g, '');
                                                        const val = flatData[vk];

                                                        colVal = colVal.replace(v, val);

                                                        //console.log(vk + '-' + val);

                                                    });

                                                    //console.log(colVal);

                                                    if (colVal.indexOf('getData:') != -1) {
                                                        reportFieldPipe.transform(colVal, col['format'])
                                                            .then(x => {
                                                                //console.log('before: ' + rowData[col.title.replace(/[ .]/g, '_')] + ' - ' + x);
                                                                //colVal = x;

                                                                rowCnter += 1;

                                                                if (x) {

                                                                    rowData[col.title.replace(/[ .]/g, '_')] = x;
                                                                    if (rowCnter >= numberOfGetDataColumns) {
                                                                        recurseGroups(structData, 0, colTitles, rowData);
                                                                    }
                                                                    //console.log(structData);

                                                                }
                                                            });
                                                    }

                                                    if (colVal.indexOf('count:') != -1) {
                                                        //console.log('before ' + colVal);

                                                        reportFieldPipe.transform(colVal, col['format'])
                                                            .then(x => {

                                                                rowCnter += 1;

                                                                if (x) {

                                                                    rowData[col.title.replace(/[ .]/g, '_')] = x;
                                                                    if (rowCnter >= numberOfGetDataColumns) {
                                                                        recurseGroups(structData, 0, colTitles, rowData);
                                                                    }

                                                                }
                                                            });
                                                    }

                                                    rowData[col.title.replace(/[ .]/g, '_')] = colVal;

                                                    if (exclude[col.title.replace(/[ .]/g, '_')] == colVal) {
                                                        rowExclude = true;
                                                    }

                                                })

                                                if (numberOfGetDataColumns == 0) {
                                                    recurseGroups(structData, 0, colTitles, rowData);
                                                }

                                                //console.log(rowData);

                                                //data.push(rowData);
                                                if (!rowExclude) {
                                                    rowData['userId'] = userid;
                                                    terrRows.push(rowData);
                                                }

                                                //this.reportData = data;
                                                //console.log(this.reportData);


                                                return false;

                                            }

                                            if (source.hasChildren()) {
                                                source.forEach(row => {
                                                    return processRow(row)
                                                });
                                            } else {
                                                processRow(source);
                                            }


                                            this.reportData[userid] = terrRows;
                                            this.cdr.markForCheck()
                                            // console.log(this.reportData);
                                            resolve(terrRows)

                                        });

                                    this.listeners.push(l);

                                } else {
                                    resolve(null)
                                }
                            })
                        }

                        /**
                         * displayUserLevel determines which users to display. Either district managers or medreps.
                         */
                        if (this.reports[0]['displayUserLevel'] == 'district') {
                            this.rrd.selectedDistrictsUserIDs.subscribe(dists => {
                                // debugger
                                // console.log(dists)
                                const users = dists.split('||')
                                this.reportLimit = 10;
                                this.reportData = {};
                                this.structuredReportData = {};
                                structData = this.structuredReportData;
                                Agrg8Pipe.reset();
                                Agrg8Pipe.totalsSubscriptions.subscribe(totals => {
                                    //console.log(totals);
                                    this.reportTotals = totals;
                                })
                                users.forEach(user => {
                                    let stat = { territory: user, status: 'Processing...' }
                                    this.process_status.push(stat)
                                    this.cdr.markForCheck()

                                    showUserIDReport(user).then(terrRows => {
                                        let i = this.process_status.indexOf(stat)
                                        this.process_status.splice(i, 1)
                                        this.cdr.markForCheck()
                                    })
                                })
                            })
                        }
                        else if (this.reports[0]['noTerritoryId']){
                            this.rrd.selectedTerritoriesUserIDs.subscribe(userstring => {
                                //console.log(user)
                                
                                const user = userstring.split('||')

                                this.reportLimit = 10;

                                var data = [];

                                this.selectedTerritories = user;
                                console.log(user)

                                var target = 0.0;
                                var done = 0.0;

                                this.reportData = {};
                                this.structuredReportData = {};
                                structData = this.structuredReportData;
                                Agrg8Pipe.reset();
                                Agrg8Pipe.totalsSubscriptions.subscribe(totals => {
                                    //console.log(totals);
                                    this.reportTotals = totals;
                                })


                                // console.log('choosing territories:' + territories)
                                // console.log(params)
                                if (params['terrId'] != null) {

                                    showTerritoryReport(params['terrId']).then(terrRows => {
                                        //console.log(terrRows)
                                        this.cdr.markForCheck()
                                    })

                                } else {

                                    this.process_status = [];
                                    user.forEach(user => {
                                        let stat = { territory: user, status: 'Processing...' }
                                        this.process_status.push(stat)
                                        this.cdr.markForCheck()

                                        showUserIDReport(user).then(terrRows => {
                                            let i = this.process_status.indexOf(stat)
                                            this.process_status.splice(i, 1)
                                            this.cdr.markForCheck()
                                        })
                                    })

                                }
                            });
                        }
                        else {
                            this.rrd.selectedTerritories.subscribe(territories => {

                                this.rrd.selectedTerritorieswithUserIDs.subscribe(users => {
                                    this.selectedTerritorieswithUserIDs = users;
                                })

                                this.reportLimit = 10;

                                var data = [];

                                this.selectedTerritories = territories;
                                console.log(territories)

                                var target = 0.0;
                                var done = 0.0;

                                this.reportData = {};
                                this.structuredReportData = {};
                                structData = this.structuredReportData;
                                Agrg8Pipe.reset();
                                Agrg8Pipe.totalsSubscriptions.subscribe(totals => {
                                    //console.log(totals);
                                    this.reportTotals = totals;
                                })


                                // console.log('choosing territories:' + territories)
                                // console.log(params)
                                if (params['terrId'] != null) {

                                    showTerritoryReport(params['terrId']).then(terrRows => {
                                        //console.log(terrRows)
                                        this.cdr.markForCheck()
                                    })

                                } else {

                                    this.process_status = [];
                                    territories.forEach(territory => {

                                        let stat = { territory: territory, status: 'Processing...' }
                                        this.process_status.push(stat)
                                        this.cdr.markForCheck()

                                        showTerritoryReport(territory).then(terrRows => {
                                            let i = this.process_status.indexOf(stat)
                                            this.process_status.splice(i, 1)
                                            this.cdr.markForCheck()
                                        })

                                    });

                                }

                            });
                        }




                    });
            });
    }


    flatten(data: DatabaseSnapshot<true>, keys: string[]) {

        var result = {};
        // console.log(keys);
        function recurse(cur: DatabaseSnapshot<true>, level) {

            if (level < keys.length) {

                const k = keys[level].replace('{', '').replace('}', '');

                result[k] = cur.key;

                cur.forEach(function (snap): boolean {
                    // console.log(snap.key);
                    result[snap.key] = snap.val();
                    if (snap.child(snap.key).hasChildren()) {
                        recurse(snap, level + 1);
                    }
                    return false;
                })

            }
        }

        if (typeof data.val() == 'object') {
            recurse(data, 0);
        } else {
            const k = keys[0].replace('{', '').replace('}', '');
            const key = `${k}_key`

            result[k] = data.val();
            result[key] = data.key;
        }

        return result;
    }

    changedateparameters(event: any) {
        // this.showReport(this.reportParameters)
    }
}

