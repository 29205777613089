import { Injectable } from '@angular/core';
import { ImFireService } from "../im-fire.service";
import { AuthService } from "./auth.service";
import { UserInfo } from "./user-info";
import { Period } from "./period-definition";
import { Observable, BehaviorSubject, Subscription } from "rxjs";
import { AngularFireDatabase, AngularFireList, AngularFireObject } from "@angular/fire/database";
import { RrLibService } from "../rr-lib.service";
import { DatabaseSnapshot } from "@angular/fire/database-deprecated/interfaces";
import { TreeviewItem } from "ngx-treeview/src/index";
import * as firebase from 'firebase';
// import {ObjectBuilder} from "../../../../functions/node_modules/firebase-functions/lib/providers/storage";
import { CustomerData } from '../customers/customer';
// import { setTimeout, clearTimeout } from 'timers';
import { promise, Alert } from 'selenium-webdriver';
import { userInfo } from 'os';

@Injectable()
export class RrDataService {

    static terrEvents = {};
    private _observers = {};

    private _config = new BehaviorSubject<DatabaseSnapshot>(null);
    public clientConfig = this._config.asObservable();

    private _allUsers = new BehaviorSubject<UserList>(null);
    public allUsers = this._allUsers.asObservable();

    public userData = new BehaviorSubject<UserData>(null);

    private userInfo: UserInfo;
    private _periodDefinitionList = new BehaviorSubject<Period[]>(null);
    public periodDefinitionList = this._periodDefinitionList.asObservable();

    private _currentCycle = new BehaviorSubject<string>(RrLibService.dateToLongString(new Date).substr(0, 7));
    public currentCycle = this._currentCycle.asObservable();

    private _currentPeriod = new BehaviorSubject<Period>(null);
    public currentPeriod = this._currentPeriod.asObservable();

    private _reportList = new BehaviorSubject<Report[]>(null);
    public reportList = this._reportList.asObservable();

    private _selectedTerritories = new BehaviorSubject<string[]>([])
    public selectedTerritories = this._selectedTerritories.asObservable();
    public territoryUsers = new BehaviorSubject<string[]>([])

    private _selectedTerritorieswithUserIDs = new BehaviorSubject<string[]>([])
    public selectedTerritorieswithUserIDs = this._selectedTerritorieswithUserIDs.asObservable();

    private _widgets = new BehaviorSubject<Widget[]>(null);
    public widgetList = this._widgets.asObservable();

    private _chartList = new BehaviorSubject<DashChart[]>(null);
    public chartList = this._chartList.asObservable();

    private _notifications = new BehaviorSubject<IGNotif>(null);
    public notificationsList = this._notifications.asObservable();

    private _calendarEvents = new BehaviorSubject<any>(null)
    public calendarEvents = this._calendarEvents.asObservable();

    private _calendarLoadingProgress = new BehaviorSubject<number>(0)
    public calendarLoadingProgress = this._calendarLoadingProgress.asObservable();

    private _hashtags = new BehaviorSubject<Hashtag[]>([])
    public hashtags = this._hashtags.asObservable()

    private _customerList = new BehaviorSubject<CustomerData[]>([]);
    public customerList = this._customerList.asObservable();

    private _masterList = new BehaviorSubject<CustomerData[]>([]);
    public masterList = this._masterList.asObservable();

    private _all_territories = new BehaviorSubject<string[]>([]);
    public allTerritories = this._all_territories.asObservable();

    private _territoryTree = new BehaviorSubject<TreeviewItem[]>([]);
    public territoryTree = this._territoryTree.asObservable();

    private _mapMarkers = new BehaviorSubject<any>(null);
    public mapMarkers = this._mapMarkers.asObservable();

    private _call_plans = new BehaviorSubject<string[]>([]);
    public call_plans = this._call_plans.asObservable();

    private __public_feed: PublicFeed[] = [];
    private _last_public_feed = new BehaviorSubject<PublicFeed>(null);
    private _public_feed = new BehaviorSubject<PublicFeed[]>([]);
    public public_feed = this._public_feed.asObservable();
    public last_public_feed = this._last_public_feed.asObservable();

    private _selectedTerritoriesUserIDs = new BehaviorSubject<string>('');
    public selectedTerritoriesUserIDs = this._selectedTerritoriesUserIDs.asObservable();

    public districtUserIDs = new BehaviorSubject<string>('');

    private _selectedDistrictsUserIDs = new BehaviorSubject<string>('');
    public selectedDistrictsUserIDs = this._selectedDistrictsUserIDs.asObservable();

    private _customer_fields = new BehaviorSubject<any>({});
    public customer_fields = this._customer_fields.asObservable();

    private _user_fields = new BehaviorSubject<any>({});
    public user_fields = this._user_fields.asObservable();

    private _lookups_data = new BehaviorSubject<any>({});
    public lookups_data = this._lookups_data.asObservable();

    private _hidden_navbar_btns = new BehaviorSubject<string>('');
    public hidden_navbar_btns = this._hidden_navbar_btns.asObservable();

    private _eformsList = new BehaviorSubject<Eform[]>(null);
    public eformsList = this._eformsList.asObservable();

    public firbaseRootRef: firebase.database.Reference;

    public territoriesPerCustomer = new BehaviorSubject<any>({});

    private listeners: { [name: string]: Subscription[] } = {}

    private defaultRlist = {};
    private clientRlist = {};

    constructor(private imFir: ImFireService, private afb: AngularFireDatabase, private auth: AuthService) {

        imFir.readFirObject('/default/config/reports').snapshotChanges().subscribe(snap => {
            this.defaultRlist = snap.payload.val();
        });

        auth.currUser.subscribe(uInfo => {
            this.loadCustomerViewFields();

            if (uInfo.isAnonymous == false) {
                this.userInfo = uInfo;

                this.__public_feed = [];

                const dateToday = RrLibService.dateToday();

                var feedTimer: NodeJS.Timer;

                // PUBLIC FEEDS
                this.territoryUsers.subscribe(userIDs => {

                    if (userIDs.length > 0) {

                        (this.listeners['notificationsPerUser'] || []).forEach(s => s.unsubscribe())
                        var notifListeners = [];

                        userIDs.forEach(userID => {
                            const s = this.clientDataRead(`public_feed_per_user/${userID}/${dateToday}`).snapshotChanges().subscribe(data => {
                                data.forEach(row => {

                                    const feed = row.payload

                                    const publicFeed = new PublicFeed(feed, imFir);

                                    this._last_public_feed.next(publicFeed);

                                    if (feedTimer != null) {
                                        clearTimeout(feedTimer)
                                    }

                                    this.__public_feed.push(publicFeed);

                                    feedTimer = setTimeout(() => {
                                        this._public_feed.next(this.__public_feed)
                                        // console.log('public feed:', publicFeed );
                                    }, 500);
                                })
                            })

                            notifListeners.push(s);
                        })

                        this.listeners['notificationsPerUser'] = notifListeners

                    }

                })


                this.loadLookups();

                const subs = this.clientDataRead('/users').snapshotChanges().subscribe(userList => {

                    // debugger
                    if (userList) {
                        var rval: UserList = { byUser: {}, byTerr: {} }

                        userList.forEach(u => {

                            if (this.firbaseRootRef == null) {
                                this.firbaseRootRef = u.payload.ref.root;
                            }

                            const v = u.payload.val()
                            if (u.key == AuthService.userName) {
                                this.userData.next(new UserData(u.payload, this));
                            }

                            v['userid'] = u.key;
                            v['color'] = RrLibService.colorFromString(v['name']);
                            if (v['profile_picture']) {
                                v['profileURL'] = this.getDownloadURL(v['profile_picture']);
                            }
                            rval.byUser[u.key] = v
                            rval.byTerr[v['territory_id']] = v

                            this.clientDataRead(`/daily_time_record/${u.key}/${dateToday}`)
                                .snapshotChanges().subscribe(dtr => {
                                    const firstTimeIn = dtr.find(dr => {
                                        const d = dr.payload.val()
                                        return parseFloat(d['lat']) * parseFloat(d['lon']) > 0 || d['actualLocation'] != null
                                    })
                                    if (firstTimeIn != null) {
                                        const timeIn = firstTimeIn.key;
                                        var dtrData = firstTimeIn.payload.val();
                                        dtrData['time_in'] = timeIn;

                                        v['dtr'] = dtrData;
                                    }
                                })
                        })
                        this._allUsers.next(rval)

                        subs.unsubscribe()
                    }
                })

                this.clientDataRead('/hashtags').snapshotChanges().subscribe(
                    list => {
                        var nHList = [];
                        list.forEach(snap => {

                            const nTag = new Hashtag(snap.payload);

                            nHList.push(nTag);

                        })
                        this._hashtags.next(nHList);
                    }
                );

                var _calendarEvents = this._calendarEvents
                var oFEvents = [];

                var terrMasters = {};
                var terrCustomers = {};

                var _terrMasters = new BehaviorSubject<any>({});
                var mastersPerTerr = _terrMasters.asObservable();

                var _terrCustomers = new BehaviorSubject<any>({});
                var customersPerTerr = _terrCustomers.asObservable();

                var terrMapActivities = {};
                var _terrMapActivities = new BehaviorSubject<any>({})
                var mapMarkersPerTerr = _terrMapActivities.asObservable();

                var terrPlans = {};
                var _terrPlans = new BehaviorSubject<any>({});
                var plansPerTerr = _terrPlans.asObservable();


                this.currentPeriod.subscribe(p => {
                    if (p != null) {

                        // Populate Territory Tree
                        this.imFir.userTerritories.subscribe(allTerritories => {

                            this.allUsers.subscribe(userList => {
                                if (userList) {
                                    var result = [];
                                    var districts = {}; 
                                    var managerIDs = [];
                                    var districtUserIDs = [];
                                    var territoryUsers = [];

                                    allTerritories.forEach(terr => {
                                        var district = 'All'
                                        managerIDs['All'] = 'All'
                                        if (userList.byTerr[terr] != null) {
                                            const managerID = userList.byTerr[terr]['manager'];
                                            const terrUserID = userList.byTerr[terr]['userid'];
                                            territoryUsers.push(terrUserID);

                                            if (managerID && districtUserIDs.indexOf(managerID) == -1) {
                                                districtUserIDs.push(managerID)
                                            }
                                            district = (userList.byUser[managerID] || {})['name'] || 'All'
                                            managerIDs[district] = managerID
                                        }

                                        const tName = userList.byTerr[terr] == null ? `${terr}-Vacant` : `${userList.byTerr[terr]['name']}`;

                                        let tvItem = new TreeviewItem({ text: tName, value: terr });
                                        if (!districts[district]) {
                                            districts[district] = [];
                                        }
                                        districts[district].push(tvItem);
                                    })

                                    this.territoryUsers.next(territoryUsers);

                                    this.districtUserIDs.next(districtUserIDs.join('||'));

                                    for (let d in districts) {
                                        result.push(new TreeviewItem(
                                            {
                                                text: d,
                                                value: managerIDs[d],
                                                collapsed: true,
                                                children: districts[d]
                                            }
                                        ));
                                    }

                                    this.updateTerritoryTree(result);


                                    // unsubscribe to existing customer observers
                                    var custObservers: Subscription[] = this._observers[`custObservers`];
                                    if (custObservers != null) {
                                        custObservers.forEach(subs => {
                                            subs.unsubscribe
                                            console
                                        });
                                    }
                                    custObservers = [];

                                    var mapObservers: Subscription[] = this._observers[`mapObservers`];
                                    if (mapObservers != null) {
                                        mapObservers.forEach(subs => subs.unsubscribe);
                                    }
                                    mapObservers = [];

                                    var territoriesPerCustomers = {}

                                    allTerritories.forEach(terr => {

                                        const terrUser = userList.byTerr[terr]
                                        // console.log(terr)
                                        // console.log(terrUser);

                                        // read all the customers per territory only once
                                        // then do the filtering below when the selected territories are changed

                                        custObservers.push(this.clientDataRead(`/doctor_clinic/${terr}`).snapshotChanges().subscribe(custList => {
                                            terrCustomers[terr] = [];
                                            // console.log(custList)
                                            for (const data of custList) {
                                                const custVal = data.payload.val()
                                                const mdid = data.payload.key

                                                var mdTerrs = territoriesPerCustomers[mdid] || []

                                                if ((custVal['last_name'] + custVal['first_name']) > "") {
                                                    const customer = new CustomerData(data.payload, this);
                                                    if (!customer[`territory_id`]) {
                                                        customer[`territory_id`] = terr
                                                    }
                                                    if (customer.territory.indexOf(`||`) > -1) {
                                                        customer.territory = terr
                                                    }
                                                    //console.log(customer.territory);
                                                    //console.log(customer[`territory_id`]);
                                                    terrCustomers[terr].push(customer)
                                                    if (mdTerrs.indexOf(terr) == -1) {
                                                        mdTerrs.push(terr)
                                                    }
                                                }

                                                territoriesPerCustomers[mdid] = mdTerrs
                                            }

                                            _terrCustomers.next(terrCustomers)
                                        }));

                                        custObservers.push(this.clientDataRead(`/doctor_clinic_master/${terr}`).snapshotChanges().subscribe(custList => {

                                            terrMasters[terr] = [];

                                            for (const data of custList) {
                                                const custVal = data.payload.val()
                                                if ((custVal['last_name'] + custVal['first_name']) > "") {
                                                    const customer = new CustomerData(data.payload, this);
                                                    terrMasters[terr].push(customer)
                                                }
                                            }

                                            _terrMasters.next(terrMasters)
                                        }));



                                        // subscribe to each territory's calls for today
                                        // todo: include time-ins and other mapable activities



                                        mapObservers.push(this.clientDataRead(`/calls/${terr}/${dateToday}`,
                                            ref => ref.orderByChild('visit_date')
                                        ).snapshotChanges().subscribe(calls => {
                                            terrMapActivities[terr] = [];

                                            for (const call of calls) {
                                                var v = call.payload.val()
                                                v['terrId'] = terr;
                                                if (terrUser != null) {
                                                    v['color'] = terrUser['color'];
                                                    v['psrName'] = terrUser['name'];
                                                    v['picture'] = terrUser['profile_pic'] || 'profile.jpg'
                                                }


                                                terrMapActivities[terr].push(v)
                                            }

                                            _terrMapActivities.next(terrMapActivities)
                                        }))
                                    })

                                    this.territoriesPerCustomer.next(territoriesPerCustomers)
                                }

                            })
                        })



                        this.selectedTerritories.subscribe(terrs => {
                            // debugger
                            const selectedTerritoriesUserIDs = [];
                            const selectedTerritorieswithUserIDs = [];
                            const selectedDistrictsUserIDs = []
                            this.allUsers.subscribe(uList => {

                                for (const terr of terrs) {
                                    const user = uList.byTerr[terr];
                                    if (user != null) {
                                        selectedTerritoriesUserIDs.push(user['userid']);
                                        selectedTerritorieswithUserIDs[terr] = user['userid'];
                                        selectedDistrictsUserIDs.push(user['manager']);
                                    }
                                }
                                this._selectedTerritorieswithUserIDs.next(selectedTerritorieswithUserIDs);
                                this._selectedTerritoriesUserIDs.next(selectedTerritoriesUserIDs.join('||'));
                                this._selectedDistrictsUserIDs.next(selectedDistrictsUserIDs.join('||'));
                            })

                            // we placed this here so we don't read the whole list again but only filter the ones read already using the selectedTerritories
                            customersPerTerr.subscribe(custPerTerr => {

                                var allCust = []

                                terrs.forEach(terrId => {
                                    const terrList = custPerTerr[terrId];
                                    if (terrList != null) {
                                        // console.log(allCust);
                                        // console.log(terrList);
                                        allCust = allCust.concat(terrList);
                                    }
                                    //console.log(allCust);
                                })

                                this._customerList.next(allCust);

                            })

                            mastersPerTerr.subscribe(mastPerTerr => {

                                var allMaster = []

                                terrs.forEach(terrId => {
                                    const terrList = mastPerTerr[terrId];
                                    if (terrList != null) {
                                        // console.log(allCust);
                                        // console.log(terrList);
                                        allMaster = allMaster.concat(terrList);
                                    }
                                    //console.log(allCust);
                                })

                                this._masterList.next(allMaster);
                            })

                            plansPerTerr.subscribe(planPerTerr => {
                                var allPlans = [];

                                terrs.forEach(terrId => {
                                    const terrList = plansPerTerr[terrId];
                                    if (terrList != null) {
                                        allPlans = allPlans.concat(terrList);
                                    }
                                })
                                this._call_plans.next(allPlans);
                            })

                            var latestCall = { visit_date: '' }

                            mapMarkersPerTerr.subscribe(mapActPerTerr => {
                                // todo: include time-in and other mapable activities
                                var markers = []
                                terrs.forEach(terrId => {
                                    var terrActs: any[] = mapActPerTerr[terrId]

                                    if (terrActs != null) {

                                        // make sure our calls are sorted by time so we can make accurate groupings of locations
                                        const sortedCalls = terrActs.sort((a, b) => { return a['visit_date'] > b['visit_date'] ? 1 : -1 })

                                        // group per location
                                        // for now let's base the first location on the first call with available location
                                        // todo:  later we will also consider locations from other activities as basis of first location

                                        var firstCallWithLocation = sortedCalls.find(x => { return x['location'] || x['location_raw'] != null })

                                        if (firstCallWithLocation == null) {
                                            firstCallWithLocation = sortedCalls.find(x => { return x['location_raw'] != null })
                                            if (firstCallWithLocation != null) {
                                                const location: string = firstCallWithLocation['location_raw'].split('>')[0].replace(/[<+]/g, '').replace(',', '||')
                                                firstCallWithLocation['location'] = location
                                            }
                                        }

                                        var lastLoc = firstCallWithLocation != null ? firstCallWithLocation['location'] || firstCallWithLocation['location_raw'].split('>')[0].replace(/[<+]/g, '').replace(',', '||') : '';
                                        var terrLocs = []
                                        var markerCalls = []
                                        var terrUser: any;
                                        var pointLbl = 'A';
                                        for (const call of sortedCalls) {

                                            if (terrUser == null) {
                                                terrUser = { name: call['psrName'] || '', picture: call['picture'] || 'profile.jpg', color: call['color'].replace('#', '') }
                                            }

                                            // if call doesn't have a location, we'll assume it's the same the last location
                                            const nLocation = call['location'] || (call['location_raw'] != null ? call['location_raw'].split('>')[0].replace(/[<+]/g, '').replace(',', '||') : lastLoc) || lastLoc

                                            const lat = parseFloat(lastLoc.split('||')[0]);
                                            const lon = parseFloat(lastLoc.split('||')[1]);
                                            const nlat = parseFloat(nLocation.split('||')[0]);
                                            const nlon = parseFloat(nLocation.split('||')[1]);

                                            // medrep moved to a new location
                                            // todo:  when nLocation = 0,0 choose between location before or after depending on the time difference. 
                                            if (nLocation != lastLoc && nlat !== 0 && nlon !== 0) {
                                                // record the location

                                                const marker = {
                                                    location: {
                                                        lat: lat,
                                                        lon: lon,
                                                        label: pointLbl,
                                                        color: terrUser['color'],
                                                        name: terrUser['name'],
                                                        address: call['location_address'],
                                                        location_name: call['location_name']
                                                    },
                                                    calls: [{ label: pointLbl, calls: markerCalls }]
                                                }
                                                terrLocs.push(marker)

                                                // console.log(pLoc)


                                                // prepare new location
                                                markerCalls = [];

                                                // set new location as the last location
                                                lastLoc = nLocation
                                                pointLbl = String.fromCharCode(pointLbl.charCodeAt(0) + 1)

                                            }

                                            const markerCall = {
                                                terr: terrUser['name'],
                                                name: call['cust_name'],
                                                visit_date: call['visit_date'],
                                                location_name: call['location_name'] || call['location_address'] || '',
                                                specialty: call['specialty'],
                                                lat: lat,
                                                lon: lon
                                            }

                                            if (latestCall.visit_date < markerCall.visit_date && lat > 0 && lon > 0) {
                                                latestCall = markerCall
                                            }

                                            markerCalls.push(markerCall)

                                        }

                                        if (terrLocs.length > 0) {
                                            // markers.push({ territory: terrId, locations: terrLocs, user: terrUser, color: RrLibService.colorFromString(terrUser['name']) })
                                            markers.push({ territory: terrId, locations: terrLocs, user: terrUser })
                                        }

                                        setTimeout(() => {
                                            this._mapMarkers.next({ markers: markers, recentCall: latestCall });
                                        }, 1000)


                                    }

                                })
                            })


                            // unsubscribe to existing call observers
                            var calObservers: Subscription[] = this._observers['calendarEvents'];

                            if (calObservers != null) {
                                calObservers.forEach(subs => subs.unsubscribe());
                            }

                            calObservers = [];

                            var tcounter = 0;

                            terrs.forEach(terr => {

                                RrDataService.terrEvents[terr] = { calls: [], plans: [], offFields: [] };

                                var updateEvents = async function () {
                                    var allEvents = [];
                                    var cEvents = [];
                                    var pEvents = [];

                                    // debugger
                                    for (const terr in RrDataService.terrEvents) {
                                        const tEvents = RrDataService.terrEvents[terr];
                                        allEvents = allEvents.concat(tEvents.calls.concat(tEvents.plans))
                                        cEvents = cEvents.concat(tEvents.calls)
                                        pEvents = pEvents.concat(tEvents.plans)
                                        oFEvents = oFEvents.concat(tEvents.offFields)
                                    }
                                    // _calendarEvents.next(allEvents);
                                    _calendarEvents.next({ calls: cEvents, plans: pEvents, offFields: oFEvents })
                                }

                                calObservers.push(this.clientDataRead(`/plans/${terr}`, r =>
                                    r.orderByKey()
                                        .startAt(RrLibService.dateToLongString(p.start))
                                        .endAt(RrLibService.dateToLongString(p.end))

                                ).snapshotChanges().subscribe(l => {

                                    let uList = this._allUsers.getValue();

                                    tcounter++;

                                    RrDataService.terrEvents[terr].plans = [];

                                    if (l != null) {
                                        l.forEach(day => {
                                            if (day != null) {
                                                day.payload.forEach(plan => {

                                                    const v = plan.val();

                                                    var calEvent = {
                                                        className: `${terr}-plans`,
                                                        id: `${terr}-plan-${v.itinerary_date}`,
                                                        _id: `${terr}-plan-${v.itinerary_date}`,
                                                        start: new Date(v.itinerary_date),
                                                        duration: 0,
                                                        title: v.cust_name,
                                                        color: RrLibService.colorFromString(v.cust_name),
                                                        backgroundColor: RrLibService.colorFromString(uList.byTerr[terr].name),
                                                        textColor: "#FFFFFF",
                                                        allDay: true
                                                        // meta : v
                                                    };

                                                    RrDataService.terrEvents[terr].plans.push(calEvent);
                                                    setTimeout(() => {
                                                        // updateEvents();
                                                    }, 1000)


                                                    return false;

                                                });
                                            }
                                        })

                                    }

                                }));

                                // calObservers.push(this.clientDataRead(`/calls/${terr}`, r =>
                                //     r.orderByKey()
                                //         .startAt(RrLibService.dateToLongString(p.start))
                                //         .endAt(RrLibService.dateToLongString(p.end))

                                // ).snapshotChanges().subscribe(l => {

                                //     let uList = this._allUsers.getValue();

                                //     RrDataService.terrEvents[terr].calls = [];
                                //     tcounter++;
                                //     if (l != null) {
                                //         l.forEach(day => {
                                //             if (day != null) {
                                //                 day.payload.forEach(call => {

                                //                     const v = call.val();

                                //                     var calEvent = {
                                //                         className: terr,
                                //                         id: `${terr}${v.visit_date}`,
                                //                         _id: `${terr}${v.visit_date}`,
                                //                         start: new Date(v.visit_date),
                                //                         duration: 0,
                                //                         title: v.cust_name,
                                //                         color: RrLibService.colorFromString(uList.byTerr[terr].name),
                                //                         backgroundColor: RrLibService.colorFromString(uList.byTerr[terr].name),
                                //                         textColor: "#FFFFFF",
                                //                         meta: v
                                //                     };

                                //                     RrDataService.terrEvents[terr].calls.push(calEvent);
                                //                     setTimeout(() => {
                                //                         // updateEvents();
                                //                     }, 1000)

                                //                     return false;

                                //                 });
                                //             }
                                //         })

                                //     }

                                //     // console.log(`${tcounter} of ${terrs.length}`)

                                //     // this._calendarLoadingProgress.next((tcounter * 1.0) / (terrs.length * 1.0))

                                // }))


                            })

                            this._observers['calendarEvents'] = calObservers;

                        })

                    }

                })

                this.clientGetObject('config/').snapshotChanges().subscribe(c => {

                    const config = c.payload;

                    this._config.next(c.payload);

                    if (config.hasChild('period_definition')) {
                        this.clientDataRead('/config/period_definition').snapshotChanges().subscribe(
                            list => {
                                var nPList = [];
                                list.forEach(snap => {
                                    const nPeriod = new Period(snap.payload);
                                    nPList.push(nPeriod);

                                    if (nPeriod.periodCode == this._currentCycle.getValue()) {
                                        this._currentPeriod.next(nPeriod);
                                    }

                                })
                                this._periodDefinitionList.next(nPList);

                            }
                        );
                    } else {
                        this.defaultDataRead('/config/period_definition').snapshotChanges().subscribe(
                            list => {
                                var nPList = [];
                                list.forEach(snap => {
                                    const nPeriod = new Period(snap.payload);
                                    nPList.push(nPeriod);

                                    if (nPeriod.periodCode == this._currentCycle.getValue()) {
                                        this._currentPeriod.next(nPeriod);
                                    }

                                })
                                this._periodDefinitionList.next(nPList);

                            }
                        );
                    }

                    if (config.hasChild('reports')) {
                        this.clientGetObject('/config/reports').snapshotChanges().subscribe(
                            list => {
                                // var nRList = [];
                                // this.getConfig('hidden_reports').then(hidden => {

                                //     list.forEach(snap => {

                                //         const nReport = new Report(snap.payload);
                                //         if (hidden.indexOf(nReport.title) == -1) {
                                //             nRList.push(nReport);
                                //         }

                                //     })
                                //     this.clientRlist = nRList;
                                //     this.updateReportsList();
                                // });

                                // var RList = list.payload.val()
                                // RList.forEach(snap => {
                                //     console.log(snap)
                                // })
                                this.clientRlist = list.payload.val()
                                this.updateReportsList();
                            }
                        );
                    } else {
                        this.defaultGetObject('/config/reports').snapshotChanges().subscribe(
                            list => {
                                // var nRList = [];
                                // this.getConfig('hidden_reports').then(hidden => {
                                //     list.forEach(snap => {

                                //         const nReport = new Report(snap.payload);
                                //         if (hidden.indexOf(nReport.title) == -1) {
                                //             nRList.push(nReport);
                                //         }

                                //     })
                                //     this.defaultRlist = nRList;
                                //     this.updateReportsList();
                                // });
                                this.defaultRlist = list.payload.val()
                                this.updateReportsList();
                            }
                        );
                    }

                    if (config.hasChild('widgets')) {
                        this.clientDataRead('/config/widgets').snapshotChanges().subscribe(
                            list => {

                                var nWList = [];
                                list.forEach(snap => {

                                    const nWidget = new Widget(snap.payload);

                                    nWList.push(nWidget);

                                })

                                this._widgets.next(nWList);
                            }
                        );
                    } else {
                        this.defaultDataRead('/config/widgets').snapshotChanges().subscribe(
                            list => {

                                var nWList = [];
                                list.forEach(snap => {

                                    const nWidget = new Widget(snap.payload);

                                    nWList.push(nWidget);

                                })

                                this._widgets.next(nWList);
                            }
                        );
                    }


                    if (config.hasChild('dash_charts')) {
                        this.clientDataRead('/config/dash_charts').snapshotChanges().subscribe(
                            list => {

                                var nWList = [];
                                list.forEach(snap => {

                                    const nWidget = new DashChart(snap.payload);
                                    nWidget.path = `${this.userInfo.client}/config/dash_charts/${snap.key}`
                                    nWList.push(nWidget);

                                })

                                this._chartList.next(nWList);
                            }
                        );
                    } else {
                        this.defaultDataRead('/config/dash_charts').snapshotChanges().subscribe(
                            list => {

                                var nWList = [];
                                list.forEach(snap => {

                                    const nWidget = new DashChart(snap.payload);
                                    nWidget.path = `default/config/dash_charts/${snap.key}`

                                    nWList.push(nWidget);

                                })

                                this._chartList.next(nWList);
                            }
                        );
                    }

                    if (config.hasChild('hidden_navbar_buttons')) {
                        this.clientGetObject('/config/hidden_navbar_buttons').snapshotChanges().subscribe(list => {
                            this.hideNavBarButtons();
                        })
                    } else {
                        this.defaultGetObject('/config/hidden_navbar_buttons').snapshotChanges().subscribe(list => {
                            this.hideNavBarButtons();
                        })
                    }


                })
                //temporary until eforms list in config is made - Martin Bautista
                this.clientDataRead('eforms/').snapshotChanges().subscribe(list => {
                    var eList = [];
                    list.forEach(snap => {

                        const nEform = new Eform(snap.payload)

                        eList.push(nEform);

                    })
                    this._eformsList.next(eList);
                })

            }

        });

    }

    updateTerritoryTree(tree) {
        this._territoryTree.next(tree)
    }

    updateReportsList() {
        var rawRList = [];
        var newRList = [];
        for (let id in this.defaultRlist) {
            rawRList[id] = this.defaultRlist[id];
        }
        for (let id in this.clientRlist) {
            rawRList[id] = this.clientRlist[id];
        }
        this.userData.subscribe(info => {
            // console.log(info["role"])
            this.getConfig('hidden_reports').then(hidden => {
                for (let id in rawRList) {

                    if (hidden.indexOf(id) == -1) {
                        if(rawRList[id]["restrictTo"]){
                            const str = rawRList[id]["restrictTo"]
                            if(str.indexOf(info["role"]) >= -1){
                                newRList.push(rawRList[id])
                            }
                        }else{
                            newRList.push(rawRList[id])
                        }
                    }
                }
                this._reportList.next(newRList)
            })
        })
    }

    hideNavBarButtons() {
        this.getConfig('hidden_navbar_buttons').then(hidden => {
            this._hidden_navbar_btns.next(hidden)
        })
    }

    /* GET RR Data*/
    getCustomers(path: string): AngularFireList<any> {
        // console.log(this.tp.territories);
        return this.imFir.getCustomers(this.userInfo.client + '/' + path);
    }
    getLookups(path: string): AngularFireObject<any> {
        return this.imFir.getLookups(this.userInfo.client + '/' + path);
    }

    getCardCustomers(path: string, currentFilter: string) {
        return this.imFir.getCardCustomers(this.userInfo.client + '/' + path);
    }

    getUserCalls(path: string, key: string): AngularFireList<any> {
        // console.log(this.userInfo.client + '/' + path, key);
        return this.imFir.getUserCalls(this.userInfo.client + '/' + path, key);
    }

    getUserNotes(path: string, key: string): AngularFireList<any> {
        // console.log(this.userInfo.client + '/' + path, key);
        return this.imFir.getUserNotes(this.userInfo.client + '/' + path, key);
    }



    SearchCardCustomers(path: string, start: BehaviorSubject<string>, filter: string): Observable<any> {
        // console.log(this.userInfo.client + '/' + path);
        return this.imFir.SearchCardCustomers(this.userInfo.client + '/' + path, start, filter);
    }

    updateCustomerData(path: string, key, userdata, doctorDataChild) {
        this.imFir.updateCustomerData('/' + this.userInfo.client + '/' + path + '/' + key, userdata, doctorDataChild);
    }

    /* SET RR Data */
    setCurrentCycle(newCycle: string) {
        this._currentCycle.next(newCycle);
        const period = this._periodDefinitionList.getValue().find(x => x.periodCode == newCycle);
        this._currentPeriod.next(period);
        // ImFireService.territories = this._userInfoTerritories;

    }


    setSelectedTerritories(territories: string[]) {
        if (this._selectedTerritories.getValue().join('') != territories.join('')) {
            this._selectedTerritories.next(territories);
        }
    }

    setSelectedDistrict(districts: string) {
        if (this._selectedDistrictsUserIDs.getValue() != districts) {
            this._selectedDistrictsUserIDs.next(districts);
        }
    }

    /* Raw Data Read / Write */

    clientDataRead(path: string, query?): AngularFireList<any> {
        return this.imFir.readFirList(this.userInfo.client + '/' + path, query)
    }

    defaultDataRead(path: string, query?): AngularFireList<any> {
        return this.imFir.readFirList('default/' + path, query)
    }

    clientGetObject(path: string, query?): AngularFireObject<any> {
        return this.imFir.readFirObject(this.userInfo.client + '/' + path, query)
    }

    defaultGetObject(path: string, query?): AngularFireObject<any> {
        return this.imFir.readFirObject('default/' + path, query)
    }

    clientSetObject(path: string, value: any) {
        return this.imFir.readFirObject(this.userInfo.client + '/' + path).set(value)
    }

    getConfig(path: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.imFir.readFirObject(`${AuthService.client}/users/${AuthService.userName}/config/${path}`).valueChanges().subscribe(uc => {
                if (uc) {
                    resolve(uc)
                } else {
                    this.imFir.readFirObject(`${AuthService.client}/config/${path}`).valueChanges().subscribe(cc => {
                        if (cc) {
                            resolve(cc)
                        } else {
                            this.imFir.readFirObject(`/default/config/${path}`).valueChanges().subscribe(dc => {
                                if (dc) {
                                    resolve(dc)
                                } else {
                                    reject(`path: "${path}" not found`)
                                }
                            })
                        }
                    })
                }
            })
        })
    }

    loadLookups() {
        this.clientGetObject('lookups').valueChanges().subscribe(lookups => {
            var lookupslist = [];
            
            for (const lName in lookups) {
                if (typeof lookups[lName] === 'string'){
                    console.log(lName)
                    console.log(lookups[lName])
                    var lookup = lookups[lName].split('||');
                    lookupslist[lName] = lookup;
                }
            }

            this._lookups_data.next(lookupslist);
        });
    }

    loadCustomerViewFields() {
        this.getConfig('customer_view_fields').then(fields => {

            var custFields = [];

            for (const fName in fields) {

                var field = fields[fName];

                field['field_name'] = fName;
                custFields.push(field);

            }

            var custFieldsSorted = custFields.sort((a, b) => {
                return a['order'] > b['order'] ? 1 : a['order'] < b['order'] ? -1 : 0;
            })

            this._customer_fields.next(custFieldsSorted);

            console.log(this.customer_fields);
        });

        this.getConfig('user_view_fields').then(fields => {

            var userFields = [];

            for (const fName in fields) {

                var field = fields[fName];

                field['field_name'] = fName;
                userFields.push(field);

            }

            var userFieldsSorted = userFields.sort((a, b) => {
                return a['order'] > b['order'] ? 1 : a['order'] < b['order'] ? -1 : 0;
            })

            this._user_fields.next(userFieldsSorted);

        });

    }

    userAccess(access: string): Observable<boolean> {

        return new Observable<boolean>(obs => {
            this.userData.subscribe(uInfo => {
                if (uInfo != null) {
                    obs.next(uInfo.isAllowedTo(access));
                } else {
                    obs.next(false);
                }
            })
        })

    }

    getDownloadURL(filename: string): Promise<any> {
        return this.imFir.getProfileImageURL(filename)
    }

    firClientRef(path: string): firebase.database.Reference {

        return this.firbaseRootRef.child(`${AuthService.client}/${path}`)
    }


}


/*  SIMPLE DATA MODEL */


export class DashChart {

    title: string;
    source: string;
    type: string;
    y_axis: Object;
    x_axis: Object;
    valueFormat: string;
    interpolate: string;
    target: string;
    height: number;
    margin: Object = { top: 20, bottom: 50, left: 20, right: 20 };
    stacked: boolean = false;
    path: string;

    constructor(snap: DatabaseSnapshot) {
        const v = snap.val();
        Object.keys(v).forEach(k => {
            this[k] = v[k];
        })
    }
}

export class Files {
    name: string;
    type: NodeType;
    ref: string;
    downloadURL: string;

    constructor(snap: DatabaseSnapshot) {
        const v = snap.val();
        Object.keys(v).forEach(k => {
            this[k] = v[k];
        })
    }
}

export class Hashtag {
    name: string;
    type: NodeType;
    ref: string;
    snap: DatabaseSnapshot;

    constructor(snap: DatabaseSnapshot) {
        const v = snap.val();
        this.name = snap.key;
        Object.keys(v).forEach(k => {
            this[k] = v[k];
        })
        this.snap = snap;
    }
}

export class IGNotif {
    from: string;
    date_time: Date;
    approve_date: Date;
    deny_date: Date;
    done: boolean;
    fir_ref: string;
    icon: string;
    message: string;
    nice_date: string;
    seen: Date;
    subject: string;
    type: string;

    constructor(snap: DatabaseSnapshot) {
        const v = snap.val();
        Object.keys(v).forEach(k => {
            this[k] = v[k];
        })
    }

}


export enum NodeType {
    Data,
    File
}

export class Report {
    key: string;
    title: string;
    source: string;

    constructor(snap: DatabaseSnapshot) {
        this.key = snap.key;
        const v = snap.val();
        Object.keys(v).forEach(k => {
            this[k] = v[k];
        })
    }
}


export class Widget {
    title: string;
    actual: string;
    target: string;
    variables: Object;

    constructor(snap: DatabaseSnapshot) {
        const v = snap.val();
        Object.keys(v).forEach(k => {
            this[k] = v[k];
        })
    }
}

export class PublicFeed {
    datetime: Date;
    description: string;
    profile_picture: string;
    profile_picture_url: string;
    type: string;
    user: string;
    snap: DatabaseSnapshot;
    name: string;
    key: string;
    color: string;
    title: string;

    get timeAgo(): string {
        return this.imFir.getNotificationTime(this.datetime);
    }

    constructor(snap: DatabaseSnapshot, private imFir: ImFireService) {
        this.key = snap.key;
        this.snap = snap;

        const v = snap.val();
        Object.keys(v).forEach(k => {
            this[k] = v[k];
        })

        this.description = this.description.replace(/%name%/ig, this.name);

        // this.profile_picture_url = this.profile_picture != null ? `${AuthService.client}/${this.profile_picture}` : 'images/profile.jpg'

        this.color = {
            call: "green",
            checkin: "blue",
            note: "orange"
        }[this.type];

        this.title = {
            call: "Call: ",
            checkin: "Checked-In: ",
            note: "Call Note Added: "
        }[this.type];
    }
}


export class UserData {
    name: string = '';
    territory_id: string = '';
    profile_picture: string = '';
    role: string = '';
    user_type: string = '';
    snap: DatabaseSnapshot;
    key: string = '';
    email: string = '';
    access: string = '';
    password: string = '';
    userid: string = '';

    isAllowedTo(access: string) {
        return this.access.indexOf(access) != -1;
    }

    constructor(snap: DatabaseSnapshot, private rrd: RrDataService) {
        if (snap != null) {
            this.key = snap.key;
            this.snap = snap;
            this.userid = this.key;
            const v = snap.val();
            Object.keys(v).forEach(k => {
                this[k] = v[k];
            })

            if (this.profile_picture > '') {
                this['profileURL'] = this.rrd.getDownloadURL(this.profile_picture);
            }

        } else {
            this.key = 'New'
        }
    }

    save() {
        var rec = {};

        for (const fname in this) {
            if ('isAllowedTo,save,delete,userid,key,profileURL'.indexOf(fname) == -1) {
                let f = fname.toString();
                let v = this[f]
                if (v != null && v != "") {
                    if (typeof v != 'object') {
                        rec[f] = v;
                    }
                }
            }
        }

        if (this.snap == null) {

            const ref = `${AuthService.client}/users/${this.key}`;
            this.rrd.firbaseRootRef.child(ref).set(rec, (a: Error) => {
                if (a) {
                    alert(`User ${this.name} was NOT saved. Error: ${a.message}`)
                } else {
                    alert(`User ${this.name} (${this.key}) was successfully created.`)
                }

            });

        } else {

            this.snap.ref.set(rec, (a: Error) => {
                if (a) {
                    alert(`User ${this.name} was NOT saved. Error: ${a.message}`)
                } else {
                    alert(`User ${this.name} (${this.key}) was successfully updated.`)
                }
            });

        }
    }

    delete() {
        const v = this.snap.val();
        const ref = `${AuthService.client}/users_deleted/${this.key}`;
        this.rrd.firbaseRootRef.child(ref).set(v).then(user => {
            this.snap.ref.remove()
                .then()
                .catch();
        });
    }
}

export enum AccessRights {
    IgniteAdmin = 'sysadmin'
}

export interface UserList {
    byTerr: object;
    byUser: object;
}

export class Eform {
    name: string;

    constructor(snap: DatabaseSnapshot) {
        this.name = snap.key
    }
}
