import { Component, OnInit } from '@angular/core';
import { RrDataService, PublicFeed, UserData } from 'app/shared/rr-data.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from 'app/shared/auth.service';
import { ChangeDetectionStrategy } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { ScrollEvent } from 'ngx-scroll-event';
import { ImFireService } from 'app/im-fire.service';

@Component({
  selector: 'app-public-feed',
  templateUrl: './public-feed.component.html',
  styleUrls: ['./public-feed.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublicFeedComponent implements OnInit {

  static allFeed: PublicFeed[] = [];
  static showAllFeeds = false;
  private publicFeed: PublicFeed[] = [];
  private _showAll = false;
  private selectedTerritoriesUserIDs = '';
  private profilePictures = {};
  private firstLoad = 10;

  constructor(private rrd: RrDataService, private fbs: AngularFireStorage, private cdr: ChangeDetectorRef, private imFir: ImFireService) {

    var feedTimer;

    rrd.allUsers.subscribe(uList => {
      
      if (uList != null) {
        rrd.public_feed.subscribe(feeds => {

          let publicFeed = []

          feeds.forEach((feed: PublicFeed) => {
            const from: UserData = uList.byUser[feed.user]

            let ppic = from ? from['profileURL'] : Promise.resolve('images/profile.jpg')
            feed.profile_picture_url = 'images/profile.jpg'
            if (ppic != null) {
              ppic.then(url => feed.profile_picture_url = url)
            }

            publicFeed.push(feed)
          
          })

          this.publicFeed = publicFeed.sort((a, b) => {
            return a.datetime > b.datetime ? -1 : a.datetime < b.datetime ? 1 : 0
          })

          this.cdr.markForCheck()

        })
      }
    })

    // rrd.last_public_feed.subscribe(publicFeed => {

    //   if (publicFeed != null) {

    //     // if (this.profilePictures[publicFeed.user] != null) {

    //     imFir.getProfileImageURL(publicFeed.profile_picture).then(url => publicFeed.profile_picture_url = url);

    //     // } else {

    //     //   this.getProfilePic(publicFeed.profile_picture).then(url => {

    //     //     publicFeed.profile_picture_url = url;
    //     //     var img = new Image(10, 10);
    //     //     img.src = url;

    //     //     this.profilePictures[publicFeed.user] = url;
    //     //   })
    //     // }

    //     if (PublicFeedComponent.allFeed.indexOf(publicFeed) == -1) {
    //       PublicFeedComponent.allFeed.unshift(publicFeed)
    //     }

    //     if (feedTimer != null) {
    //       clearTimeout(feedTimer);
    //     }

    //     // debugger
    //     feedTimer = setTimeout(() => {
    //       this.updateDisplay()
    //     }, 500);
    //   }

    // })

    // rrd.selectedTerritoriesUserIDs.subscribe(list => {
    //   this.selectedTerritoriesUserIDs = list;
    //   this.updateDisplay();
    // })
  }

  ngOnInit() {
  }

  showAll($event) {
    this._showAll = $event.checked;
    PublicFeedComponent.showAllFeeds = $event.checked;
    this.updateDisplay();
  }

  updateDisplay() {

    this.publicFeed = PublicFeedComponent.allFeed.sort((a, b) => {
      return a.datetime > b.datetime ? -1 : a.datetime < b.datetime ? 1 : 0
    })

    this.cdr.markForCheck();
  }

  getProfilePic(path: string): Promise<string> {
    // return Promise.resolve('images/profile.jpg')
    return new Promise((resolve, reject) => {
      if (path != null) {

        const x = this.fbs.ref(`${AuthService.client}/${path}`).getDownloadURL().subscribe(url => {
          resolve(url);
          x.unsubscribe();
        })

      } else {
        resolve('images/profile.jpg');
      }
    })
  }

  public handleScroll(event: ScrollEvent) {

    if (event.isReachingBottom) {
      this.firstLoad += 10
    }
  }
}
