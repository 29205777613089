import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

/**
 * Eform Generator - Ignite Manager 2 need a way to view and in some cases submit Eforms. This page 
 */

@Component({
  selector: 'app-eforms',
  templateUrl: './eforms.component.html',
  styleUrls: ['./eforms.component.css']
})
export class EformsComponent implements OnInit {

    eform: string;

    constructor(private route: ActivatedRoute) {
        this.route.params.subscribe(params => {
            this.eform = params['eform'];
        })
    }

    ngOnInit() {
    }

}
