import {Injectable, Inject} from "@angular/core";
import * as firebase from 'firebase/app';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule, AngularFireAuth } from '@angular/fire/auth';
import { UserInfo } from "./user-info";
import { Observable, BehaviorSubject, Subject } from "rxjs";
import { ImFireService } from '../im-fire.service'
import { userInfo } from "os";
import { FirebaseApp } from "@angular/fire/firebase.app.module";
import { map } from "rxjs/operators";

@Injectable()
export class AuthService {
    static UNKNOWN_USER = {
        isAnonymous: true,
        email: null,
        displayName: null,
        providerId: null,
        uid: null,
        client : null,
        userName: null
    };

    static userName = "";
    static client = "";

    userInfo = new BehaviorSubject<UserInfo>(AuthService.UNKNOWN_USER);
    private user: firebase.User;

    public currUser = this.userInfo.asObservable()

    constructor( private angularFireAuth: AngularFireAuth, private firebase : FirebaseApp ) {

        this.angularFireAuth.authState.subscribe(user => {
            // console.log("user: ", JSON.stringify(user));
            this.user = user;
            let userInfo = new UserInfo();
            if (user != null) {

                userInfo.isAnonymous = user.isAnonymous;
                userInfo.email = user.email;
                userInfo.displayName = user.displayName;
                userInfo.providerId = user.providerId;
                userInfo.photoURL = user.photoURL;
                userInfo.uid = user.uid;

                let u = user.email.split('@');
                userInfo.client = u[1].split('.')[0];
                userInfo.userName = u[0];

                AuthService.client = userInfo.client;
                AuthService.userName = userInfo.userName;

            } else {

                this.user = null;
                userInfo.isAnonymous = true;

            }
            this.userInfo.next(userInfo);
        });
    }

    login(email: string, password: string): Observable<string> {
        let result = new Subject<string>();
        this.angularFireAuth.auth.signInWithEmailAndPassword(email, password)
            .then(() => result.next("success"))
            .catch(err => result.error(err));

        firebase.auth().signInWithEmailAndPassword(email, password);

        return result.asObservable();
    }

    currentUser(): Observable<UserInfo> {
        return this.userInfo.asObservable();
    }

    logout(): Observable<string> {
        let result = new Subject<string>();
        this.userInfo.next(AuthService.UNKNOWN_USER);
        this.angularFireAuth.auth.signOut()
            .then(() => result.next("success"))
            .catch(err => result.error(err));

        firebase.auth().signOut();

        return result.asObservable();
    }

    isLoggedIn(): Observable<boolean> {
        return this.userInfo.pipe(
            map(userInfo => !userInfo.isAnonymous)
        );
    }

    updateDisplayName(displayName: string): Observable<string> {
        let result = new Subject<string>();
        this.user.updateProfile({displayName: displayName, photoURL: null})
            .then(() => {result.next("success")})
            .catch(err => result.error(err));
        return result;
    }

    createUser(email: string, password: string, displayName: string): Observable<string> {
        let result = new Subject<string>();
        // this.angularFireAuth.authState.subscribe(user => {
        //     // console.log("Update: ", user);
        //     if (user != null) {
        //         user.updateProfile({displayName: displayName, photoURL: null});
        //     }
        // });

        
        this.angularFireAuth.auth.createUserWithEmailAndPassword(email, password)
            .then((v) => {
                //auth.auth.updateProfile({displayName: displayName, photoURL: null});
                result.next("success");
                // v.updateDisplayName(displayName);
                
            })
            .catch(err => result.error(err));

        return result.asObservable();
    }

    updateEmail(email: string): Observable<string> {
        let result = new Subject<string>();
        this.user.updateEmail(email)
            .then(() => result.next("success"))
            .catch(err => result.error(err));
        return result.asObservable();
    }

    updatePassword(password: string): Observable<string> {

        

        let result = new Subject<string>();
        this.user.updatePassword(password)
                .then(a => {
                    result.next("success");
                })
                .catch(err => result.error(err));
        return result.asObservable();
    }

    sendPasswordResetEmail(email: string): Observable<string> {
        let result = new Subject<string>();
        this.angularFireAuth.auth.sendPasswordResetEmail(email)
            .then(() => result.next("success"))
            .catch(err => result.error(err));
        return result;
    }

    loginViaProvider(provider: string): Observable<String> {
        let result = new Subject<string>();
        if (provider === "google") {
            this.angularFireAuth
                .auth
                .signInWithPopup(new firebase.auth.GoogleAuthProvider())
                .then(auth => result.next("success"))
                .catch(err => result.error(err));
            return result.asObservable();
        }
        else if (provider === "twitter") {
            this.angularFireAuth
                .auth
                .signInWithPopup(new firebase.auth.TwitterAuthProvider())
                .then(auth => result.next("success"))
                .catch(err => result.error(err));
            return result.asObservable();
        }
        result.error("Not a supported authentication method: " + provider)
        return result.asObservable();
    }
}
