import { Component, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material';
import { ImFireService } from '../../im-fire.service';
import { RrDataService, UserData } from '../../shared/rr-data.service';
import { AngularFireList, AngularFireAction } from "@angular/fire/database/interfaces";
import { Observable } from "rxjs";
import { UserInfo } from 'app/shared/user-info';
import { CustomerData } from '../customer';
import { FileOverlayComponent } from '../cards/file-overlay/file-overlay.component';
import { DatabaseSnapshot } from '@angular/fire/database-deprecated/interfaces';
import { DatePipe } from '@angular/common';
import { AuthService } from '../../shared/auth.service';
import { DisplayUserComponent } from "app/display-user/display-user.component";
import { AppNavbarComponent } from "../../app-navbar/app-navbar.component";
import * as _ from 'lodash';
import { DataSource } from '@angular/cdk/collections';
import { findIndex } from 'rxjs/operators/findIndex';
import { isNull } from 'util';
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomersListComponent implements OnInit {

  @Input('CustomerList') customerList: Observable<CustomerData[]>;

  displayedColumns = ['thumbnail', 'md_id', 'name', 'specialty_description', 'class_code', 'frequency', 'clinic_address', 'action'];
  selectedCustomer;
  editRow: string = "0";
  firstLoad: number = 20;
  limit: number = 10;
  loading: boolean = true;
  private subscription;
  currentDate = new Date();
  currentCycle: string;
  currentFilter: string = "md_id";
  holder = 0;
  allCustomers : CustomerData[];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  dataSource: MatTableDataSource<CustomerData> = new MatTableDataSource();
  filter: BehaviorSubject<string | null> = new BehaviorSubject("");
  // TEST
  /// unwrapped arrays from firebase
  filteredCustomers: any;

  loadCount = 40;

  /// filter-able properties
  forDelete: string;
  forApprove: string;

  /// Active filter rules
  filters = {}

  private userData: UserData;
  private allowedToDelete = false;

  constructor(public dialog: MatDialog, 
            private rrd: RrDataService, 
            private fb: ImFireService, 
            private setDate: DatePipe, 
            private cdr : ChangeDetectorRef) { 

    this.rrd.userData.subscribe(u => {
      if (u) {
        this.userData = u;
        this.allowedToDelete = u.isAllowedTo('delete_md');
      }
    })

  }


  openEditDialog(i: CustomerData): void {
    // //this.editRow = i;
    this.selectedCustomer = i;
    let dialogRef = this.dialog.open(FileOverlayComponent, {
      data: this.selectedCustomer
    });
  }

  getPage($event) {
    // console.log('page', $event);
  }

  ngOnInit() {

    this.customerList.subscribe(cList => {
      this.allCustomers = cList;
      this.dataSource.data = cList.slice(0, this.firstLoad);
      this.cdr.markForCheck();
      // if (this.paginator != null) {
      //   this.paginator.firstPage()
      // }
    })

  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  loadMore() {
    this.firstLoad = this.firstLoad + 50;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  toggleDelete(row: CustomerData) {
    row.toggleDelete()
  }

  onTableScroll(e) {

    // debugger
    const tableViewHeight = e.target.offsetHeight // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    
    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 100;
    const limit = tableScrollHeight - tableViewHeight - buffer;    
    
    // console.log(scrollLocation, limit)

    if (scrollLocation > limit && this.firstLoad < this.allCustomers.length) {
      this.firstLoad = this.firstLoad + 50;
      this.dataSource.data = this.allCustomers.slice(0, this.firstLoad);
      this.cdr.markForCheck();
    }
  }

  // getFilter(toFilter) {
  //   if (toFilter == this.currentFilter) {
  //     switch (toFilter) {
  //       case "md_id":
  //         this.applyFilter('');
  //         this.getDoctorClinic();
  //         break;

  //       case "inclusion_notification":

  //         var filteredData: MatTableDataSource<CustomerData> = new MatTableDataSource();
  //         for (var i in this.dataSource.data) {
  //           this.getInclusion(i, filteredData);
  //         };
  //         // this.applyFilter('');
  //         break;


  //       case "delete_notification":

  //         var filteredData: MatTableDataSource<CustomerData> = new MatTableDataSource();
  //         for (var i in this.dataSource.data) {
  //           this.getDelete(i, filteredData);
  //         };
  //         this.subscription = filteredData.data;
  //         console.log(this.subscription);

  //         console.log(filteredData.data);
  //         for (var i in filteredData.data) {

  //         }
  //         // this.applyFilter('');
  //         break;


  //     };
  //   }
  //   else {
  //     this.currentFilter = toFilter;
  //     this.getFilter(this.currentFilter);
  //   }
  // }



  // getDoctorClinic() {

  //   this.subscription = this.rrd.getCustomers('doctor_clinic/TD');

  //   this.subscription.snapshotChanges().subscribe(items => {
  //     this.dataSource.data = items.map(item =>
  //       (
  //         {

  //           $key: item.key, ...item.payload.val()
  //         })
  //     )
  //     for (var i in this.dataSource.data) {
  //       this.getCalls(i);
  //     }
  //     this.loading = false;
  //   });

  // }

  // getCalls(i) {
  //   var md = this.dataSource.data[i];
  //   md.territory = "TD";

  //   // this.fb.readFirObject(`${AuthService.client}/calls_per_customer/${this.setDate.transform(
  //   //   this.currentDate, 'yyyy-MM')}/${md.territory}/${md.$key}`).snapshotChanges().subscribe(
  //   //   r => {
  //   //     if (md.frequency == null) {
  //   //       md.frequency = "N/A";

  //   //     }
  //   //     else if (r.payload.numChildren() >= parseInt(md.frequency.split('x', 1)[0]) && r.payload.numChildren() > 0) {
  //   //       md.bg_color = 'rgb(140, 241, 114)';
  //   //     }
  //   //     else if (r.payload.numChildren() < parseInt(md.frequency.split('x', 1)[0]) && r.payload.numChildren() > 0) {
  //   //       md.bg_color = 'rgb(243, 235, 127)';
  //   //     }
  //   //   });
  // }

  // getDelete(i: string, filteredData: MatTableDataSource<CustomerData>) {
  //   var md = this.dataSource.data[i];
  //   if (md.delete_notification != null) {

  //     filteredData.data.push(md);
  //     for (var i in filteredData.data) {

  //     }
  //     // md = this.dataSource.data[this.holder];
  //     // md.$key = this.dataSource.data[this.holder].$key;
  //     // this.dataSource.data[this.holder] = filteredData.data[this.holder];
  //     // this.dataSource.data[this.holder].$key = filteredData.data[this.holder].$key;
  //     // this.holder = this.holder + 1;

  //   }
  // }

  // getInclusion(i: string, filteredData: MatTableDataSource<CustomerData>) {
  //   var md = this.dataSource.data[i];
  //   if (md.inclusion_notification != null && md.delete_notification == null) {

  //     filteredData.data.push(md);
  //     // md = this.dataSource.data[this.holder];
  //     // md.$key = this.dataSource.data[this.holder].$key;
  //     // this.dataSource.data[this.holder] = filteredData.data[this.holder];
  //     // this.dataSource.data[this.holder].$key = filteredData.data[this.holder].$key;
  //     // this.holder = this.holder + 1;

  //   }
  // }

  // getColor(delete_notification, inclusion_notification) {
  //   if (delete_notification != null)
  //     return 'rgb(185, 101, 22)'
  //   else if (inclusion_notification != null)
  //     return 'rgb(51, 49, 48)'
  //   else if (inclusion_notification == null && delete_notification == null)
  //     return 'rgb(4, 111, 161)'
  //   else if (inclusion_notification != null && delete_notification != null)
  //     return 'rgb(185, 101, 22)'
  // }
}









