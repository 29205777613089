import { Component, OnInit, Inject, Input } from '@angular/core';
import { RrDataService, UserList, UserData } from '../shared/rr-data.service';
import { Period } from 'app/shared/period-definition';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'app/shared/auth.service';
import { RrLibService } from 'app/rr-lib.service';
import { CustomerData } from 'app/customers/customer';
import { ViewChild } from '@angular/core/src/metadata/di';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
// import { parseDate } from 'ngx-bootstrap/chronos/create/local';
import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { DatabaseSnapshot } from '@angular/fire/database-deprecated/interfaces';
import { access } from 'fs';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  constructor(private rrd: RrDataService, private sanitizer: DomSanitizer) { }

  private userList = {};
  private periods: Period[];
  private terrStruc = [];
  private totalTerritories = 0;
  private locked = false;
  private nextCycle: any;

  ngOnInit() {
    this.rrd.periodDefinitionList.subscribe(plist => {
      this.periods = plist;
      this.locked = (plist.find(p => { return p.periodCode > RrLibService.cycleToday() }) != null);
      const currCycle = plist.find(p => { return p.periodCode == RrLibService.cycleToday() });
      if (currCycle) {

        const lastDay = new Date(currCycle.end);
        const start = new Date(lastDay.getTime() + 1000 * 60 * 60 * 24);
        const tn = new Date(start.getTime() + 1000 * 60 * 60 * 24 * 32);
        const firstOfNextMonth = new Date(RrLibService.dateToLongString(tn).substring(0, 7) + '-01');
        const last = new Date(firstOfNextMonth.getTime() - 1000 * 60 * 60 * 24)
        const nCycle = RrLibService.dateToLongString(start).substring(0, 7);

        const dP = new DatePipe('en-US');
        const description = dP.transform(start, "MMMM - yyyy");

        this.nextCycle = { periodCode: nCycle, description: description, start: dP.transform(start, "yyyy-MM-dd"), end: dP.transform(last, "yyyy-MM-dd") }
      }

    })

    this.rrd.allUsers.subscribe(userList => {

      this.userList = {};
      var x = 0;
      var y = 0;
      for (let uid in userList.byUser) {

        x += 1;

        const u = userList.byUser[uid];

        
        if (u.territory_id && u.territory_id.indexOf('UNK') != 0) {
          if (u.manager) {
            y += 1;
            var ul = this.userList[u.manager] || [];
            this.generateDownloadJsonUri(u);
            this.checkMaster(u);
            this.checkActivity(u);
            u.masterCopied = 0;
            u.allDenied = 0;
            u.allApproved = 0;
            u.initMasterBusy = true;
            ul.push(u);
            this.userList[u.manager] = ul;
            // console.log(x, y, 'counted', uid, u.name, u.manager, u.territory_id, u.user_type);
          }

          this.totalTerritories = y;
        }

      }

      this.terrStruc = [];
      for (let manager in this.userList) {
        this.terrStruc.push({
          manager: manager,
          name: `${userList.byUser[manager] ? userList.byUser[manager].name : 'No Name'}`,
          territories: this.userList[manager]
        })
      }
    })
  }

  generateDownloadJsonUri(user: UserData) {
    this.rrd.clientGetObject(`/doctor_clinic/${user.territory_id}`).snapshotChanges().subscribe(data => {
      const obj = data.payload.val();

      var theJSON = JSON.stringify(obj);
      var uri = this.sanitizer.bypassSecurityTrustUrl("data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
      user['doctor_clinic_download'] = uri;

    })
  }

  purgeMaster(user: UserData) {
    let master: DatabaseSnapshot = user['master_list'];
    if (master) {
      master.forEach(row => {

        if (row.hasChild('inclusion_notification')) {
          row.ref.child('inclusion_notification').remove();
        }

        if (row.hasChild('delete_notification')) {
          row.ref.remove();
        }

        return false;
      })
    }
  }

  checkMaster(user: UserData) {
    this.rrd.clientGetObject(`/doctor_clinic_master/${user.territory_id}`).snapshotChanges().subscribe(data => {

      user['master_list'] = data.payload;
      user['withMaster'] = data.payload.hasChildren();
      user["initMasterBusy"] = false;

    })
  }

  checkActivity(user: UserData) {
    this.rrd.clientGetObject(`/_call_counters/${RrLibService.cycleToday()}/daily_calls/${user.territory_id}`).snapshotChanges().subscribe(data => {

      user['withCalls'] = data.payload.hasChildren();

    })
  }

  copyDistrict(district: UserData[]) {
    district.forEach(user => {
      user['masterCopied'] = 2;
      const terrID = user.territory_id;
      const data = user['master_list'];
      if (data.hasChildren()) {
        this.rrd.firbaseRootRef.child(`/${AuthService.client}/doctor_clinic/${terrID}`).set(data.val(), (a: Error) => {
          if (a) {
            user['masterCopied'] = 3;
            user['masterCopiedErr'] = a.message;
          } else {
            user['masterCopied'] = 1;
          }
        })
      } else {
        user['masterCopied'] = 0;
      }

    })
  }

  initMaster(user: UserData) {
    if (confirm(`This will overwrite ${user.name}'s Master.  Any changes you may have already made may be lost.  Continue?`)) {

      user['initMasterBusy'] = true;
      this.rrd.clientGetObject(`/doctor_clinic/${user.territory_id}`).snapshotChanges().subscribe(customers => {
        const data = customers.payload;
        this.rrd.firbaseRootRef.child(`/${AuthService.client}/doctor_clinic_master/${user.territory_id}`).set(data.val(), (a: Error) => {
          user['initMasterBusy'] = false;
        })
      })


    }
  }

  changeManager(user: UserData, event) {
    // debugger
    this.rrd.firbaseRootRef.child(`/${AuthService.client}/users/${user['userid']}/manager`).set(user['manager'], (a: Error) => {
      this.userList[user['manager']].push(user)

    })
  }


  denyAll(district: UserData[]) {
    district.forEach(user => {
      user['allDenied'] = 2;
      const terrID = user.territory_id;

      const subs = this.rrd.clientDataRead(`/doctor_clinic/${terrID}`).snapshotChanges().subscribe(customers => {

        var count = customers.length

        if (count > 0) {
          subs.unsubscribe();
        }

        customers.forEach(cust => {
          count = count - 1;
          const cData = cust.payload.val();

          this.rrd.firbaseRootRef.child(`/${AuthService.client}/doctor_clinic_bak/${terrID}/${cust.key}`).set(cData)

          if (cData['delete_notification'] != null) {
            cust.payload.ref.child('delete_notification').remove();
          }

          if (cData['inclusion_notification'] != null) {
            cust.payload.ref.remove();
          }

          // clean up empty records
          if (cData['last_name'] == null && cData['first_name'] == null) {
            cust.payload.ref.remove();
          }

          if (count <= 0) {
            user['allDenied'] = 1;
          }
        })

        this.resetMaster(user, terrID);


      })

    })
  }

  resetMaster(user: UserData, terrID: string) {
    this.rrd.firbaseRootRef.child(`/${AuthService.client}/doctor_clinic_master/${terrID}`).once('value', masterlist => {
      let mlist = masterlist.val()
      if (mlist) {
        this.rrd.firbaseRootRef.child(`/${AuthService.client}/doctor_clinic_master_bak/${terrID}`).set(mlist)
          .then(() => {
            this.rrd.firbaseRootRef.child(`/${AuthService.client}/doctor_clinic_master/${terrID}`).remove()
              .then(() => { this.checkMaster(user) })
          })
      }
    })
  }

  approveAll(district: UserData[]) {
    district.forEach(user => {
      user['allApproved'] = 2;
      const terrID = user.territory_id;

      const subs = this.rrd.clientDataRead(`/doctor_clinic/${terrID}`).snapshotChanges().subscribe(customers => {

        var count = customers.length

        if (count > 0) {
          subs.unsubscribe();
        }

        customers.forEach(cust => {

          count = count - 1;
          const cData = cust.payload.val();

          this.rrd.firbaseRootRef.child(`/${AuthService.client}/doctor_clinic_bak/${terrID}/${cust.key}`).set(cData)




          // clean up empty records
          if ((cData['last_name'] == null && cData['first_name'] == null) || (cData['delete_notification'] != null)) {

            cust.payload.ref.remove();

          } else {
            if (cData['inclusion_notification'] != null) {

              // const p1 = (cData['last_name'] || '-').substring(0,1) ;
              // const p2 = (cData['first_name']|| '-').substring(0,1) || '-';
              // const p3 = (cData['middle_name']|| '-').substring(0,1) || '-';

              // const str = `${(cData['last_name'] || '-')}${(cData['first_name'] || '-')}${(cData['middle_name'] || '-')}${(cData['specialty_description'] || '-')}`
              // const p4 = RrLibService.codeFromString(str.toUpperCase());

              const oldMDID = cust.key;
              // const newMDID = (p1+p2+p3+p4).toUpperCase();

              const newMDID = CustomerData.getCustomerKey(cData);

              cData['md_id'] = newMDID;
              delete cData['inclusion_notification'];


              cust.payload.ref.remove();

              cust.payload.ref.parent.child(newMDID).set(cData);
              this.rrd.firbaseRootRef.child(`/${AuthService.client}/doctor/${newMDID}`).set(cData, (a: Error) => {

                if (a == null) {
                  // move notes and visits to new MDID
                  const s1 = this.rrd.clientDataRead(`/notes/`).snapshotChanges().subscribe(cycles => {

                    cycles.forEach(c => {
                      const cycle = c.key;
                      const snap = c.payload;

                      snap.forEach(note => {

                        return false
                      })

                    })
                  })

                }

              })

            }
          }

          if (count <= 0) {
            user['allApproved'] = 1;
          }

        })

        this.resetMaster(user, terrID);

      })

    })
  }

  trackByFn(index, arr) {
    return index;
  }

}

@Component({
  selector: 'admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminUsersComponent implements OnInit {

  @Input('locked') locked: boolean;
  @Input('nextCycle') nextCycle: Period;

  private userList: UserData[] = [];
  private loadCount: 40;

  constructor(private rrd: RrDataService, private dialog: MatDialog, private cdr: ChangeDetectorRef) {
    this.rrd.clientDataRead('/users').query.once('value', userList => {
      this.userList = [];
      // cdr.detach();
      
      userList.forEach(u => {
        const user = new UserData(u, this.rrd);
        this.userList.push(user);
        return false
      })
      cdr.markForCheck()
      // cdr.reattach();
    })

  }

  ngOnInit() {

  }

  trackByFn(index, arr) {
    return index;
  }

  newUser() {
    var newUser = new UserData(null, this.rrd);
    this.openEditDialog(newUser);
  }

  onScroll(event) {

    const scrollTop = event.target.scrollTop;
    const scrollHeight = event.target.scrollHeight;
    const offsetHeight = event.target.offsetHeight;
    const scrollPosition = scrollTop + offsetHeight;
    const scrollTreshold = scrollHeight - 600;

    if ((scrollPosition > scrollTreshold) && (this.loadCount < this.userList.length)) {
      this.loadCount += 40;
    }
  }

  openEditDialog(user: UserData): void {
    // this.editRow = i;

    let dialogRef = this.dialog.open(EditUserComponent, {
      data: user
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }

  delete(user: UserData) {
    if (confirm(`Are you sure you want to delete user ${user.name}?  This cannot be undone. Do you wish to proceed?`)) {
      user.delete();
    }
  }

  lock() {
    if (confirm('Have you checked to make sure all your users for the next cycle are accounted for.  Once finalized, you will not be able to delete any more users until the start of the next cycle and this WILL BE THE FINAL BASIS FOR BILLING. This cannot be undone.  Do you wish to continue?')) {
      // TODO: create dialog to explain more and allow user to define the period parameters
      this.rrd.firbaseRootRef.child(`${AuthService.client}/config/period_definition/${this.nextCycle.periodCode}`).set(this.nextCycle);

    }
  }

  disableALLAddDeleteMDs() {
    if (confirm('This will disable the option to add/delete MDs for all non DM Users. Are you sure?')) {
      this.userList.forEach(user => {
        this.disableAddDeleteMDs(user)
      })
    }
  }

  enableALLAddDeleteMDs() {
    if (confirm('This will enable the option to add/delete MDs for all non DM Users. Are you sure?')) {
      this.userList.forEach(user => {
        this.enableAddDeleteMDs(user)
      })
    }
  }

  disableALLCallPlanner() {
    if (confirm('This will disable the Call Planner for all non DM Users. Are you sure?')) {
      this.userList.forEach(user => {
        this.disableAddDeleteMDs(user)
      })
    }
  }

  enableALLCallPlanner() {
    if (confirm('This will enable the Call Planner for all non DM Users. Are you sure?')) {
      this.userList.forEach(user => {
        this.disableAddDeleteMDs(user)
      })
    }
  }

  disableAddDeleteMDs(user: UserData) {
    if (user.access.indexOf('add_md') != -1 && user.access.indexOf('approve') == -1 && user.access.indexOf('sysadmin') == -1){
      console.log(user.key)
      const access = user.access.split('||')
      console.log(access)
      var acc = []
      access.forEach(x => {
        if (x != 'add_md' && x != 'delete_md'){
          acc.push(x)
        }
      })
      this.rrd.firbaseRootRef.child(`${AuthService.client}/users/${user.key}/access`).set(acc.join("||"));
      // console.log(acc.join("||"))

    }
  }

  enableAddDeleteMDs(user: UserData) {
    if (user.access.indexOf('add_md') == -1 && user.access.indexOf('approve') == -1 && user.access.indexOf('sysadmin') != -1){
      console.log(user.key)
      const access = user.access.split('||')
      console.log(access)

      access.push('add_md')
      access.push('delete_md')
      
      // console.log(access.join("||"))
      this.rrd.firbaseRootRef.child(`${AuthService.client}/users/${user.key}/access`).set(access.join("||"));
    }
  }

  disableUserCallPlanner(user: UserData) {
    if (user.access.indexOf('set_plan') != -1 && user.access.indexOf('approve') == -1 && user.access.indexOf('sysadmin') == -1){
      console.log(user.key)
      const access = user.access.split('||')
      console.log(access)
      var acc = []
      access.forEach(x => {
        if (x != 'set_plan'){
          acc.push(x)
        }
      })
      this.rrd.firbaseRootRef.child(`${AuthService.client}/users/${user.key}/access`).set(acc.join("||"));
      // console.log(acc.join("||"))

    }
  }

  enableUserCallPlanner(user: UserData) {
    if (user.access.indexOf('set_plan') == -1 && user.access.indexOf('approve') == -1 && user.access.indexOf('sysadmin') != -1){
      console.log(user.key)
      const access = user.access.split('||')
      console.log(access)

      access.push('set_plan')
      
      // console.log(access.join("||"))
      this.rrd.firbaseRootRef.child(`${AuthService.client}/users/${user.key}/access`).set(access.join("||"));
    }
  }

}

@Component({
  selector: 'user-edit',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./admin.component.css']
})
export class EditUserComponent implements OnInit {

  user_fields = new BehaviorSubject<any[]>([]);
  territory_ids = [];
  territory_list = [];
  user_list = [];
  alert: any = null;

  static managers: string[] = null;

  constructor(
    private dialog: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) private data: UserData, private rrd: RrDataService, private auth: AuthService) {

    this.territory_ids = data['territory_id'].split('||');

  }

  ngOnInit() {
    this.rrd.user_fields.subscribe(fields => {

      // console.log(fields);

      this.rrd.lookups_data.subscribe(lookups => {

        this.rrd.allUsers.subscribe(uList => {
          var terrList = [];
          var userList = [];

          // if (EditUserComponent.managers == null) {

          var managers = [];

          for (const user in uList.byUser) {
            const u: UserData = uList.byUser[user];
            if (u.user_type == 'manager') {
              managers.push(`${user}~${u.name}`)
              console.log(`${user}~${u.name}`);
            }
          }

          EditUserComponent.managers = managers;

          // }

          for (const terr in uList.byTerr) {
            if (terr.indexOf('||') == -1) {
              terrList.push(terr);
            }
          }
          for (const user in uList.byUser) {
            if (user.indexOf('||') == -1) {
              userList.push(user);
            }
          }

          this.user_list = userList;

          if (terrList.length > 0) {
            lookups['territories'] = terrList
            lookups['access'] = "set_plan||set_visit||add_md||delete_md||approve_add||approve_delete||approve_ctd||sysadmin".split('||');
            lookups['managers'] = EditUserComponent.managers;

            var userFields = [];
            for (const fName in fields) {
              var field = fields[fName];

              var type = field['type'];

              if (field['type'] === undefined) {
                type = '';
              }

              if (type.includes('lookup')) {
                var str = type.split('||');
                const lookup: any[] = lookups[str[1]];
                var lookupData = [];
                lookup.forEach(a => {
                  const b = a.split('~');
                  lookupData.push({ value: b[0], desc: b[1] || b[0] })
                })

                lookupData = lookupData.sort((a, b) => { return a.desc > b.desc ? 1 : a.desc < b.desc ? -1 : 0 });

                field['lookup_data'] = lookupData;
                field['field_type'] = str[0];
              }
              else if (type.includes('tag')) {
                var str = type.split('||');


                field['tags'] = lookups[str[1]];
                field['field_type'] = str[0];
                if (this.data[field.field_name]) {
                  this.data[field.field_name + '_tag'] = this.data[field.field_name].split('||')
                }
              }
              else {
                field['field_type'] = type;
              }
              userFields.push(field);
            }
            this.user_fields.next(userFields);
          }
        });
      });

    });
  }

  tagUpdate(value, field_name) {
    const data: string[] = []

    this.data[field_name + '_tag'].forEach(d => {
      if (typeof d == 'object') {
        data.push(d.value)
      } else {
        data.push(d);
      }
    })

    this.data[field_name] = data.join('||');

  }

  close() {
    this.dialog.close();
  }

  validate(event, field) {
    // console.log(event, field);
  }

  save() {

    this.alert = null;
    var newUser: boolean = false;

    // do validation
    if (this.data.email == '') {
      this.alert = { type: 'danger', message: `Email address is empty.  Please use ex: <userid>@${AuthService.client}.idx` };
      return
    }

    if (this.data.key == 'New') {
      // check new userid exists
      const nUserID = this.data.email.split('@')[0].toLowerCase();

      if (this.user_list.indexOf(nUserID) != -1) {
        this.alert = { type: 'danger', message: `User ID: ${nUserID} is already used.` };
        return
      }

      this.data.key = nUserID;
      newUser = true;
    }

    if (this.data.password == '') {
      this.alert = { type: 'danger', message: `Password cannot be empty!` };
      return
    }

    if (this.data.name == '') {
      this.alert = { type: 'danger', message: `Full Name must not be empty` };
      return
    }

    if (this.data.territory_id == '') {
      this.alert = { type: 'danger', message: `Territory ID(s) must not be empty.` };
      return
    }

    this.data.user_type = this.data.territory_id.split('||').length > 1 ? 'manager' : 'medrep'

    if (this.data.access == '') {
      const access = this.data.user_type == 'manager' ? 'approve_add||approve_delete||approve_ctd' : 'set_plan||set_visit||add_md||delete_md';

      this.data.access = access;
      this.data['access_tag'] = access.split('||');

      this.alert = { type: 'warning', message: `Security Access was set to default. Please confirm and click Save again.` };
      return
    }

    if (newUser) {
      this.auth.createUser(this.data.email, this.data.password, this.data.name);
    }

    this.data.save();

    this.dialog.close();

  }
}

