import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { CustomerData } from '../customer';
import { FileOverlayComponent } from './file-overlay/file-overlay.component';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { RrDataService } from '../../shared/rr-data.service';
import { HostListener } from '@angular/core';
import { ScrollEvent } from 'ngx-scroll-event';
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { Observable } from 'rxjs';

@Component({
  selector: 'rr-customer-card-view',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CardsComponent implements OnInit {

  @Input('CustomerList') customerList: Observable<CustomerData[]>;

  selectedCustomer;
  editRow: string = "0";
  firstLoad: number = 100;
  limit: number = 10;
  loading: boolean = true;
  searchCustomers: any;
  private subscription;
  customers: CustomerData[] = [];
  profileThumb: string;
  userss: Array<any>;
  userdata = [];
  currentFilter: string = "first_name";
  startAt: BehaviorSubject<string | null> = new BehaviorSubject("");
  customers$: Observable<any[]>;

  constructor(public dialog: MatDialog, private rrd: RrDataService) {
    
  }


  openEditDialog(i: string): void {
    this.editRow = i;
    this.selectedCustomer = this.customers.find(x => x.$key == i);
    let dialogRef = this.dialog.open(FileOverlayComponent, {
      data: this.selectedCustomer
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  loadMore() {
    this.firstLoad = this.firstLoad + 100;
  }

  public handleScroll(event: ScrollEvent) {
    if (event.isReachingBottom) {
      this.loadMore();
    }
  }


  ngOnInit() {

    this.customerList.subscribe(cList => {
      this.customers = cList;
    })

    this.profileThumb = "images/profile.jpg";

    // {
    //   {

    //     this.customers$ = this.rrd.SearchCardCustomers('doctor_clinic/TD', this.startAt, this.currentFilter);

    //   }
    //   this.loading = false;
    // }
  }

  search(searchText) {
    this.startAt.next(searchText.trim().toUpperCase());
  }




  // applyFilter(filterValue: string) {
  //   filterValue = filterValue.trim(); // Remove whitespace
  //   filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  //   this.customer.filter = filterValue;
  // }
}







