import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { RouterLinkActive } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { AuthService } from "../shared/auth.service";
import { ImFireService } from "../im-fire.service";
import { RrDataService, IGNotif, AccessRights } from "../shared/rr-data.service";
import { auth } from 'firebase/app';
import { AdminGuard } from 'app/shared/admin-guard';


@Component({
    selector: 'app-navbar',
    templateUrl: './app-navbar.component.html',
    styleUrls: ['./app-navbar.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AppNavbarComponent implements OnInit {

    isLoggedIn = new BehaviorSubject(false);

    isAdmin = false;

    periodDefinition: Object;
    periodDefinitionList: Object[] = [];
    currentCycle: string;

    reportList = [];
    eformList = [];

    Object = Object;
    notifCount: number = null;
    client: string;
    userName: string;
    notifications = [];
    initialNotif = [];
    notifProfilePic = [];
    notifContent: boolean = false;
    userDetails: Observable<any>;
    hiddenNavButtons: string;

    constructor(private authService: AuthService,
        private rrd: RrDataService,
        private imFire: ImFireService
    ) {

        this.authService.isLoggedIn().subscribe(x => {

            this.isLoggedIn.next(x);

            this.rrd.periodDefinitionList.subscribe(l => {

                this.periodDefinitionList = l || [];

                this.rrd.currentCycle.subscribe(c => {

                    this.currentCycle = c;

                });

                console.log('new period definition');
                console.log(l);
            });

            this.rrd.currentPeriod.subscribe(
                p => {
                    this.periodDefinition = p;
                    console.log(p);
                });

            this.rrd.reportList.subscribe(r => this.reportList = r);
            this.rrd.eformsList.subscribe(e => this.eformList = e);

        });

        this.rrd.userAccess(AccessRights.IgniteAdmin).subscribe(is => this.isAdmin = is)

        this.rrd.hidden_navbar_btns.subscribe(hidden => {
            this.hiddenNavButtons = hidden;
        })

    }

    selectCycle(nCycle: string) {

        this.currentCycle = nCycle;
        this.rrd.setCurrentCycle(nCycle);

    }

    ngOnInit() {
        this.authService.userInfo.subscribe(udata => {
            this.rrd.allUsers.subscribe(uList => {
                if (uList != null) {
                    this.imFire.readFirList(udata.client + '/notification/' + udata.userName, ref => ref.orderByChild('datetime')).snapshotChanges().subscribe(n => {
                        let count = 0;
                        let initialCount = 0;
                        this.initialNotif = [];
                        n.forEach(notif => {

                            let data = notif.payload.val();

                            if (data) {

                                if (!data['seen']) {

                                    let type = data['type'] // notif.payload.child('type').val();
                                    let notifDateTime = data['datetime'] //notif.payload.child('datetime').val();
                                    let ndt = this.imFire.getNotificationTime(notifDateTime)

                                    // let p = this.setProfilePicture(udata.client, notif.payload.child('from').val())
                                    let from = uList.byUser[data['from']]
                                    let ppic = from ? from['profileURL'] : Promise.resolve('images/profile.jpg')

                                    count++;
                                    this.initialNotif.push({ $key: notif.key, ...notif.payload.val(), profile_pic: ppic, time_span: ndt })
                                }
                                if (count > 0) {
                                    this.notifCount = count
                                }
                            }

                        })
                        this.initialNotif = this.initialNotif.slice(this.initialNotif.length - 3, this.initialNotif.length).reverse()
                        // this.notifProfilePic = this.notifProfilePic.slice(this.notifProfilePic.length - 3, this.notifProfilePic.length).reverse()
                    })
                }
            })
        })
        
    }

    viewNotif(index: string) {
        this.imFire.setSelectedNotification(index)
    }




    setProfilePicture(client: string, userName: string) {

        // rfb temp disabled 5/18/18

        // this.imFire.readFirObject(client + '/users/'  + userName)
        // .snapshotChanges()
        // .subscribe(x => {
        //     let  profilePic = x.payload.hasChild('profile_picture');
        //     let  imgUrl = x.payload.child('profile_picture').val();
        //     if ((profilePic) && (imgUrl)) {
        //         this.imFire.getProfileImageURL(imgUrl).then(y => {
        //             this.notifProfilePic.push(y);
        //         });
        //     } else { 
        //         this.notifProfilePic.push("images/profile.jpg")
        //     }
        // })
    }
}
