import {Component, Output} from "@angular/core";
import {AuthService} from "./shared/auth.service";
import { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import {Router} from "@angular/router";
import {UserInfo} from "./shared/user-info";
import * as firebase from 'firebase';
import {ToasterModule, ToasterService, ToasterConfig} from 'angular2-toaster';
import {ImFireService} from "./im-fire.service";
import {EventsService} from "./shared/events.service";
import {BodyOutputType} from "angular2-toaster/angular2-toaster";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent {
    private alertType = null;
    private alertMessage = "";

    private toasterService: ToasterService;

    public config1 : ToasterConfig = new ToasterConfig({
        positionClass: 'toast-bottom-left',
        showCloseButton: true,
        tapToDismiss: false,
        timeout : 5000,
        animation : 'fade',
        newestOnTop : true,
        mouseoverTimerStop: true

    });

    isLoggedIn = new BehaviorSubject<boolean>(false);
    notifications = [];

    constructor(private authService: AuthService,
                private router: Router,
                toast : ToasterService,
                private fb : ImFireService) {

        this.toasterService = toast;
        console.log('creating instance of app.components/toast');
        this.authService.isLoggedIn().subscribe(this.isLoggedIn);

        this.currentUser().subscribe(function (u) {
            if (u.client && u.userName) {
                firebase.database().ref(`${u.client}/users/${u.userName}/default_im2_route`).once('value', function (s) {
                    if (s.val()) {
                        let x = [];
                        x.push(s.val());
                        router.navigate(x).then((e) => {
                            if (e) {
                                console.log('Navigation is successful!');
                            } else {
                                console.log('Navigation has failed!');
                            }
                        });
                    }
                });
            }
        });

    }

    ngAfterViewInit() {

        EventsService.toasts.subscribe(t => this.popToast(t));

    }

    popToast(toast) {
        if (toast.type) {
            toast.bodyOutputType = BodyOutputType.TrustedHtml;
            this.toasterService.pop(toast);
        }
    }

    currentUser(): Observable<UserInfo> {
        return this.authService.currentUser();
    }

    logout() {
        this.authService.logout();
        this.router.navigate(['/']);
    }

    onResetPasswordSuccess() {

        this.alertType = "success";
        this.alertMessage = "Reset Password Sent!";
    }

    onLoginSuccess() {
        //this.authService.logout();
        //this.router.navigate(['./dashboard']);
    }

    onRegisterSuccess() {
        this.alertType = "success";
        this.alertMessage = "User registered!";
    }

    onError(err) {
        this.alertType = "danger";
        this.alertMessage = err;
    }

    onLoggedOut() {
        // Just reset any displayed messsage.
        this.alertType = null;
        this.alertMessage = "";
    }

    alertClosed() {
        this.alertType = null;
        this.alertMessage = "";
    }
}

