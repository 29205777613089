import { Component, OnInit, EventEmitter, Output, Input } from "@angular/core";
import { AuthService } from "app/shared/auth.service";
import { UserInfo } from "app/shared/user-info";
import { Observable } from "rxjs";
import { ImFireService } from "app/im-fire.service";
import * as firebase from 'firebase';
import { ToasterModule, ToasterService, ToasterConfig } from 'angular2-toaster';
import { EventsService } from "../shared/events.service";
import { DatePipe } from '@angular/common';
import { RrLibService } from "../rr-lib.service";
import { RrDataService } from "../shared/rr-data.service";
import { PublicFeedComponent } from "app/public-feed/public-feed.component";


@Component({
    selector: 'app-display-user',
    templateUrl: './display-user.component.html',
    styleUrls: ['./display-user.component.css']
})
export class DisplayUserComponent implements OnInit {
    @Input() displayType = 'full';
    @Output() onLoggedOut = new EventEmitter();


    territories: string[];
    userDetails: Observable<any>;
    profileImg: string;

    private toasterService: ToasterService;

    constructor(private authService: AuthService, private imFire: ImFireService, toast: ToasterService, private rrd: RrDataService) {
        this.toasterService = toast;

        const ds = RrLibService.dateToLongString(new Date());

        console.log(ds);



        // firebase.app().database().ref(AuthService.client + '/public_feed/').orderByKey().startAt(ds).limitToLast(5).on('child_added', function (snap) {
        //     const feed = snap.val();

        //     feed.description = feed.description.replace('%name%', feed.name);

        //     const ttype = {
        //         call : "success",
        //         checkin : "info",
        //         note : "warning"
        //     }[feed.type];

        //     const title = {
        //         call : "Call",
        //         checkin : "Checked-In",
        //         note : "Call Note Added:"
        //     }[feed.type];

        //     EventsService.createToast(ttype, title, feed.description);

        //     //console.log(feed.type + ' - ' + feed.description);
        // })
    }

    ngOnInit() {

        this.userDetails = this.imFire.clientGetObject('users/' + AuthService.userName);
        this.userDetails.subscribe(x => {
            
            this.profileImg = "images/profile.jpg";

            if (x.profile_picture) {

                this.imFire.getProfileImageURL(x.profile_picture).then(y => this.profileImg = y);

            } else {
                this.profileImg = "images/profile.jpg";
            }


            this.territories = x.territory_id.split('||');
            // ImFireService.territories = this.territories;
            // this.rrd.setTerritories(x.territory_id.split('||'));

            if (this.territories.length > 1) {
                this.rrd.last_public_feed.subscribe(feed => {
                    if (feed != null) {

                        if (PublicFeedComponent.showAllFeeds) {

                        }

                        let ndt = this.imFire.getNotificationTime(feed.datetime)
                        if (ndt == 'A moment ago') {
                            const ttype = {
                                call: "success",
                                checkin: "info",
                                note: "warning"
                            }[feed.type];

                            const title = {
                                call: "Call:",
                                checkin: "Checked-In:",
                                note: "Call Note Added:"
                            }[feed.type];

                            EventsService.createToast(ttype, title, feed.description);
                        }
                    }

                })
            }

        })


    }

    ngAfterViewInit() {

    }

    currentUser(): Observable<UserInfo> {
        return this.authService.currentUser();
    }

    logout() {
        this.authService.logout().subscribe(() => this.onLoggedOut.emit("success"));
    }

}
