import { Component, OnInit, Inject } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from "@angular/fire/storage";
import { Observable } from 'rxjs';
import { AuthService } from 'app/shared/auth.service';
import { tap } from 'rxjs/operators';
import { RrDataService, NodeType, Hashtag } from 'app/shared/rr-data.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { TagModel } from 'ngx-chips/core/accessor';
import { EventsService } from 'app/shared/events.service';
import { debuglog } from 'util';
import { RrLibService } from 'app/rr-lib.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit {

  // Main task 
  task: AngularFireUploadTask;

  // Progress monitoring
  percentage: Observable<number>;

  snapshot: Observable<any>;


  private _showTags: Hashtag[];
  private _tags: Hashtag[] = [];
  private tags: TagModel[] = [];
  private showUploader = false;
  private filterTags: string[] = [];
  private autoCompleteTags = [];

  description: string;
  downloadURL: Observable<string>;
  openPanel: string;
  selectedFile: Object;


  constructor(private fbS: AngularFireStorage,
    private afb: AngularFireDatabase,
    private rrd: RrDataService
  ) {

    this.rrd.hashtags.subscribe(hashtags => {

      this._tags = hashtags;

      this.autoCompleteTags = [];

      this._tags.forEach(_tag => {

        this.autoCompleteTags.push(_tag.name);

        _tag['files'] = [];
        _tag['tags'] = ""

        _tag.snap.forEach(file => {
          const val = file.val();
          const ext = val['filename'].split('.').slice(-1)[0];
          const ht = val['tags'];

          val['extension'] = 'docx,pdf,pptx,xlsx,zip,png'.indexOf(ext) === -1 ? 'file' : ext;
          val['key'] = file.key;
          val['tags'] = ht ? ht.split('||') : [];
          

          _tag['files'].push(val);
          _tag['tags'] += ht;

         
          return false
        })
      })

      this._showTags = this._tags;

    })

  }



  downloadFile(selectedFile) {
    if (selectedFile.downloadURL != null) {
      document.location.href = selectedFile.downloadURL;
    } else {
      this.fbS.ref(selectedFile.storagePath).getDownloadURL().subscribe(url => {
        document.location.href = url;
      })
    }
    
  }

  deleteFile(selectedFile) {
    if (confirm(`Are you sure you wish to delete this file (${selectedFile.description})`)) {
      selectedFile.tags.forEach(tag => {
        if (typeof tag == 'object') {
          tag = tag['display']
        }
        const fileKey = selectedFile.key;

        this.afb.database.ref(`/${AuthService.client}/hashtags/${tag}/${fileKey}`).remove()
          .catch(err => {
            console.log(`Error: ${err} deleting ${selectedFile.description}`)
          })
      })

      this.afb.database.ref(`${AuthService.client}/files/${selectedFile.key}`).remove().then(v => {
        this.fbS.ref(selectedFile.storagePath).delete().subscribe((v) => {
        }, (err) => {
          // alert(`Failed to delete: ${selectedFile.description} (${err}).`);
          EventsService.createToast('error', 'File Delete Error:', `Failed to delete: ${selectedFile.description} (${err}).`)
        }, () => {
          EventsService.createToast('warning', 'File Delete:', `${selectedFile.description} successfuly deleted.`)
          // alert(`${selectedFile.description} successfuly deleted.`);
        })
      }).catch(err => {
        EventsService.createToast('error', 'File Delete Error:', `Failed to delete: ${selectedFile.description} (${err}).`)
      })

    }
  }

  tagFilter(event) {
    if (this.filterTags.length == 0) {
      this._showTags = this._tags;
    } else {
      // filter all the hashtags to those that contain files with hashtags that match the filter
      const filter = this.filterTags;

      this._showTags = this._tags.filter(v => {
        return filter.indexOf(v.name) > -1 || filter.find(ft => {
          if (typeof ft == 'object') {
            ft = ft['display'] // tag-input creates an array of tagModel objects
          }
          return v['tags'].indexOf(ft) > -1;
        })
      })


    }
  }

  toggleUploader(): void {
    this.showUploader = !this.showUploader;
  }


  startUpload(event: FileList) {
    // The File object
    const file = event.item(0)

    // Client-side validation example
    // if (file.type.split('/')[0] !== 'image') {
    //   console.error('unsupported file type :( ')
    //   return;
    // }

    // The storage path
    const path = `${AuthService.client}/files/${new Date().getTime()}_${file.name}`;

    // Totally optional metadata
    const customMetadata = { app: 'Ignite File', description: this.description, tags: this._tags.join('||') };

    // The main task
    this.task = this.fbS.upload(path, file, { customMetadata })

    // Progress monitoring
    this.percentage = this.task.percentageChanges();

    this.task.snapshotChanges().subscribe(snap => {
      if (snap.bytesTransferred === snap.totalBytes) {
        const fileKey = file.name.replace(/[ .$#,]/g, '_')

        this.tags.push(AuthService.userName.toUpperCase())
        const stags = this.tags.map<string>((v, i, a) => {
          return v['display'] || v;
        })

        this.afb.database.ref(`/${AuthService.client}/files/${fileKey}`)
          .update({
            filename: file.name,
            storagePath: path,
            description: this.description || 'n/a',
            tags: stags.join('||'),
            mime: snap.metadata.contentType || 'file',
            downloadURL: snap.downloadURL
          })

        this.tags.forEach(tag => {
          if (typeof tag == 'object') {
            tag = tag['display']
          }

          this.afb.database.ref(`/${AuthService.client}/hashtags/${tag}/${fileKey}`)
            .update({
              filename: file.name,
              storagePath: path,
              description: this.description || 'n/a',
              type: 'File',
              tags: stags.join('||'),
              mime: snap.metadata.contentType || 'file',
              downloadURL: snap.downloadURL
            })
        })
        this.tags = [];
        this.description = null;
        this.percentage = null;
        this.showUploader = false;
      }
    })

    // The file's download URL
    // this.downloadURL = this.task.downloadURL();
  }

  // Determines if the upload task is active
  isActive(snapshot) {
    return snapshot.state === 'running' && snapshot.bytesTransferred < snapshot.totalBytes
  }

  ngOnInit() {

  }

  public onAdding(tag: TagModel): Observable<TagModel> {
    // const confirm = window.confirm('Do you really want to add this tag?');
    tag['display'] = tag['display'].toUpperCase();
    
    // return Observable
    return of(tag).filter(() => true);
      
      
  }

  public onRemove(tag: TagModel): Observable<TagModel> {

    this.tags.splice(this.tags.indexOf(tag), 1);

    tag['display'] = tag['display'].toUpperCase();
    //return Observable
    return of(tag).filter(() => true);

  }

  addTagToSelectedFile(tag) {
    if (typeof tag == 'object') {
      tag = tag['display'];
    }

    tag = tag.toUpperCase();
    this.selectedFile['tags'].pop()
    this.selectedFile['tags'].push(tag);
    const file = Object.assign({}, this.selectedFile);
    const fileKey = file['key'];

    const aTags = file['tags'];
    const sTags = aTags.join('||');

    file['tags'] = file['tags'].join('||')

    this.afb.database.ref(`/${AuthService.client}/files/${fileKey}/tags`).set(sTags).then(v => {
      aTags.forEach(tag => {
        this.afb.database.ref(`/${AuthService.client}/hashtags/${tag}/${fileKey}`).set(file)
      })
    })
  }

  removeTagFromSelectedFile(tag) {
    if (typeof tag == 'object') {
      tag = tag['display'];
    }
    const file = this.selectedFile;
    const fileKey = file['key'];
    const tags = file['tags'].join('||');
    const aTags = file['tags'];

    this.afb.database.ref(`/${AuthService.client}/files/${fileKey}/tags`).set(tags)
      .then(v => {
        this.afb.database.ref(`/${AuthService.client}/hashtags/${tag}/${fileKey}`).remove()
        aTags.forEach(ntag => {
          this.afb.database.ref(`/${AuthService.client}/hashtags/${ntag}/${fileKey}/tags`).set(tags)
        })
      })
      
  }

}
