import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';

@Component({
  selector: 'app-wegot',
  templateUrl: './wegot.component.html',
  styleUrls: ['./wegot.component.css'],
  encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('animateValue', [
            state('*', style({
                backgroundColor: '#fff',
                transform: 'scale(1)'
            })),
            state('*',   style({
                backgroundColor: '#cfd8dc',
                transform: 'scale(1.1)'
            })),
            transition('* => *', animate('100ms ease-in')),
            transition('* => *', animate('100ms ease-out'))
        ])
    ]
})
export class WegotComponent implements OnInit {
    @Input() title : string;
    @Input() actual : number = 0;
    @Input() target : number = 0;
    @Input() rate : number = 0.0;
    @Input() extraClass : string;

  constructor() { }

  ngOnInit() {
      if (this.actual > 0 && this.target > 0) {
          this.rate = (this.actual / this.target) * 100
      } else {
          this.rate = 0;
      }

  }

}
