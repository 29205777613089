import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { RrDataService } from 'app/shared/rr-data.service';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subject } from 'rxjs';
import { CalendarComponent } from 'ng-fullcalendar';
import { Options } from 'fullcalendar';
import { setTimeout } from 'timers';

@Component({
  selector: 'rr-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class RRCalendarComponent implements AfterViewInit, OnInit {

  @ViewChild(CalendarComponent) ucCalendar: CalendarComponent;

  

  showCalls = false;
  showPlans = true;
  showOffField = true;

  calendarOptions: Options =  {
    height: 800,
    // themeSystem: 'bootstrap3',
    contentHeight: 800,
    editable: true,
    eventLimit: true,
    // customButtons: {
    //   call: this.callButton
    // },
    header: {
      left: 'month basicWeek basicDay listMonth',
      center: 'title',
      right: 'today prev,next'
    },
    buttonIcons: {
      prev: 'left-single-arrow',
      next: 'right-single-arrow',
      prevYear: 'left-double-arrow',
      nextYear: 'right-double-arrow'
    }
  }

  private view = 'month';
  private viewDate = new Date();
  private events = [];

  displayEvent: any;
  logger: any[] = [];

  static ucCalendar: CalendarComponent;

  constructor(private rrd: RrDataService) {

  }

  ngOnInit() {

  }

  ngAfterViewInit() {

  }

  clickButton(model: any) {
    this.displayEvent = model;
  }

  eventClick(model: any) {
    model = {
      event: {
        id: model.event.id,
        start: model.event.start,
        end: model.event.end,
        title: model.event.title,
        allDay: model.event.allDay
        // other params
      },
      duration: {}
    }
    this.displayEvent = model;
  }
  updateEvent(model: any) {
    model = {
      event: {
        id: model.event.id,
        start: model.event.start,
        end: model.event.end,
        title: model.event.title
        // other params
      },
      duration: {
        _data: model.duration._data
      }
    }
    this.displayEvent = model;
  }
  windowResize(model: any) {
    console.log('The calendar has adjusted to a window resize');
  }
  viewRender(model: any) {
    console.log('viewRender');
  }
  eventRender(model: any) {
    this.logger.push(model);
  }

  initialized() {
    
    RRCalendarComponent.ucCalendar = this.ucCalendar;

    this.rrd.calendarEvents.subscribe(e => {
      // debugger
      // this._events.next(e);
      if (e != null) {
        this.events = e;
        // console.log(e.length, JSON.stringify(e));

        this.updateEvents(e)
        // if (RRCalendarComponent.ucCalendar != null) {
        //   if (RRCalendarComponent.ucCalendar.fullCalendar != null) {

        //     RRCalendarComponent.ucCalendar.fullCalendar('removeEventSources');
            
        //     RRCalendarComponent.ucCalendar.fullCalendar('addEventSource', e['calls']);
        //     RRCalendarComponent.ucCalendar.fullCalendar('addEventSource', e['plans']);

        //     setTimeout(()=>{
        //       RRCalendarComponent.ucCalendar.fullCalendar('refetchEventSources');
        //     }, 10)
            

        //   }
        // }
      }
    });

  }

  select(model: any) {
    console.log(model);
  }
  unselect(model: any) {
    console.log(model);
  }

  updateEvents(event) {
    const e = this.events;
    if (RRCalendarComponent.ucCalendar != null) {
      if (RRCalendarComponent.ucCalendar.fullCalendar != null) {

        RRCalendarComponent.ucCalendar.fullCalendar('removeEventSources');
        
        if (this.showCalls) {
          RRCalendarComponent.ucCalendar.fullCalendar('addEventSource', e['calls']);
        }
        
        if (this.showPlans) {
          RRCalendarComponent.ucCalendar.fullCalendar('addEventSource', e['plans']);
        }


        setTimeout(()=>{
          RRCalendarComponent.ucCalendar.fullCalendar('refetchEventSources');
        }, 10)
        

      }
    }
  }

}
