import { Component, OnInit, ViewChild } from '@angular/core';
import { RrDataService } from 'app/shared/rr-data.service';
import { GoogleSymbol, GoogleMap } from '@agm/core/services/google-maps-types';
import { RrLibService } from 'app/rr-lib.service';

@Component({
  selector: 'rr-map',
  templateUrl: './rrmap.component.html',
  styleUrls: ['./rrmap.component.css']
})
export class RrmapComponent implements OnInit {

  title: string = 'View Route';

  iconHeight = 0.0001;

  lat: number;
  lon: number;
  markers = [];
  lines = [];
  timeIns = [];
  noTimeIns = [];

  constructor(private rrd: RrDataService) { }

  ngOnInit() {

    this.zoomChanged(12);

    this.rrd.allUsers.subscribe(uList => {
      this.rrd.districtUserIDs.subscribe(distIDs => {
        this.rrd.selectedTerritoriesUserIDs.subscribe(uids => {
          this.timeIns = [];

          (distIDs + '||' + uids).split('||').forEach(uid => {
            const user = uList.byUser[uid];
            if (user) {
              var dtr = user['dtr'];
              if (dtr != null) {
                var location = { lat: parseFloat(dtr['lat']), lon: parseFloat(dtr['lon']) }

                if (location.lat * location.lon == 0 && dtr['actualLocation'] != null) {
                  const aLoc = dtr['actualLocation'].split('>')[0].replace(/[<+]/g, '').split(',');
                  if (aLoc.length == 2) {
                    location = { lat: parseFloat(aLoc[0]), lon: parseFloat(aLoc[0]) }
                  }
                }

                if (location.lat * location.lon > 0) {
                  this.timeIns.push({
                    location: location,
                    location_name: dtr['name'],
                    timeIn: dtr['time_in'],
                    name: user['name'],
                    color: user['color'].replace('#', '')
                  });
                } else {
                  this.timeIns.push({
                    timeIn: dtr['time_in'] || 'No Time In',
                    name: user['name'],
                    color: user['color'].replace('#', '')
                  })
                }
              } else {
                this.timeIns.push({
                  name: user['name'],
                  color: user['color'].replace('#', '')
                })
              }
            } else {
              if (uid > '') {
                this.timeIns.push({
                  name: `${uid} no record`,
                  color: '#000000'
                })
              }
            }
          })

          this.timeIns = this.timeIns.sort((a, b) => {
            return (a.timeIn || '99') > (b.timeIn || '99') ? 1 : a.timeIn < b.timeIn ? -1 : 0;
          })

        })
      })

    });

    this.rrd.mapMarkers.subscribe(e => {
      // debugger
      if (e != null) {

        this.lat = e.recentCall.lat;
        this.lon = e.recentCall.lon;

        var markers = [];
        var lines = [];



        for (const t of e.markers) {
          // check for overlapping locations
          var marker = {}

          for (const loc of t.locations) {
            const mrk = marker[`${loc.location.lat}-${loc.location.lon}`] || [];
            mrk.push(loc)
            marker[`${loc.location.lat}-${loc.location.lon}`] = mrk;
          }

          var nLocations = []
          for (const mrkLoc in marker) {
            var mrk: any[] = marker[mrkLoc]
            // check if a location was visited more than once (same location different labels)
            if (mrk.length > 1) {
              // combine the markers
              const nrec = mrk.reduce((a, b) => {

                if (a.location.label.indexOf(b.location.label) == -1) {
                  a.location.label = [a.location.label, b.location.label].join(',')
                  a.calls = a.calls.concat(b.calls)
                }

                return a;
              })

              nLocations.push(nrec)

            } else {
              nLocations.push(mrk[0])
            }
          }

          markers = markers.concat(nLocations)

          lines.push({ color: '#' + t.user.color, points: t.locations })
        }

        this.markers = markers;
        this.lines = lines;

      }



      // if (fcwl.meta['location'] != null) {
      //   const loc = fcwl.meta['location'].split('||')
      //   // console.log("location: ", loc)
      //   this.lat = parseFloat( loc[0] );
      //   this.lon = parseFloat( loc[1] );  
      // }

      // e.calls.forEach(call => {
      //   if (call.meta['location'] != null) {
      //     const loc = call.meta['location'].split('||')
      //     this.markers.push({lat : parseFloat(loc[0]), lon : parseFloat(loc[1]), obj: call.meta});
      //   }
      // });


    })
  }

  test = null;
  zoomChanged(event) {

    this.test = event;
    // this.iconHeight = (1 / (event / 22))

    // this.iconHeight = 591657550.500000 / 2^(event-1)

    const scale = {
      22: 200.0,
      21: 500.0,
      20: 1128.497220,
      19: 2256.994440,
      18: 4513.988880,
      17: 9027.977761,
      16: 18055.955520,
      15: 36111.911040,
      14: 72223.822090,
      13: 144447.644200,
      12: 288895.288400,
      11: 577790.576700,
      10: 1155581.153000,
      9: 2311162.307000,
      8: 4622324.614000,
      7: 9244649.227000,
      6: 18489298.450000,
      5: 36978596.910000,
      4: 73957193.820000,
      3: 147914387.600000,
      2: 295828775.300000,
      1: 591657550.500000
    }
    this.iconHeight = (scale[event] / 19900000)
  }

}
