import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { CustomerData } from 'app/customers/customer';
import { AngularFireStorage } from '@angular/fire/storage';
import { AuthService } from 'app/shared/auth.service';
import { FileOverlayComponent } from '../cards/file-overlay/file-overlay.component';
import { MatDialog } from '@angular/material';
import { ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UserData, RrDataService } from 'app/shared/rr-data.service';

@Component({
  selector: 'rr-customer-card',
  templateUrl: './customer-card.component.html',
  styleUrls: ['./customer-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomerCardComponent implements OnInit, AfterViewInit {

  @Input('customer') customer : CustomerData;
  downloadURL : string;
  selectedCustomer;

  private userData: UserData;
  private allowedToDelete = false;

  constructor(public dialog: MatDialog, private fbs : AngularFireStorage, private cdr : ChangeDetectorRef, private rrd: RrDataService) { 
    this.rrd.userData.subscribe(u => {
      if (u) {
        this.userData = u;
        this.allowedToDelete = u.isAllowedTo('delete_md');
      }
    })
  }

  openEditDialog(i: string): void {
    // this.editRow = i;
    this.selectedCustomer = this.customer;
    let dialogRef = this.dialog.open(FileOverlayComponent, {
      data: this.selectedCustomer
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  ngOnInit() {
    
  }

  toggleDelete(customer : CustomerData) {
    customer.toggleDelete();
  }

  ngAfterViewInit() {
    // debugger
    const cust = this.downloadURL
    if (this.customer['profile_picture'] != null) {
      // this.fbs.ref(`${AuthService.client}/${this.customer['profile_picture']}`).getDownloadURL().subscribe(url => {
      //   this.downloadURL = url;
      // })
    }
  }

}
