import { Pipe, PipeTransform } from '@angular/core';
import * as fb from 'firebase';
import {AuthService} from "../../shared/auth.service";
import {ImFireService} from "../../im-fire.service";
import { DatePipe } from '@angular/common';
// todo: allow calculated fields

@Pipe({
    name: 'reportField'
})
export class ReportFieldPipe implements PipeTransform {
    static getDataMaps = {};

    transform(value:string, format:string):any {

        return new Promise((resolve, reject) => {

            if ((value == 'null' || value == 'undefined') || !value) {
                resolve('');
            }


            if (typeof value == 'string' && (value.indexOf('getData:') != -1)) {
                if (value.indexOf('getData:') != -1) {
                    var dPath = value.replace('getData:', '');
                    if (ReportFieldPipe.getDataMaps[value]) {
                        resolve(ReportFieldPipe.getDataMaps[value]);
                    } else {
                        fb.database().ref(AuthService.client + '/' + dPath).once('value', val => {
                            var value = val.val() || " ";
                            if(format == "number"){
                                value = val.val() || "0";
                            }

                            ReportFieldPipe.getDataMaps[value] = value;
                            resolve(value);
                        })
                    }
                }

            }
            else if (typeof value == 'string' && (value.indexOf('count:') != -1)) {
                var dPath = value.replace('count:', '');

                if (ReportFieldPipe.getDataMaps[value]) {
                    resolve(ReportFieldPipe.getDataMaps[value]);
                } else {
                    const keys = dPath.match(/{[a-z,A-Z]*}/g);

                    keys.forEach(k => dPath = dPath.replace(k, ''));

                    fb.database().ref(AuthService.client + '/' + dPath).once('value', val => {
                        ReportFieldPipe.getDataMaps[value] = val.numChildren() || 0;
                        resolve(val.numChildren() || 0);
                    })
                }
            }
            else {

                if (format == 'date') {
                    if (value.indexOf('+') != -1) {
                        value = value.split('+')[0];
                    }
                    if (value.indexOf(':') != -1) {
                        value = value.split(' ')[0];
                    }
                    //console.log('date ' + value);
                    const d = new Date(Date.parse(value.replace(' ', 'T')));

                    if ( Object.prototype.toString.call(d) === "[object Date]" ) {

                        if (isNaN(d.getTime())) {
                            // debugger
                            resolve ('No Data');
                        } else {
                            const dp = new DatePipe('EN-US');
                            resolve(dp.transform(d, "dd-MMM-yyyy"));
                        }

                    }
                    else {
                        resolve ('Invalid Date');
                        return
                    }
                }

                if (format == 'time') {

                    if (value.indexOf('+') != -1) {
                        value = value.split(' ')[1];
                        value = value.split('+')[0];
                    }

                }

                // if (format == 'percent') {
                //     value = `${value} %`;
                // }

                resolve(value);
            }

        });

    }

}
