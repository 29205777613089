import { Component, OnInit } from '@angular/core';
import { RrDataService, UserData } from 'app/shared/rr-data.service';
import { CustomerData } from 'app/customers/customer';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from 'app/shared/auth.service';
import { FileOverlayComponent } from 'app/customers/cards/file-overlay/file-overlay.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'rr-customer-main',
  templateUrl: './customer-main.component.html',
  styleUrls: ['./customer-main.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomerMainComponent implements OnInit {

  private display = 'list';
  private allCustomers: CustomerData[];

  private masterList: CustomerData[];

  private _customerList = new BehaviorSubject<CustomerData[]>(null)
  private customerList = this._customerList.asObservable();

  private _filter = new BehaviorSubject<string>("");
  private filter = this._filter.asObservable();

  private showMaster = true;

  private territories = [];
  private rate = 0;

  private customerFields: any[];

  private userData: UserData;
  private allowedToAdd = false;

  constructor(private rrd: RrDataService, private cdr: ChangeDetectorRef, private dialog: MatDialog) {

    this.rrd.userData.subscribe(u => {
      if (u) {
        this.userData = u;
        this.allowedToAdd = u.isAllowedTo('add_md');
      }
    })


  }

  ngOnInit() {

    this.rrd.customer_fields.subscribe(fields => {

      this.customerFields = fields;
      this.rrd.selectedTerritories.subscribe(territories => {

        this.territories = territories;

        this.rrd.customerList.subscribe(customerList => {
          this.allCustomers = customerList;
          this.cdr.markForCheck();
          this._customerList.next(customerList)
        })

        this.rrd.masterList.subscribe(masterList => {
          this.masterList = masterList;
          this.applyFilter('');
          this.cdr.markForCheck();
        })

      })

    })



  }

  toggleMasterList(event) {

    this.applyFilter('');

  }

  newCustomer(): void {
    // this.editRow = i;
    var newCust = new CustomerData(null, this.rrd);

    // this.customerFields.forEach(field => {
    //   const fname = field.field_name;
    //   newCust[fname] = '';
    // })

    // newCust.key = 'NEW';
    // newCust.md_id = '(draft)';

    newCust['source'] = this.showMaster ? 'doctor_clinic_master' : 'doctor_clinic';
    newCust.territory = this.territories[0];

    let dialogRef = this.dialog.open(FileOverlayComponent, {
      data: newCust
    });

    dialogRef.afterClosed().subscribe(result => {
      // moved to file overlay
      // if (confirm('Do you wish to save the new doctor?')) {
      //   newCust.save(this.customerFields);
      // }
    });
  }

  carryOver() {
    // replace with proper dialog
    if (confirm('This will overwrite your next month`s plan.  Any changes you may have already made may be lost.  Continue?')) {

      const target = this.allCustomers.length
      var done = 0

      this.allCustomers.forEach(customer => {
        const custData = customer.snap.val();
        const ref = customer.snap.ref.root.child(`${AuthService.client}/doctor_clinic_master/${this.territories[0]}/${customer.snap.key}`).set(custData, (e) => {
          done += 1;
          this.rate = (done / target) * 100;
          this.cdr.markForCheck();

          if (target == done) {
            this.rate = 0;
          }
        })
      })

    }
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    if (filterValue != '') {

      const source = this.showMaster ? this.masterList : this.allCustomers;
      const filtered = source.filter(data => {
        return `${data.last_name} ${data.first_name} ${data.specialty_description} ${data.clinic_address} ${data.frequency}`.toLowerCase().indexOf(filterValue) != -1;
      })
      this._customerList.next(filtered)
      this.cdr.markForCheck();

    } else {
      const source = this.showMaster ? this.masterList : this.allCustomers;

      this._customerList.next(source);
      this.cdr.markForCheck();
    }
  }

  getFilter(toFilter) {

    const source = this.showMaster ? this.masterList : this.allCustomers;
    var filtered: CustomerData[];
    console.log('filter', toFilter);

    switch (toFilter) {

      case "None":
        this._customerList.next(source)
        break;

      case "inclusion_notification":

        filtered = source.filter(data => {
          return data.inclusion_notification != null
        })
        this._customerList.next(filtered)
        break;

      case "delete_notification":

        filtered = source.filter(data => {
          return data.delete_notification != null
        })
        this._customerList.next(filtered)
        break;

      case "CalledToTarget":

        filtered = source.filter(data => {
          return data.call_status == 'Called To Target'
        })
        this._customerList.next(filtered)
        break;

      case "Reached":

        filtered = source.filter(data => {
          return 'Reached Called To Target'.indexOf(data.call_status) != -1
        })
        this._customerList.next(filtered)
        break;

      case "Unreached":

        filtered = source.filter(data => {
          return data.call_status == 'Unreached'
        })
        this._customerList.next(filtered)
        break;

    };
  }

}
