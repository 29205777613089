import { Component, OnInit } from '@angular/core';
import { CustomerData, LookupsData, GroupbySpec } from '../customer';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { RrDataService } from '../../shared/rr-data.service';

@Component({
  selector: 'app-customer-tml',
  templateUrl: './customer-tml.component.html',
  styleUrls: ['./customer-tml.component.css']
})
export class CustomerTmlComponent implements OnInit {
  selectedCustomer;
  editRow: string = "0";
  customers = [];
  lookups: LookupsData[] = [];
  groups: GroupbySpec[] = [];
  specialties = [];
  loading: boolean = true;

  constructor(public dialog: MatDialog, private rrd: RrDataService) { 
    this.rrd.call_plans.subscribe(customers => {
      this.customers = customers;
      console.log(this.customers);
      //debugger
    });
    this.rrd.currentPeriod.subscribe(p => {
      console.log(p);
    });
  }

  ngOnInit() {
  }

}
