import { Component } from "@angular/core";
import { AuthService } from "app/shared/auth.service";
import { Observable, BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { UserInfo } from "app/shared/user-info";
import { map } from "rxjs/operators"
import { TreeviewItem, TreeviewConfig } from 'ngx-treeview';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent  {

    userInfo: Observable<UserInfo>;
    isLoggedIn = new BehaviorSubject(false);

    constructor(private authService: AuthService, private router: Router) {
        this.userInfo = authService.userInfo;
        this.userInfo.pipe(
            map(userInfo => !userInfo.isAnonymous)
        ).subscribe(this.isLoggedIn);
    }
}
